import { BlurEvent, Button, ClickEvent, DataDisplayEvent, DataSource, DataSourceMode, DataSourceModeChangeEvent, Textbox } from "@mcleod/components";
import { Api, ModelRow } from "@mcleod/core";
import { ExpandableFilterController } from "@mcleod/general/src/ExpandableFilterController";
import { getDispatchControlBoolean } from "@mcleod/general/src/models/ModelDispatchControl";
import { setAutoRateContext, setRateListeners } from "./RateUtil";
import { AutogenLayoutQuoteRates } from "./autogen/AutogenLayoutQuoteRates";
import { OtherChargesUtil } from "./OtherChargesUtil";

export class QuoteRates extends AutogenLayoutQuoteRates {
    private estimateFuelSurcharge: boolean = getDispatchControlBoolean("est_fuel_surcharge");
    private autoRate: boolean = getDispatchControlBoolean("auto_rate");
    private calculatingCharges: boolean = false;
    private expandableFilter: ExpandableFilterController = new ExpandableFilterController();
  private otherChargesUtil: OtherChargesUtil = new OtherChargesUtil();

  onLoad() {
    this.textboxRateType.onSelectItem = (textbox, selectedItem) => {
      const newValue = selectedItem["value"];
      this.activeRow.set(textbox.field, newValue);
      if (newValue == "O") {
        this.textboxRateUnits.enabled = true;
        this.textboxRateUnits.enabledDuringAdd = true;
        this.textboxRateUnits.enabledDuringUpdate = true;
      }
      else {
        this.textboxRateUnits.enabled = false;
        this.textboxRateUnits.enabledDuringAdd = false;
        this.textboxRateUnits.enabledDuringUpdate = false;
      }
      return undefined;
    };
    this.buttonEstimateFuelSurcharge.visible = !this.estimateFuelSurcharge;
    this.setupAdditionalChargeExpandableFilter();
    this.otherChargesUtil.initialize(this);
  }

    setupAdditionalChargeExpandableFilter() {
        this.expandableFilter.initialize(this.panelOtherChargesHeader2, this.panelOtherChargeTable);
    }

    buttonRateOnClick(event: ClickEvent) {
        const button = event.target as Button;
        button.busy = true;
        button.enabled = false;
        this.calculateRates(true).finally(() => {
            button.busy = false
            button.enabled = true;
        });
    }

    async calculateRates(autorate: boolean = this.autoRate, estimateFsc: boolean = this.estimateFuelSurcharge, overrideRateId?: number) {
        if (!this.mainDataSource.isAddingOrUpdating() || this.calculatingCharges || this.mainDataSource.activeRow == null) return Promise.resolve();
        this.mainDataSource.setComponentsBusy(true);
        this.calculatingCharges = true;

        try {
            const orderRow = this.mainDataSource.getDataboundValues(null, true, null, null);
            orderRow.set("customer_id", this.activeRow.get("customer_id"));
            const response = await Api.search("lme/dispatch/calculate-order-charges", {
                "order_type": "Q",
                "estimate_fsc": estimateFsc,
                "autorate": autorate,
                "order_row": orderRow,
                "calculate_max_target": false
            });
            const data = response.data[0];
            const orderApiData = data["order_row"] || [];
            if (estimateFsc) {
                this.calculateOtherCharges(data);
            }
            this.activeRow.set("rate_type", orderApiData["rate_type"]);
            this.activeRow.set("bill_distance", orderApiData["bill_distance"]);
            this.activeRow.set("autorate_status", orderApiData["autorate_status"]);
            this.activeRow.set("rate_id", overrideRateId ?? orderApiData["rate_id"]);
            this.activeRow.set("rate", orderApiData["rate"]);
            this.activeRow.set("rate_units", orderApiData["rate_units"]);
            this.activeRow.set("freight_charge", orderApiData["freight_charge"]);
            this.activeRow.set("otherchargetotal", this.otherChargesUtil.getOtherChargeTotal(false));
            if (orderApiData["total_charge"] != null) {
                if (orderApiData["total_charge"].amount != this.activeRow.get("total_charge")?.amount || 0) {
                    this.activeRow.set("total_charge", orderApiData["total_charge"]);
                }
            }
            setAutoRateContext(this, this.activeRow.get("rate_id"));
        }
        finally {
            this.mainDataSource.setComponentsBusy(false);
            this.calculatingCharges = false;
        }
    }

    private calculateOtherCharges(data) {
        const appendingId: string[] = [];
        // Estimate Fuel Surcharge
        this.tableOtherCharges.rows.forEach(row => {
            if (row.data?._appending == false) appendingId.push(row.data?._data["id"])
        });
        for (let i = this.tableOtherCharges.rows.length - 1; i >= 0; i--) {
            const row = this.tableOtherCharges.rows[i];
            if (row?.data?.data["est_fuel_surcharge"] === "Y") {
                row.deleteRow()
            }
        }

        this.sourceOrderOtherChargeQuote.data = [];
        const otherCharges = data["other_charges"] || [];
        otherCharges.forEach((element, index) => {
            const isAppending = appendingId.length == 0 || !appendingId.includes(element["id"]);
            const modelRow = new ModelRow(this.sourceOrderOtherChargeQuote.url, isAppending);
            modelRow.setValues(element);
            this.sourceOrderOtherChargeQuote.addRow(modelRow, index, false);
        });
        this.tableOtherCharges.displayData(null, this.sourceOrderOtherChargeQuote.data, 0);
    }

    /// START - Other Charge Logic ///
    getOtherChargeDatasource(): DataSource {
    return this.sourceOrderOtherChargeQuote;
  }

  otherChargeOnBlur(event: BlurEvent) {
    this.otherChargesUtil.otherChargeOnBlur(event);
    }

    tableOtherChargesOnRowDisplay(event) {
    this.otherChargesUtil.tableOtherChargesOnRowDisplay(event);
    }

    tableOtherChargesOnContentsChanged() {
        this.otherChargesUtil.tableOtherChargesOnContentsChanged();
    }

    chargeIdChanged(textbox: Textbox, selection) {
        this.otherChargesUtil.chargeIdChanged(textbox, selection);
    }
    /// END - Other Charge Logic ///

    sourceQuoteOrderOnDisplay(event: DataDisplayEvent) {
        if (this.activeRow)
            setAutoRateContext(this, this.activeRow.get("rate_id"));
    }

    sourceQuoteOrderAfterModeChange(event: DataSourceModeChangeEvent) {
        setRateListeners(this);
        if (event.newMode === DataSourceMode.ADD) {
            this.sourceOrderOtherChargeQuote.mode = DataSourceMode.ADD; // Trigger creation of blank row and set QUICK ADD to ADD mode
        }
    }

    sourceOrderOtherChargeQuoteAfterExecution() {
        if (this.sourceOrderOtherChargeQuote.mode === DataSourceMode.UPDATE) {
            this.otherChargesUtil.tableOtherChargesOnContentsChanged();
        }
    }
}
