import { CommonDialogs } from "@mcleod/common";
import { ClickEvent, DataDisplayEvent, Textbox } from "@mcleod/components";
import { Navigation } from "@mcleod/core";
import { LinkTenderDialog } from "./LinkTenderDialog";
import { LoadTenderExpress } from "./LoadTenderExpress";
import { PurposeSwap } from "./PurposeSwap";
import { PurposeSwapDialog } from "./PurposeSwapDialog";
import { AutogenLayoutLoadTenderExpressExpanded } from "./autogen/AutogenLayoutLoadTenderExpressExpanded";
import { ModelEdiOrder } from "./models/ModelEdiOrder";
import { ModelEdiorderProfile } from "./models/ModelEdiorderProfile";

export class LoadTenderExpressExpanded extends AutogenLayoutLoadTenderExpressExpanded {
    private _tenderID: string;
    private _purposeType: String;
    private _orderID: String;
    private _loadTenderExpress: LoadTenderExpress;

    override onLoad() {
        this.tenderID = this.parentTender?.tenderID;
        this.purposeType = this.parentTender?.purposeType;
        this.orderID = this.parentTender?.orderID;
        this._loadTenderExpress = this.parentTender?.loadTenderExpress;
        this.sourceEdiOrder.search({ id: this.tenderID });
        this.sourceEdiStop.search({ order_id: this.tenderID });
        this.sourceEdiOrder.displayDataInBoundComponents();
        if (this.purposeType != "Cancel" && (this.purposeType === "Original" || (this.purposeType != null && this.purposeType.indexOf("Change") >= 0))) {
            this.buttonPurposeSwap.enabled = true;
            this.buttonPurposeSwap.color = "primary";
            this.buttonLinkTender.tooltip = "Link a change tender to an order";

            if (this.purposeType === "Original") {
                this.buttonPurposeSwap.tooltip = "Change purpose to 'Change Tender'";
                this.buttonLinkTender.enabled = false;
                this.buttonLinkTender.color = "strokeSecondary";
            }

            if (this.purposeType != null && this.purposeType.indexOf("Change") >= 0) {
                this.buttonPurposeSwap.tooltip = "Change purpose to 'Original Tender'";
            }

        } else {
            this.buttonPurposeSwap.enabled = false;
            this.buttonPurposeSwap.color = "strokeSecondary";
            this.buttonLinkTender.enabled = false;
            this.buttonLinkTender.color = "strokeSecondary";
        }
    }

    get tenderID(): string {
        return this._tenderID;
    }

    set tenderID(value: string) {
        this._tenderID = value;
    }

    set purposeType(value: String) {
        this._purposeType = value;
    }

    get purposeType(): String {
        return this._purposeType;
    }

    get orderID(): String {
        return this._orderID;
    }

    set orderID(value: String) {
        this._orderID = value;
    }

    set loadTenderExpress(value: LoadTenderExpress) {
        this._loadTenderExpress = value;
    }

    sourceEdiOrderOnDisplay(event: DataDisplayEvent) {
        const tenderId = event.target.owner;
        tenderId.findComponentById("labelTenderId").addUnderlineWhenMouseOver();
        const movementStatus = event.rowData?.get("movement.status");
        const isBrokerage = event.rowData?.get("movement.brokerage");
        const brokerageStatusColor = event.rowData?.get("brokerage_status_color");
        if (this.purposeType === "Original" || !this.orderID) {
            this.labelMoveStatus.visible = false;
            this.panelBrkStatus.visible = false;
        }
        else if (isBrokerage === "N" || isBrokerage == "") this.panelBrkStatus.visible = false;
        else this.setPanelBrokerageStatusColor(brokerageStatusColor);

        if (this.labelMoveStatus.visible) {
            switch (movementStatus) {
                case "A":
                    event.rowData?.set("move_status", "Available");
                    break;
                case "C":
                    event.rowData?.set("move_status", "Covered");
                    break;
                case "D":
                    event.rowData?.set("move_status", "Delivered");
                    break;
                case "P":
                    event.rowData?.set("move_status", "In Progress");
                    break;
                case "V":
                    event.rowData?.set("move_status", "Void");
                    break;
                default:
                    this.labelMoveStatus.caption = "Other";
                    break;
            }
        }

        const orderId = event.rowData?.get("order_id") as string;
        const replyAction = event.rowData?.get("reply_action") as string;
        if (orderId != null || replyAction === "D") {
            this.buttonPurposeSwap.enabled = false;
            this.buttonPurposeSwap.color = "strokeSecondary";
        }
    }

    setPanelBrokerageStatusColor(color: any) {
        if (color == null || color == "#ffffff") {
            this.panelBrkStatus.backgroundColor = "default.reverse";
            this.panelBrkStatus.color = "default";
            this.panelBrkStatus.borderColor = "default";
            this.panelBrkStatus.borderWidth = 1;
        }
        else {
            this.panelBrkStatus.backgroundColor = color;
            this.panelBrkStatus.color = "default.reverse";
            this.panelBrkStatus.borderColor = null;
            this.panelBrkStatus.borderWidth = null;
        }
    }

    async purposeCodeSwap(event: ClickEvent) {
        this.parentTender.loadTenderExpress.turnOffRefresh();
        //get the purpose for the row selected
        const ediOrderRow = await new ModelEdiOrder().searchSingle({ id: this.parentTender.tenderID });
        const purpose = ediOrderRow.get("purpose_type");
        if (purpose === "O") {//change to a change and propmpt for order ID
            const dialog = new PurposeSwapDialog({ okVisible: false, title: "Order ID for Change Tender" });
            dialog.ediOrderRow = ediOrderRow;
            dialog.show().then(response => {
                if (response) {
                    const orderTextbox = dialog.findComponentById("textOrderId") as Textbox;
                    this.parentTender.loadTenderExpress.swapPurposeExistingTender(ediOrderRow, orderTextbox.text);
                }
                else {
                    this.parentTender.loadTenderExpress.refreshScreen();
                }
            }).then(() => {
                this.parentTender.loadTenderExpress.startRefreshInterval();
            });

        } else if (purpose === "U") {//it's change, change to original
            CommonDialogs.showYesNo("This will update the purpose of this tender from a change to an original.  Are you sure you want to do this?", "Change Purpose",
                { titleProps: { imageName: "circleX2", imageColor: "primary.light" }, xVisible: false }).then(clickedYes => {
                    if (clickedYes) {
                        const purposeSwap = new PurposeSwap();
                        purposeSwap.swapChangeToOriginal(ediOrderRow).then(() => {
                            this.sourceEdiOrder.search({ id: ediOrderRow.get("id") });
                            this.parentTender.loadTenderExpress.refreshScreen();
                        });
                    }
                });
        }
        this.buttonPurposeSwap.tooltip = null;
    }


    async linkTender(event: ClickEvent) {
        const ediOrderRow = await new ModelEdiOrder().searchSingle({ id: this.parentTender.tenderID });
        const profile = await new ModelEdiorderProfile().searchSingle({ partner_id: ediOrderRow.data["partner_id"], version: ediOrderRow.data["version"] });
        const needsReply: boolean = profile.get("rply_rquired_chang") == "Y" ? true : false;

        const dialog = new LinkTenderDialog({ okVisible: false, title: "Missing Order ID On Change Tender" });
        dialog.ediOrderRow = ediOrderRow;

        dialog.show().then(response => {
            if (response) {
                this.parentTender.loadTenderExpress.compareTender(ediOrderRow, needsReply).then(() => {
                    this.parentTender.loadTenderExpress.refreshScreen();
                });
            }
            else
                this.parentTender.loadTenderExpress.refreshScreen();
        });
    }

    get parentTender() {
        return this.parent as LoadTenderExpressExpanded;
    }

    labelTenderIdOnClick(event: ClickEvent) {
        Navigation.navigateTo("lme/datafusion/ViewEdiOrder?mode=update&key=" + this.parentTender.tenderID, { newTab: true });
    }
}
