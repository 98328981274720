import {AutogenLayoutSmsMessageDetails} from "./autogen/AutogenLayoutSmsMessageDetails";
import {
  ClickEvent,
  Component, DataSourceExecutionEvent,
  ImageViewer,
  Label, Panel,
  Snackbar,
  TableRow,
  TableRowDisplayEvent
} from "@mcleod/components";
import {FileUtil, getUserSettings, ModelRow, StringUtil} from "@mcleod/core";


export class SmsMessageDetails extends AutogenLayoutSmsMessageDetails {

  tableSmsMessagesOnRowDisplay(event: TableRowDisplayEvent) {
    const tableRow: TableRow = event.getTableRow();
    const labelSender = tableRow.findComponentById("labelSender") as Label;
    const labelUserCompany = tableRow.findComponentById("labelUserCompany") as Label;
    const labelRecipient = tableRow.findComponentById("labelRecipient") as Label;
    const modelRow = tableRow.data;
    const isDirectionInbound = StringUtil.equalsIgnoreCase(modelRow.get("direction_display"), "Inbound");

    if (isDirectionInbound) {
      labelUserCompany.caption = getUserSettings().company_name;
      this.setMediaComponents(modelRow, tableRow);
      labelSender.caption = modelRow.get("carrier_driver.driver_name");
      labelRecipient.visible = false;
    } else {
      labelSender.caption = getUserSettings().company_name;

      if (labelUserCompany) {
        labelUserCompany.visible = false;
      }
    }
  }

  labelMediaMessageOnClick(event: ClickEvent) {
    const row: TableRow = TableRow.getContainingTableRow(event.target as Component);
    const component = event.target as Label;
    const labelMediaName = component.getDataValue().trim();
    const modelRow = row.data;
    const parentRowId = modelRow.get("id");

    this.sourceMmsMedia.addBeforeExecutionListener((event: DataSourceExecutionEvent) => {
      event.filter.parent_row_id = parentRowId;
      event.filter.media_name = labelMediaName;
    });

    this.sourceMmsMedia.search().then(response => {
      let imageFound = false;
      response?.modelRows?.forEach((row: ModelRow) => {
        const mediaName = row.get("mms_media.media_name");
        const media = row.get("mms_media.media");
        if (StringUtil.equalsIgnoreCase(mediaName, labelMediaName)) {
          imageFound = true;
          this.saveOrView(media, labelMediaName);
        }
      });
      if (!imageFound) {
        Snackbar.showWarningSnackbar("Image preview failed.");
      }
    });
  }

  private saveOrView(imageBase64: string, fileName: string): void {
    if (ImageViewer.eligibleToView(fileName) === true) {
        ImageViewer.smsImageViewer(imageBase64, fileName);
    }else {
      FileUtil.downloadBase64AsFile(imageBase64, fileName);
      Snackbar.showSnackbar("Downloaded " + fileName);
    }
  }

  private setMediaComponents(modelRow: any, tableRow: TableRow) {
    const mediaMessageUrl = modelRow.get("media_message_url");

    if (mediaMessageUrl !== null && mediaMessageUrl !== undefined) {
      const mediaPanel = tableRow.findComponentById("panelMediaMessages") as Panel;
      const splitStrings: string[] = mediaMessageUrl.split(", ");

      splitStrings.forEach((currentString) => {
        const mediaName: string[] = currentString.split('/');
        const result: string = mediaName[mediaName.length - 1];

        const label = new Label({ color: "primary.light",caption: result,wrap:true, onClick: (event) => this.labelMediaMessageOnClick(event) });
        mediaPanel.add(label);
      });
    }
  }

}
