import { ChangeEvent, Panel, Textbox } from "@mcleod/components";
import { TooltipCallback } from "@mcleod/components/src/base/ComponentProps";
import { DisplayType } from "@mcleod/core";
import { EdiOrderRates } from "@mcleod/datafusion/src/EdiOrderRates";
import { OrderRates } from "./OrderRates";
import { Orders } from "./Orders";
import { QuoteRates } from "./QuoteRates";
import { RecOrderRates } from "./RecOrderRates";
import { RecurringOrders } from "./RecurringOrders";

export function setRateListeners(layout: OrderRates | RecOrderRates | QuoteRates | EdiOrderRates) {
    addRateFieldUpdateListener(layout);
    layout.textboxRateType.addChangeListener((event: ChangeEvent) => {
        if (event.userInitiatedChange && !layout.textboxRateType.isDropdownVisible()) {
            enableDisableUnitsAndRates(layout, event.newValue);
        }
    });
    layout.textboxRate.addBlurListener(event => { if (event.changedWhileFocused) layout.calculateRates(false, false) });
    layout.textboxRateUnits.addBlurListener(event => { if (event.changedWhileFocused) layout.calculateRates(false, false) });
    layout.textboxBillDistance.addBlurListener(event => { if (event.changedWhileFocused) layout.calculateRates(false, false) });
    if (layout instanceof OrderRates || layout instanceof RecOrderRates) {
        layout.textboxRateMinWeight.addBlurListener(event => { if (event.changedWhileFocused) layout.calculateRates(false, false) });
        layout.textboxWeight.addBlurListener(event => { if (event.changedWhileFocused) layout.calculateRates(false, false) });
        if (layout.owner instanceof Orders || layout.owner instanceof RecurringOrders) {
            layout.owner.textboxWeight.addBlurListener(event => { if (event.changedWhileFocused) layout.calculateRates(false, false) });
        }
    }
}

export function enableDisableUnitsAndRates(layout: OrderRates | RecOrderRates | QuoteRates | EdiOrderRates, newValue: string) {
    const isOther = newValue === "Other" || newValue === "O";
    if (isOther) {
        layout.textboxRateUnits.enabled = true;
        layout.textboxRateUnits.enabledDuringAdd = true;
        layout.textboxRateUnits.enabledDuringUpdate = true;
    }
    else {
        layout.textboxRateUnits.enabled = false;
        layout.textboxRateUnits.enabledDuringAdd = false;
        layout.textboxRateUnits.enabledDuringUpdate = false;
    }
    layout.calculateRates(false, false).then(() => {
        isOther ? layout.textboxRateUnits.focus() : layout.textboxRate.focus();
    });
}

export function setAutoRateContext(layout: OrderRates | RecOrderRates | QuoteRates | EdiOrderRates, rateId: number) {
    if (rateId != null) {
        layout.labelAutoRated.visible = true;
        layout.labelAutoRated.tooltipCallback = getRateIdTooltipCallback(layout);
        layout.buttonRate.tooltip = `Rate Number: ${rateId}`;
    }
    else {
        layout.labelAutoRated.visible = false;
        layout.textboxRate.tooltipCallback = null;
        layout.buttonRate.tooltip = null;
        layout.mainDataSource.activeRow.set("rate_id", null);
    }
}

function addRateFieldUpdateListener(layout: OrderRates | RecOrderRates | QuoteRates | EdiOrderRates) {
    const rateFields = ["rate", "rate_type", "rate_units", "weight", "rate_min_weight", "equipment_type_id", "bill_distance", "customer_id", "order_type_id", "commodity_id", "tractor_type"];
    layout.mainDataSource.boundComponents.forEach((comp) => {
        if (comp instanceof Textbox && rateFields.includes(comp.field)) {
            comp.addChangeListener((event: ChangeEvent) => {
                if (event.oldValue != event.newValue && layout.labelAutoRated.visible && event.userInitiatedChange)
                    setAutoRateContext(layout, null);
            })
        }
    });
}

function getRateIdTooltipCallback(layout: OrderRates | RecOrderRates | QuoteRates | EdiOrderRates): TooltipCallback {
    return (base, originatingEvent) => {
        const tooltip = makeRateIdTooltip(layout.mainDataSource.activeRow.get("rate_id"));
        return layout.labelAutoRated["_internalShowTooltip"](tooltip, originatingEvent);
    }
}

function makeRateIdTooltip(rateId) {
    const tooltipPanel: Panel = new Panel({ width: 150 });
    const rateIdLabel = new Textbox();
    rateIdLabel.printable = true;
    rateIdLabel.caption = "Rate ID";
    rateIdLabel.text = rateId;
    rateIdLabel.displayType = DisplayType.STRING;
    tooltipPanel.add(rateIdLabel);
    return tooltipPanel;
}
