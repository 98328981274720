import { CommonDialogs, CommonTooltips } from "@mcleod/common";
import {
    Button, ChangeEvent, Checkbox, ClickEvent, DataSourceMode, DropdownItem, Image, ImageType, Label, Layout, Panel,
    RowModeControlType, ScreenStack, SlideoutDecorator, Snackbar, Switch, Tab, TableCell, TableColumn, TableRow,
    TableRowDisplayEvent, TableRowExpansionEvent, Textbox
} from "@mcleod/components";
import { SearchFilterVisible } from "@mcleod/components/src/components/table/Table";
import {
    Alignment, Api, DisplayType, getLogger, HorizontalAlignment, Model, ModelRow, NumberUtil, StringUtil
} from "@mcleod/core";
import { getDispatchControlValue } from "@mcleod/general/src/models/ModelDispatchControl";
import { CarrierAssignmentPrompt } from "./CarrierAssignmentPrompt";
import { CarrierSelectionExpand } from "./CarrierSelectionExpand";
import { Project44Profile } from "./Project44Profile";
import { AutogenLayoutCarrierSelection } from "./autogen/AutogenLayoutCarrierSelection";
import { CarrierAssignment } from "@mcleod/powerbroker/src/CarrierAssignment";
import { SendLoadTender } from "@mcleod/datafusion/src/SendLoadTender";
import { QuoteEntryLTL } from "./brltl/QuoteEntryLTL";
import { Orders } from "./Orders";
import { CarrierSelectionRateChange } from "./CarrierSelectionRateChange";
import { BRLTLOrder, BrltlUtil } from "../BrltlUtil";

const log = getLogger("lme.dispatch.CarrierSelection");

interface ValidCarrierResult {
    isValid: boolean,
    reason_code?: string,
    reason?: string
}

export class CarrierSelection extends AutogenLayoutCarrierSelection {
    private _orderRow: ModelRow;
    private isQuoteOrder: boolean;
    private _defaultRow: ModelRow;
    private configurationProfile: ModelRow;
    private _chosenCarrier: ModelRow;
    private _selectedForDispatchCarrier: ModelRow;
    private _p44Record: ModelRow;
    private checkboxSelectAll: Checkbox;
    private allUnchecked = true;

    public doAfterChoosingCarrier: () => Promise<void>;

    private p44RatingModes: DropdownItem[] = [
        { caption: "LTL", value: "LTL" },
        { caption: "VOL", value: "VOL" },
        { caption: "LTL and VOL", value: "LTV" }
    ];

    public get orderRow(): ModelRow {
        return this._orderRow;
    }

    public set orderRow(value: ModelRow) {
        this._orderRow = value;
        this.isQuoteOrder = value.get("id").startsWith("Q");
        this.getRates();

        this.refreshHeaderInfo();
    }

    public get chosenCarrier(): ModelRow {
        return this._chosenCarrier;
    }

    public set chosenCarrier(value: ModelRow) {
        if (value != null && value.get("bfg_uid") != this.chosenCarrier?.get("bfg_uid")) {
            this._chosenCarrier = value;
            this.doAfterChoosingCarrier();
        }
        else {
            this._chosenCarrier = value;
        }
    }

    public get p44Record(): ModelRow {
        return this._p44Record;
    }

    public set p44Record(value: ModelRow) {
        this._p44Record = value;
    }

    public get selectedForDispatchCarrier(): ModelRow {
        return this._selectedForDispatchCarrier;
    }

    public set selectedForDispatchCarrier(value: ModelRow) {
        this._selectedForDispatchCarrier = value;
        if (this.isQuoteOrder) {
            this.buttonFindCarrierRates.visible = !value;
        }
    }

    public get defaultRow(): ModelRow {
        return this._defaultRow;
    }

    public set defaultRow(value: ModelRow) {
        this._defaultRow = value;
    }

    override async onLoad() {
        this.styleTab(this.tabSpotQuote);
        this.tableBFGs.addRowCollapseListener((collapseEvent) => {
            if (this.tableBFGs.rows.every(row => !row.expanded)) {
                this.switchExpandAll.checked = false;
            }
        });
        this.tableBFGs.addRowExpandListener((expandEvent) => {
            if (this.tableBFGs.rows.every(row => row.expanded)) {
                this.switchExpandAll.checked = true;
            }
        });
        this.p44Record = await Model.searchSingleRecord("lme/powerbroker/contract-management-control", { id: "P44" });
    }

    public async getRates() {
        const rateRows = [];
        const errorRows = [];
        const allRows: ModelRow[] = this.mainDataSource.data;
        this.sortBFGData(allRows, rateRows, errorRows);
        this.tableBFGs.searchFilterVisible = SearchFilterVisible.NEITHER;
        this.tableBFGs.data = rateRows;
        this.tableBFGs.data.forEach(row => {
            if (row.get("bfg_status_code") == "CHOS") this.chosenCarrier = row;
            if (row.get("bfg_status_code") == "SFD") this.selectedForDispatchCarrier = row;
        });
        if (!!this.chosenCarrier || !!this.selectedForDispatchCarrier) this.buttonChangeMarkups.visible = false;
        this.tableBFGs.displayData(null, rateRows, 0);
        this.tableErrors.data = errorRows;
        this.tableErrors.displayData(null, errorRows, 0);
        this.tabRates.caption = "Rates" + ` (${rateRows.length})`;
        this.tabErrors.caption = "Errors" + ` (${errorRows.length})`;
        if (errorRows.length > 0) {
            this.tabErrors.headingLabel.color = "error.lightest";
        }

        this.expandAll(getDispatchControlValue("carrier_select_expand_all") == "Y");

        if (this.p44Record.get("is_active") == "Y") {
            this.textboxRatingMode.visible = true;
            this.textboxP44PaymentTerms.visible = true;
            this.textboxP44PaymentTerms.selectedItem = (this.textboxP44PaymentTerms.items as DropdownItem[]).find(item => item.value == this.defaultRow?.get("payment_terms"));
            this.textboxRatingMode.selectedItem = (this.textboxRatingMode.items as DropdownItem[])?.find(item => item.value == this.defaultRow?.get("rating_mode"));
            this.textboxP44DirectionOverride.visible = true;
            this.buttonP44Settings.caption = "P44 Settings";
            this.textboxRatingMode.items = this.p44RatingModes;
            this.textboxP44DirectionOverride.selectedItem = (this.textboxP44DirectionOverride.items as DropdownItem[]).find(item => item.value == this.defaultRow?.get("p44_direction_override"));
            this.textboxP44PaymentTerms.tooltip = "Select value to override payment terms for P44";
        }
        if (null != this.isQuoteOrder) {
            if (this.isQuoteOrder) {
                this.configureSelectColumn();
                this.syncSelectAllCheckbox();
            }
            else if (this.isQuoteOrder === false)
                this.tableBFGs.removeColumn(this.tableBFGs.columns.find(col => "columnHeaderSelected" === col.headingCell.id));
        }
        const previouslySelectedItem = (this.textboxRatingMode.items as DropdownItem[])?.find(item => item.value == this.defaultRow?.get("rating_mode"));
        if (previouslySelectedItem) {
            this.textboxRatingMode.selectedItem = previouslySelectedItem;
        }
    }

    configureSelectColumn() {
        const column: TableColumn = this.tableBFGs.columns.find(col => "columnHeaderSelected" === col.headingCell.id);
        const headingCell: TableCell = column.headingCell;
        this.checkboxSelectAll = new Checkbox({
            id: "checkboxSelectAll",
            align: HorizontalAlignment.LEFT,
            color: "primary",
            checkSize: 28,
            padding: 0,
            onClick: (event) => this.checkboxSelectAllOnClick(event)
        });
        this.checkboxSelectAll.allowIndeterminate = true;
        this.checkboxSelectAll.enabled = true;
        headingCell.components = [];
        headingCell.add(this.checkboxSelectAll);
    }

    checkboxSelectAllOnClick(event: ClickEvent) {
        this.tableBFGs.allRows.forEach(tableRow => {
            const modelRow = tableRow.data as ModelRow;
            const cb: Checkbox = tableRow.findComponentById("checkboxInclude") as Checkbox;
            cb.checked = this.allUnchecked;
            modelRow.set("bfg_status_code", !this.allUnchecked ? "EXCL" : "INCL");
            modelRow.post();
        });
        this.syncSelectAllCheckbox();
    }

    syncSelectAllCheckbox() {
        if (this.tableBFGs.allRows.length == 0 || this.selectedForDispatchCarrier) {
            this.checkboxSelectAll.enabled = false;
            this.tableBFGs.allRows.forEach((tableRow: TableRow) => {
                const cb: Checkbox = tableRow.findComponentById("checkboxInclude") as Checkbox;
                cb.enabled = false;
            })
            if (!this.selectedForDispatchCarrier) {
                this.allUnchecked = true;
                return;
            }
        } else {
            this.checkboxSelectAll.enabled = true;
        }
        if (this.tableBFGs.rows.every(tableRow => {
            return !this.isIncludedCarrierQuote(tableRow.data);
        })) { // check if all checkboxes are not checked
            this.checkboxSelectAll.checked = false;
            this.allUnchecked = true;
        } else if (this.tableBFGs.rows.every(tableRow => {
            return this.isIncludedCarrierQuote(tableRow.data);
        })) { // check if all checkboxes are checked
            this.checkboxSelectAll.checked = true;
            this.allUnchecked = false;
        } else {
            this.checkboxSelectAll.checked = undefined;
            this.allUnchecked = false;
        }
        this.checkboxSelectAll.tooltip = !this.allUnchecked ? "Unselect all" : "Select all";
    }

    isIncludedCarrierQuote(bfgRow: ModelRow) {
        return bfgRow.get("bfg_status_code") !== "EXCL";
    }

    checkboxIncludeOnClick(event: ClickEvent) {
        const checkbox: Checkbox = event.target as Checkbox;
        const tableRow: TableRow = TableRow.getContainingTableRow(checkbox);
        const modelRow: ModelRow = tableRow.data;
        modelRow.set("bfg_status_code", checkbox.checked ? "INCL" : "EXCL");
        modelRow.post();
        this.syncSelectAllCheckbox();
    }

    sortBFGData(allRows: ModelRow[], rateRows, errorRows) {
        allRows.forEach(row => {
            if (row.data["bfg_type_code"] == "QERR" || row.data["bfg_type_code"] == "QPHB")
                errorRows.push(row);
            else if (row.data["bfg_type_code"] == "DFLT") {
                this.defaultRow = row;
            } else rateRows.push(row);
        });
        const rateWeights = {
            "CHOS": 0, // Chosen
            "SFD": 0, // Selected for Dispatch
            "Manual": 1, // Spot Quote
            "P44": 3 // Incomplete Setup
        }
        rateRows = rateRows.sort((a, b) => {
            const aWeight = rateWeights[a.get("bfg_status_code")] ?? rateWeights[a.get("rating_source")] ?? rateWeights[a.get("carrier_scac")] ?? 2;
            const bWeight = rateWeights[b.get("bfg_status_code")] ?? rateWeights[b.get("rating_source")] ?? rateWeights[b.get("carrier_scac")] ?? 2;
            if (aWeight == bWeight) {
                if (getDispatchControlValue("carrier_assignment_prompt") == "C") {
                    return a.get("total_revenue_amount").base_amount - b.get("total_revenue_amount").base_amount;
                }
                else if (getDispatchControlValue("carrier_assignment_prompt") == "S" || getDispatchControlValue("carrier_assignment_prompt") == "N") {
                    return a.get("total_charge_amount").base_amount - b.get("total_charge_amount").base_amount;
                }
            }
            return aWeight - bWeight;
        });
    }

    expandAll(shouldBeExpanded: boolean) {
        this.switchExpandAll.checked = shouldBeExpanded;
        for (const tableRow of this.tableBFGs.rows) {
            tableRow.setExpanded(shouldBeExpanded);
        }
        if (this.tableBFGs.data.length > 0) this.tableBFGs.rows[0].scrollIntoView();
    }

    switchExpandAllOnChange(event: ChangeEvent) {
        if (event.userInitiatedChange) {
            const switchExpand = event.target as Switch;
            this.expandAll(switchExpand.checked);
        }
    }

    updateDefaultRow() {
        this.defaultRow.set("rating_mode", this.textboxRatingMode.selectedItem?.value ?? null);
        this.defaultRow.set("p44_direction_override", this.textboxP44DirectionOverride.selectedItem?.value ?? null);
        this.defaultRow.set("p44_payment_terms", this.textboxP44PaymentTerms.selectedItem?.value ?? null);
    }

    async findCarrierRatesOnClick(event: ClickEvent) {
        const rerateButton = event.target as Button;
        rerateButton.busy = true;
        this.tableBFGs.rows.forEach((row) => row.findComponentById("buttonChooseCarrier").enabled = false)
        this.updateDefaultRow();
        if (this.defaultRow.hasChanged()) {
            await this.defaultRow.post();
        }
        await CarrierSelection.findCarrierRates(this.activeRow.get("order_id"), this.configurationProfile, () => this.mainDataSource.search({ order_id: this.orderRow.get("id") }));
        rerateButton.busy = false;
        if (this.chosenCarrier == null && (!this.isQuoteOrder || this.orderRow.get("quote_status") == "A")) {
            this.tableBFGs.rows.forEach((row) => row.findComponentById("buttonChooseCarrier").enabled = true)
        }
        this.expandAll(this.switchExpandAll.checked);
        if (this.isQuoteOrder)
            this.syncSelectAllCheckbox();
    }

    static async findCarrierRates(orderId: String, configurationProfile?: ModelRow, afterRerate?: () => Promise<any>, snackbarTarget: Panel = ScreenStack.getSnackbarTarget()) {
        const progressSnackbar: Snackbar = Snackbar.showDownloadSnackbar("Carrier Rerating", "Finding Carrier Rates...", {
            persist: true,
            targetPanel: snackbarTarget
        });
        const result = (await Api.search("lme/powerbroker/find-carrier-rates", {
            order_id: orderId, configuration: configurationProfile
        })).data[0];
        progressSnackbar.dismiss();
        const requestStatus = result["request_status"];
        const responseMessage = result["response_message"];
        progressSnackbar.dismiss();
        if (responseMessage != null) {
            Snackbar.showWarningSnackbar(responseMessage);
            if (requestStatus !== "SUCCESS") {
                return false;
            }
        }
        if (result["new_chosen_bfg_uid"] != null) {
            const updatedRates = (await Api.search("lme/dispatch/brltl-billing-freight-group", { new_chosen_bfg_uid: result["new_chosen_bfg_uid"] })).data;
            const [currentRate, newRate] = updatedRates.map((rate: Partial<any>) => new ModelRow("lme/dispatch/brltl-billing-freight-group", false, rate)).sort((a, b) => a.get("bfg_status_code") == "CHOS" ? -1 : 1);
            CarrierSelectionRateChange.showSlideout(currentRate, newRate, afterRerate);
        } else {
            await afterRerate?.();
        }
        return true;
    }

    async p44SettingsOnClick(event: ClickEvent) {
        if (this.p44Record.get("is_active") == "Y") {
            const project44ProfileLayout = Layout.getLayout("lme/dispatch/Project44Profile") as Project44Profile;
            new SlideoutDecorator({
                layout: project44ProfileLayout,
                layoutLoadListeners: async () => {
                    if (this.configurationProfile) {
                        project44ProfileLayout.mainDataSource.setRowsAndMode(DataSourceMode.NONE, [this.configurationProfile]);
                    } else {
                        // first check if there is a default p44 profile for the customer
                        let p44Profile = await Model.searchSingleRecord("lme/ar/customer", { id: this.orderRow.get("customer_id") });
                        if (p44Profile.data?.p44_profile_id == null) {
                            p44Profile = await Model.searchSingleRecord("lme/powerbroker/contract-management-control", { id: "P44" });
                        }
                        const p44DefaultControlId = p44Profile.data?.p44_profile_id;
                        if (p44DefaultControlId) {
                            project44ProfileLayout.mainDataSource.search({ id: p44DefaultControlId });
                        }
                    }
                    project44ProfileLayout.textboxId.printable = true;
                    project44ProfileLayout.textboxDescription.printable = true;
                    project44ProfileLayout.switchCarrierConfiguration.visible = false;
                },
                fillVerticalSpace: true,
                transitionOptions: { direction: Alignment.RIGHT },
                title: "Project44 Settings",
                addlComponents: new Button({
                    id: "submitP44Btn",
                    caption: "Rerate",
                    onClick: () => this.p44SettingsOnSave(project44ProfileLayout),
                    backgroundColor: "primary",
                    color: "primary.reverse",
                    borderWidth: 0,
                    minWidth: 128,
                    rowBreak: false
                })
            })
        }
    }

    p44SettingsOnSave(p44Layout: Project44Profile) {
        this.configurationProfile = p44Layout.mainDataSource.getDataboundValues();
        p44Layout.slideOut().then(() => {
            this.buttonFindCarrierRates.clicked();
        });
    }

    updateDefaultBFG(event: ChangeEvent) {
        if (event.userInitiatedChange) {
            const textbox = event.target as Textbox;
            this.defaultRow.set(textbox.field, textbox.selectedItem?.value ?? null);
        }
    }

    tableBFGsOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.getTableRow();
        const labelBlanketRate = tableRow.findComponentById("labelBlanketRate") as Label;
        labelBlanketRate.visible = tableRow.data.get("bfg_type_code", "") === "BCST";

        if (this.isQuoteOrder && this.isIncludedCarrierQuote(tableRow.data)) {
            const cb: Checkbox = tableRow.findComponentById("checkboxInclude") as Checkbox;
            cb.checked = true;
        }
        const labelTransit = tableRow.findComponentById("labelTransit") as Label;
        labelTransit.style.textAlign = "left";

        // Carrier Qualification logic
        const qualification = tableRow.data.get("qualification_status", null);
        const inadequateTransit: boolean = tableRow.data.get("inadequate_transit_time", false);
        const isExpired: boolean = tableRow.data?.get("is_expired", false);
        const canMoveHazmat: boolean = tableRow.data?.get("can_move_hazmat", false);
        const labelCarrierInformation = tableRow.findComponentById("labelCarrierInformation") as Label;
        labelCarrierInformation.style.textAlign = "left";
        CommonTooltips.setTooltipFromLayoutCallback(labelCarrierInformation, tableRow.data, "lme/dispatch/brltl/CarrierInformationQuickInfo");
        const panelImage = tableRow.findComponentById("panelImage") as Panel;
        const imageBytes = tableRow.data.get("image_bytes", null);
        if (imageBytes != null) {
            if (panelImage.getComponentCount() == 0) {
                const carrierImage = new Image({
                    id: "carrierImg",
                    imageType: ImageType.IMG,
                    preserveAspectRatio: true,
                    height: 50,
                    width: 100
                });
                carrierImage.imageBytes = imageBytes;
                panelImage.add(carrierImage);
            }
        } else {
            panelImage.visible = false;
            labelCarrierInformation.marginLeft = 12;
        }
        const carrierQualPanel = tableRow.findComponentById("panelCarrierQualification") as Panel;
        let qualificationImage: Image;
        const buttonChooseCarrier = tableRow.findComponentById("buttonChooseCarrier") as Button;
        if (StringUtil.equalsIgnoreCase("Disqualified", qualification) || isExpired || !canMoveHazmat) {
            qualificationImage = new Image({ name: "rateConExpiring", color: "error", width: "20", height: "20" });
            CommonTooltips.setTooltipFromLayoutCallback(qualificationImage, tableRow.data, "lme/dispatch/CarrierQualificationDetailsQuickInfo");
            buttonChooseCarrier.enabled = false;
        } else if (StringUtil.equalsIgnoreCase("Warning", qualification) || tableRow.data.get("bfg_comment") != null) {
            qualificationImage = new Image({ name: "warning", color: "warning", width: "24", height: "24" });
            if (StringUtil.equalsIgnoreCase("Warning", qualification)) {
                CommonTooltips.setTooltipFromLayoutCallback(qualificationImage, tableRow.data, "lme/dispatch/CarrierQualificationDetailsQuickInfo");
            } else {
                CommonTooltips.setTooltipCallback(qualificationImage, tableRow.data.get("bfg_comment"));
            }
        } else if (inadequateTransit === true) {
            qualificationImage = new Image({
                name: "circleQuestion",
                color: "caution",
                width: "24",
                height: "24",
                tooltip: "Carrier might not make the Transit Time"
            });
        }
        if (qualificationImage && carrierQualPanel.getComponentCount() == 0) {
            carrierQualPanel.add(qualificationImage);
        }

        // Choose button logic
        buttonChooseCarrier.caption = this.getChooseCaption(tableRow.data);
        buttonChooseCarrier.disabledTooltip = (() => {
            if (!this.isQuoteOrder && this.chosenCarrier != null) {
                return "A carrier has already been selected for this order.";
            } else if (this.isQuoteOrder) {
                if (!!this.selectedForDispatchCarrier) {
                    return "A carrier has already been selected for this quote.";
                } else if (this.orderRow.get("quote_status") != "A") {
                    return "Quote status must be Approved prior to selecting a carrier quote.";
                }
            }
            return null;
        })();
        buttonChooseCarrier.enabled = buttonChooseCarrier.disabledTooltip === null;

        // highlight the chosen carrier if there is one
        if (tableRow.data.get("bfg_uid") == (this.chosenCarrier?.get("bfg_uid") ?? this.selectedForDispatchCarrier?.get("bfg_uid"))) {
            tableRow.backgroundColor = "success.lightest";
            if (this.selectedForDispatchCarrier) {
                buttonChooseCarrier.caption = "Tender";
                buttonChooseCarrier.enabled = true;
            }
        }
    }

    getChooseCaption(bfgRow: ModelRow) {
        const p44Caption = "P44 Dispatch";
        const defaultCaption = "Choose";
        if (this.p44Record.getBoolean("is_active") && this.configurationProfile != null) {
            if (("P" === this.configurationProfile.get("send_disp_request") && "P44" === bfgRow.get("calc_type_code")) || ("A" === this.configurationProfile.get("send_disp_request"))) {
                return p44Caption
            }
            else {
                return defaultCaption;
            }
        }
        return bfgRow.getBoolean("dispatch_to_p44") ? p44Caption : defaultCaption;
    }

    public tableBFGsOnRowExpand(expansionEvent: TableRowExpansionEvent) {
        const tableRow: TableRow = expansionEvent.target as TableRow;
        const expandPanel = expansionEvent.expandComponentParent as Panel;
        const layoutExpanded: CarrierSelectionExpand = expandPanel.findComponentById("layoutCarrierSelectionExpand") as CarrierSelectionExpand;
        if (expansionEvent.isExpanding) {
            layoutExpanded.addLayoutDataLoadListener(async () => {
                if (this.chosenCarrier || (this.isQuoteOrder && this.selectedForDispatchCarrier)) {
                    layoutExpanded.tableRevenueDetails.rowModeControlType = RowModeControlType.AUTO;
                }
                layoutExpanded.chosenCarrier = this.chosenCarrier;
                layoutExpanded.setParentTableRow(tableRow);
                layoutExpanded.mainDataSource.data = tableRow.data.get("revenue_details").map((row: Partial<any>) => new ModelRow("lme/dispatch/revenue-detail", false, row)).sort(this.sortRevenueDetails);
                layoutExpanded.mainDataSource.displayDataInBoundComponents();
                layoutExpanded.tableRevenueDetails.columnHeadingsVisible = false;
                this.panelTableBfg.rows[0].style.height = "100%"; // pretty nasty hack to make the vertical scroll bar behave
            })
        }
    }

    private sortRevenueDetails = (a: ModelRow, b: ModelRow) => {
        enum revDetailWeights {
            BASE,
            DEF,
            DEFW,
            ASWT,
            ASWE,
            MAXB,
            MBAS,
            OBCH,
            OVCH,
            OGCH,
            OVBA,
            OVBR,
            OBBA,
            OBBR,
            ODIS,
            OGBA,
            OGBR,
            BFIX,
            DNAG,
            DISC,
            FUEL
        }

        const aWeight = revDetailWeights[`${a.get("gla_code")}`] ?? 100;
        const bWeight = revDetailWeights[`${b.get("gla_code")}`] ?? 100;
        return aWeight - bWeight;
    };

    private async buttonChooseCarrierOnClick(event: ClickEvent) {
        const button = event.target as Button;
        const tableRow = TableRow.getContainingTableRow(button);
        button.busy = true;
        try {
            await tableRow.data.post();
            const carrierValidation = await this.validateCarrierForDispatch(tableRow);
            if (carrierValidation.isValid) {
                if (this.isQuoteOrder) {
                    await this.selectCarrierForDispatch(tableRow);
                } else {
                    await this.assignCarrier(carrierValidation, tableRow);
                    this.sendLoadTender();
                }
            }
        } catch (error) {
            log.error(error);
            CommonDialogs.showServerError(error);
        }
        button.busy = false;
    }

    async assignCarrier(carrierValidation: ValidCarrierResult, tableRow: TableRow) {
        return Api.post("lme/dispatch/carrier-dispatch", {
            bfg_uid: tableRow.data.get("bfg_uid"),
            reason_code: carrierValidation.reason_code,
            reason: carrierValidation.reason,
            send_disp_request: this.configurationProfile?.get("send_disp_request")
        }).then(() => {
            this.selectedForDispatchCarrier = null;
            this.chosenCarrier = tableRow.data;
            this.mainDataSource.search({ order_id: this.activeRow.get("order_id") });
        });
    }

    async selectCarrierForDispatch(tableRow: TableRow) {
        if (this.selectedForDispatchCarrier) {
            Api.post("lme/dispatch/brltl/get-created-order-id-for-quote", { quote_id: this.activeRow.get("order_id") }).then(result => {
                CommonDialogs.showMessage("Order " + result.data[0].order_id + " has already been created from this quote.", "Order Created")
            });
        } else {
            const oldStatusCode = tableRow.data.get("bfg_status_code", "");
            try {
                tableRow.data.set("bfg_status_code", "SFD"); // Set status to Selected for Dispatch
                await tableRow.data.post();
                await this.doAfterChoosingCarrier();
            }
            catch (error) {
                log.error(error);
                CommonDialogs.showServerError(error);
                tableRow.data.set("bfg_status_code", oldStatusCode); // Set status to Selected for Dispatch
                await tableRow.data.post();
            }
        }
    }

    private async validateCarrierForDispatch(tableRow: TableRow) {
        let result: ValidCarrierResult = { isValid: true };
        const selectedCarrier = tableRow.data as ModelRow;
        const response = await Api.post("lme/dispatch/carrier-dispatch-validation", {
            bfg_uid: selectedCarrier.get("bfg_uid"),
            is_quote: this.isQuoteOrder
        });
        const selectedForDispatch = response.data[0]?.selected_for_dispatch;
        const warnings: String[] = response.data[0]?.warnings;
        if (!selectedForDispatch) {
            const reasonDesc = this.generateCarrierAssignPromptMessage(selectedCarrier);
            if (!!reasonDesc) {
                result = await this.awaitCarrierResponsePrompt(reasonDesc);
            }
        }
        if (result.isValid && warnings.length > 0) {
            result = await this.awaitCarrierQualPrompt();
        }
        return result;
    }

    private generateCarrierAssignPromptMessage(selectedCarrier: ModelRow) {
        switch (getDispatchControlValue("carrier_assignment_prompt")) {
            case "C": { // Lowest Cost
                return this.getLowestCarrierMessage(selectedCarrier, "total_revenue_amount");
            }
            case "S": { // Lowest Charge
                return this.getLowestCarrierMessage(selectedCarrier, "total_charge_amount");
            }
            default:
                return null;
        }
    }

    private getLowestCarrierMessage(selectedCarrier: ModelRow, field: string) {
        const lowestCarrier = this.tableBFGs.data.sort((a, b) => a.get(field).amount - b.get(field).amount)[0];
        if (lowestCarrier.get("bfg_uid") == selectedCarrier.get("bfg_uid")) return null; // Has qualification warnings but does not fall into this case
        return `${selectedCarrier.get("carrier_name")} rate of ${selectedCarrier.getCurrency(field)} was selected over the cheaper ${lowestCarrier.get("carrier_name")} rate of ${lowestCarrier.getCurrency(field)}.`;
    }

    private async awaitCarrierResponsePrompt(reasonDesc: string) {
        const layout = Layout.getLayout("lme/dispatch/CarrierAssignmentPrompt") as CarrierAssignmentPrompt;
        layout.addLayoutLoadListener(() => {
            layout.textboxReasonCode.addBeforeLookupModelSearchListener((event) => {
                event.filter.code_type = "CAREX"
            })
            layout.textboxReason.text = reasonDesc;
        })
        const clickedYes = await CommonDialogs.showYesNo(layout, "Assignment Reason", {
            width: 500, yesButtonCaption: "OK", noButtonCaption: "Cancel"
        });
        return {
            isValid: clickedYes,
            reason_code: layout.textboxReasonCode.text,
            reason: layout.textboxReason.text
        };
    }

    private async awaitCarrierQualPrompt() {
        return {
            isValid: await CommonDialogs.showYesNo("Carrier has a warning status based on the carrier qualification profile. Continue?", "Carrier Assignment", {
                yesButtonCaption: "OK", noButtonCaption: "Cancel"
            })
        };
    }

    sendLoadTender() {
        const carrierId = this.chosenCarrier.get("payee_id");
        const movementId = this.chosenCarrier.get("movement_id");
        CarrierAssignment.checkCarrierForDataFusion(carrierId).then(result => {
            if (result == "auto") {
                CarrierAssignment.sendAutoTender(carrierId, movementId).then(result => {
                    const tenderSent = result.data[0]["tendersent"];
                    log.debug("Result: " + tenderSent);
                    if (tenderSent) {
                        const notes = result.data[0].text;
                        Snackbar.showSnackbar("Result: " + notes + ".");
                    } else {
                        Snackbar.showWarningSnackbar("Failed to send outbound tender to DataFusion carrier " + carrierId + ": " + result.data[0].text);
                    }
                });
            } else if (result == "manual") {
                const slideoutProps = {
                    width: 1200, height: window.innerHeight, backgroundColor: "defaultBackground", top: 30, padding: 0,
                    borderRadius: 4, borderWidth: 1, borderShadow: true, borderColor: "strokeSecondary"
                };
                Layout.getLayout("lme/datafusion/SendLoadTender", {
                    ...slideoutProps,
                    width: 1000
                }).addLayoutLoadListener(event => {
                    const layout = event.target as SendLoadTender;
                    layout.buttonCancel.addClickListener(() => layout.slideOut());
                    layout.buttonSendLater.visible = false;
                    layout.search({
                        carrier: carrierId,
                        move_id: movementId,
                        is_cancel: "false",
                        is_auto: "false"
                    }).then(result => {
                        if (result?.modelRows?.length > 0) {
                            layout.setAllDataSourcesToMode(DataSourceMode.UPDATE);
                            layout.slideIn({ direction: Alignment.RIGHT }, true, {
                                closeOnClickOff: false,
                                greyedBackground: true
                            });
                        }
                    });
                });
            }
        });
    }

    buttonAddSpotQuoteOnClick(event: ClickEvent) {
        const button = event.target as Button;
        if (this.tabSpotQuote.validateSimple()) {
            button.busy = true;
            Api.post("lme/dispatch/carrier-spot-quote", {
                order_id: this.activeRow.get("order_id"),
                transit_days: this.textboxTransitDays.text,
                payee_id: this.textboxCarrier.getFirstLookupModelData()?.get("id"),
                base_charge: !StringUtil.isEmptyString(this.textboxBaseAmount.text) ? this.textboxBaseAmount.getDataValue() : null,
                source: this.textboxSource.selectedItem?.caption,
                ref_num: this.textboxReference.getDataValue(),
                fuel_charge: !StringUtil.isEmptyString(this.textboxFuelAmount.text) ? this.textboxFuelAmount.getDataValue() : null,
                accessorial_charge: !StringUtil.isEmptyString(this.textboxAccessorialAmount.text) ? this.textboxAccessorialAmount.getDataValue() : null
            }).then(async result => {
                if (result.data[0]?.error) {
                    Snackbar.showWarningSnackbar(result.data[0]?.error);
                } else {
                    this.tabSpotQuote.forEveryChildComponent(component => component instanceof Textbox ? component.clear() : null);
                    this.tabSpotQuote.collapseNow();
                    await this.mainDataSource.search({ order_id: this.activeRow.get("order_id") });
                    this.expandAll(this.switchExpandAll.checked);
                }
            }).finally(() => {
                button.busy = false;
            })
        }
    }

    buttonChangeMarkupsOnClick(event: ClickEvent) {
        CommonDialogs.showInputDialog(null, "Change Markups", {
            placeholder: "Enter a new markup amount", displayType: DisplayType.DECIMAL
        }).then(result => {
            if (result) {
                this.expandAll(true);
                this.tableBFGs.rows.forEach(tableRow => {
                    const layoutExpanded = ((tableRow.getExpansionComponent() as Panel).findComponentById("layoutCarrierSelectionExpand") as CarrierSelectionExpand)
                    layoutExpanded.addLayoutDataLoadListener(() => {
                        layoutExpanded.tableRevenueDetails.rows.forEach(row => {
                            if (row.data.get("markup_amount") != null) {
                                row.data.set("markup_amount", result);
                                layoutExpanded.performCalculationsOnRatingData(row.data, "markup_amount");
                            }
                        });
                    });
                });
            }
        });
    }

    styleTab(tab: Tab) {
        if (tab.heading != null && tab.headingLabel != null) {
            tab.heading.setProps({ color: "subtle.light", fontBold: false, borderWidth: 0 });
            tab.headingLabel.setProps({ color: "default.light", fontSize: "medium", fontBold: true });
        }
    }

    sourceBrltlBillingFreightGroupAfterExecution(event) {
        this.getRates();
    }

    refreshHeaderInfo() {
        const modelPath = this.isQuoteOrder ? "lme/dispatch/brltl/quote-freight-group-item" : "lme/dispatch/brltl-order-freight-group-item";
        Model.search(modelPath, {
            lme_order_id: this.orderRow.get("id"),
            fgp_uid: this.orderRow.get("fgp_uid")
        }).then(result => {
            const firstFreightClass = result.modelRows[0]?.get("nmfc_class_code");
            const totalWeight = result.modelRows.reduce((currentTotal, row) => currentTotal + row.get("weight"), 0);
            const freightClass = result.modelRows?.every(row => row.get("nmfc_class_code") == firstFreightClass) ? firstFreightClass : "Multiple";
            const orderAndStopInfo = new ModelRow("lme/dispatch/orders", false, {
                stop_origin_sched_arrive_early: (this.orderRow as ModelRow).get("stop_origin.sched_arrive_early"),
                stop_origin_city_name: (this.orderRow as ModelRow).get("stop_origin.city_name"),
                stop_origin_state: (this.orderRow as ModelRow).get("stop_origin.state"),
                stop_consignee_city_name: (this.orderRow as ModelRow).get("stop_consignee.city_name"),
                stop_consignee_state: (this.orderRow as ModelRow).get("stop_consignee.state"),
                total_weight: NumberUtil.formatDecimal(totalWeight ?? 0, "#,###.#") + " lbs",
                freight_class: freightClass
            })
            this.panelOverview.displayData(orderAndStopInfo, null, 0);
        });
    }

    static async shouldRerate(ordersLayout: Orders | QuoteEntryLTL, showPrompt: boolean = true): Promise<boolean> {
        const sourceFreightGroupItem = ordersLayout.getFreightGroupItemDataSource();
        const sourceStop = ordersLayout.getStopDataSource();
        const sourceHdrXFgp = ordersLayout.getHdrXFgpDataSource();
        const sourceBFG = ordersLayout.getBillingFreightGroupDataSource();
        const brltlData: BRLTLOrder = {
            revenue_code_id: ordersLayout.activeRow.get("revenue_code_id"),
            order_type_id: ordersLayout.activeRow.get("order_type_id"),
            order_mode: ordersLayout.activeRow.get("order_mode"),
            no_freight_group_items: sourceFreightGroupItem.data.length == 0
        }

        if (BrltlUtil.isBRLTLOrder(brltlData) && ordersLayout.activeRow.get("status") != "V") {
            const orderChangedData = ordersLayout.activeRow.getChangedData();
            const stopsChanged = sourceStop.data.some(stop => {
                const changedData = stop.getChangedData();
                return changedData.hasOwnProperty("sched_arrive_early") || changedData.hasOwnProperty("sched_arrive_late") || changedData.hasOwnProperty("location_id") || changedData.hasOwnProperty("zip_code") || changedData.hasOwnProperty("city_id") || changedData.hasOwnProperty("appt_required") || changedData.hasOwnProperty("confirmed");
            });
            const freightItemsChanged = sourceFreightGroupItem.hasChanged();
            const accessorialsChanged = sourceHdrXFgp.hasChanged();
            const billingFreightGroupChanged = sourceBFG.data.filter(row => row.get("bfg_type_code") != "DFLT").some(row => row.hasChanged());

            const rerateConsignedBfgControlValue = getDispatchControlValue("rerate_consigned_bfg");
            const chosenCarrier = sourceBFG.data.some(row => row.get("bfg_status_code") == "CHOS");
            if (chosenCarrier && rerateConsignedBfgControlValue === "N") return false;

            if (orderChangedData.hasOwnProperty("customer_id") || orderChangedData.hasOwnProperty("order_type_id") || stopsChanged || billingFreightGroupChanged || freightItemsChanged || accessorialsChanged) {
                const caption = "Updating consigned order may affect rates.  Would you like to re-rate?";
                return showPrompt && chosenCarrier && rerateConsignedBfgControlValue === "P" ? await CommonDialogs.showYesNo(caption, "Re-rate Order") : true
            }
        }
        return false;
    }
}
