import { BlurEvent, Button, ClickEvent, DataSource, DataSourceExecutionEvent, DataSourceMode, DataSourceModeChangeEvent, DropdownItem, Panel, Textbox } from "@mcleod/components";
import { TooltipCallback } from "@mcleod/components/src/base/ComponentProps";
import { Api, DisplayType, ModelRow } from "@mcleod/core";
import { getDispatchControlBoolean } from "@mcleod/general/src/models/ModelDispatchControl";
import { setAutoRateContext, setRateListeners } from "./RateUtil";
import { AutogenLayoutRecOrderRates } from "./autogen/AutogenLayoutRecOrderRates";
import { OtherChargesUtil } from "./OtherChargesUtil";

export class RecOrderRates extends AutogenLayoutRecOrderRates {

    private _allocationEnabled: boolean;
    private _calculatingCharges = false;
    private chargePanelExpanded: boolean = true;
    private estimateFuelSurcharge: boolean = getDispatchControlBoolean("est_fuel_surcharge")
    private autoRate: boolean = getDispatchControlBoolean("auto_rate");
    private otherChargesUtil: OtherChargesUtil = new OtherChargesUtil();
    doAfterRating: () => void;
    checkPickupAndDeliveryEntered = () => true;

  override onLoad(): void {
    this.textboxCollectionMethod.items = [{ caption: "Prepaid", value: "P" }, {
      caption: "Third party", value: "T"
    }, { caption: "Collect", value: "C" }];
    this.textboxCtrlParty.textCombined.manualAddLayout = null;
    this.buttonEstimateFuelSurcharge.visible = !this.estimateFuelSurcharge;
    this.textboxRateType.addChangeListener(event => {
      this.labelAutoRated.visible = false;
      this.textboxRate.tooltipCallback = null;
      const textbox = event.target as Textbox;
      const newValue = textbox.selectedItem?.value;
      this.activeRow.set(textbox.field, newValue);
      if (newValue == "O") {
        this.textboxRateUnits.enabled = true;
        this.textboxRateUnits.enabledDuringAdd = true;
        this.textboxRateUnits.enabledDuringUpdate = true;
      }
      else {
        this.textboxRateUnits.enabled = false;
        this.textboxRateUnits.enabledDuringAdd = false;
        this.textboxRateUnits.enabledDuringUpdate = false;
      }
    });
    this.otherChargesUtil.initialize(this);
  }

    /** This is an event handler for the afterExecution event of sourceRecurringOrder.  */
    sourceRecurringOrderAfterExecution(event: DataSourceExecutionEvent) {
        setAutoRateContext(this, this.mainDataSource.activeRow.get("rate_id"));
    }

    /** This is an event handler for the afterModeChange event of sourceRecurringOrder.  */
    sourceRecurringOrderAfterModeChange(event: DataSourceModeChangeEvent) {
        if ((event.newMode == DataSourceMode.ADD || event.newMode == DataSourceMode.UPDATE) && event.newMode != event.oldMode) {
            this.sourceOrderOtherChargeRec.mode = DataSourceMode.ADD;
            this.checkSegAllocEnabled();
        }
        setRateListeners(this);
    }

    set calculatingCharges(value: boolean) {
        this._calculatingCharges = value;
    }

    get calculatingCharges(): boolean {
        return this._calculatingCharges;
    }

    textboxSegAllocCodeBeforeLookupModelSearch(event) {
        event.filter.alloc_type = 'M';
        event.filter.is_active = 'Y';
    }

    buttonExpandChargesOnClick(event: ClickEvent) {
        const transitionProps = { speed: 100, paddingTop: 0 };
        if (this.chargePanelExpanded) this.panelOtherChargeTable.collapse(transitionProps).then(() => {
            this.buttonExpandCharges.imageRotation = 90
        }); else this.panelOtherChargeTable.expand(transitionProps).then(() => {
            this.buttonExpandCharges.imageRotation = 0
        });
        this.chargePanelExpanded = !this.chargePanelExpanded;
    }

    private checkSegAllocEnabled() {
        this._allocationEnabled = this.sourceRecurringOrder.activeRow?.get("seg_alloc_enabled");
        delete this.sourceRecurringOrder.activeRow?.data["seg_alloc_enabled"];
        this.textboxSegAllocCode.visible = this._allocationEnabled;
        // May need to make this required in a future card
        //this.textboxSegAllocCode.required = this._allocationEnabled;
        if (!this._allocationEnabled) this.textboxSegAllocCode.dataSource = null;
    }

    /** This is an event handler for the onRowDisplay event of tableOtherCharges.  */
    tableOtherChargesOnRowDisplay(event) {
        const chargeId: Textbox = event.getTableRow().findComponentById("textboxChargeId") as Textbox;
        const textboxDescr: Textbox = event.getTableRow().findComponentById("textboxDescr") as Textbox;
        if (chargeId != null && chargeId.onSelectItem == null) {
            chargeId.onSelectItem = ((textbox, selectedItem) => {
                textboxDescr.text = (selectedItem as ModelRow).get("descr");
                this.chargeIdChanged(textbox, selectedItem);
                return undefined;
            });
        }
    }

    getOtherChargeDatasource(): DataSource {
    return this.sourceOrderOtherChargeRec;
  }

  otherChargeOnBlur(event: BlurEvent) {
    this.otherChargesUtil.otherChargeOnBlur(event);
    }

    buttonEstimateFuelSurchargeOnClick(event: ClickEvent) {
        this.ratingButtonClicked(event, false, true);
    }

    buttonRateOnClick(event: ClickEvent) {
        this.ratingButtonClicked(event, true);
    }

    ratingButtonClicked(event: ClickEvent, autoRate: boolean, estimateFsc: boolean = this.estimateFuelSurcharge) {
        const button = event.target as Button;
        button.busy = true;
        button.enabled = false;
        this.calculateRates(autoRate, estimateFsc).finally(() => {
            button.busy = false
            button.enabled = true;
        });
    }

    chargeIdChanged(textbox: Textbox, selection) {
        this.otherChargesUtil.chargeIdChanged(textbox, selection);
    }

    tableOtherChargesOnContentsChanged(event) {
        this.mainDataSource.activeRow.set("otherchargetotal", this.otherChargesUtil.getOtherChargeTotal(false));
    }

    async calculateRates(autorate: boolean = this.autoRate, estimateFsc: boolean = this.estimateFuelSurcharge): Promise<any> {
        if (!this.mainDataSource.isAddingOrUpdating() || this.calculatingCharges || this.mainDataSource.activeRow == null || !this.checkPickupAndDeliveryEntered()) return Promise.resolve();
        this.mainDataSource.setComponentsBusy(true);
        this.calculatingCharges = true;
        const response = await Api.search("lme/dispatch/calculate-order-charges", {
            "order_type": "R",
            "autorate": autorate,
            "estimate_fsc": estimateFsc,
            "order_row": this.mainDataSource.getDataboundValues(null, true, null, null),
            "calculate_max_target": false
        });
        let totalChargeChanged = false;
        const data = response.data[0];
        const orderApiData = data["order_row"] || [];

        // Estimate Fuel Surcharge
        if (estimateFsc) {
            const appendingId: string[] = [];
            this.tableOtherCharges.rows.forEach(row => {
                if (row.data?._appending == false) appendingId.push(row.data?._data["id"])
            });
            for (let i = this.tableOtherCharges.rows.length - 1; i >= 0; i--) {
                const row = this.tableOtherCharges.rows[i];
                if (row?.data?.data["est_fuel_surcharge"] === "Y") {
                    row.deleteRow()
                }
            }
            this.sourceOrderOtherChargeRec.data = [];
            const otherCharges = data["other_charges"] || [];
            otherCharges.forEach(element => {
                const isAppending = appendingId.length == 0 || !appendingId.includes(element["id"]);
                const modelRow = new ModelRow(this.sourceOrderOtherChargeRec.url, isAppending);
                modelRow.setValues(element);
                this.sourceOrderOtherChargeRec.data.push(modelRow);
            });
            this.tableOtherCharges.displayData(null, this.sourceOrderOtherChargeRec.data, 0);
        }

        // Auto Rate
        const items = this.textboxRateType.items as DropdownItem[];
        this.textboxRateType.selectedItem = items.find((item) => item.value === orderApiData["rate_type"]);
        this.mainDataSource.activeRow.set("bill_distance", orderApiData["bill_distance"]);
        this.mainDataSource.activeRow.set("autorate_status", orderApiData["autorate_status"]);
        this.mainDataSource.activeRow.set("rate_id", orderApiData["rate_id"]);
        this.mainDataSource.activeRow.set("rate", orderApiData["rate"]);
        this.mainDataSource.activeRow.data["calc_method"] = data["rate_type"];
        this.mainDataSource.activeRow.set("rate_units", orderApiData["rate_units"]);
        this.mainDataSource.activeRow.set("freight_charge", orderApiData["freight_charge"]);
        this.mainDataSource.activeRow.set("otherchargetotal", this.otherChargesUtil.getOtherChargeTotal(false));
        if (orderApiData["total_charge"] != null) {
            if (orderApiData["total_charge"].amount != this.mainDataSource.activeRow.get("total_charge")?.amount || 0) {
                this.mainDataSource.activeRow.set("total_charge", orderApiData["total_charge"]);
                totalChargeChanged = true;
            }
        }
        if (orderApiData["totalcharge_and_excisetax"] != null) this.mainDataSource.activeRow.set("totalcharge_and_excisetax", orderApiData["order_total_charges_plus_excisetax"]);

        setAutoRateContext(this, this.mainDataSource.activeRow.get("rate_id"));
        if (totalChargeChanged && this.doAfterRating != null) this.doAfterRating();

        this.calculatingCharges = false;
        this.mainDataSource.setComponentsBusy(false);
    }

    getRateIdTooltipCallback(rateId: String): TooltipCallback {
        return (base, originatingEvent) => {
            const tooltip = this.makeRateIdTooltip(rateId);
            return this.labelAutoRated["_internalShowTooltip"](tooltip, originatingEvent);
        }
    }

    private makeRateIdTooltip(rateId) {
        const tooltipPanel: Panel = new Panel({ width: 150 });
        const rateIdLabel = new Textbox();
        rateIdLabel.printable = true;
        rateIdLabel.caption = "Rate ID";
        rateIdLabel.text = rateId;
        rateIdLabel.displayType = DisplayType.STRING;
        tooltipPanel.add(rateIdLabel);
        return tooltipPanel;
    }

    /** This is an event handler for the onChange event of checkboxLockMiles.  */
    checkboxLockMilesOnChange(event) {
        this.textboxBillDistance.enabled = event.newValue === false;
    }

}
