import { CommonDialogs } from "@mcleod/common";
import {
    Button, ClickEvent, DataSourceMode, Layout, Overlay, Panel, SaveButton, SlideoutDecorator, Step, StepEvent
} from "@mcleod/components";
import { Api, getLogger, Model, ModelRow, StringUtil } from "@mcleod/core";
import { CarrierAssignment } from "@mcleod/powerbroker/src/CarrierAssignment";
import { AutogenLayoutCarrierSelectionStepper } from "./autogen/AutogenLayoutCarrierSelectionStepper";
import { ModelOrders, RowOrders } from "./models/ModelOrders";
import { RowQuoteOrder } from "@mcleod/powerbroker/src/models/ModelQuoteOrder";
import { UnassignCarrierPrompt } from "./UnassignCarrierPrompt";


const log = getLogger("lme.dispatch.CarrierSelectionStepper");

export interface CarrierSelectionProps {
    orderId: string,
    orderRow?: RowOrders | RowQuoteOrder,
    onClose?: (cancelled: boolean, updatedData?: ModelRow) => void
}

export enum WorkflowStep {
    SELECTION = 0, ASSIGNMENT = 1
}

interface HeaderButton {
    id: string,
    caption: string,
    onClick: (event: ClickEvent) => void
}

const buttonProps = {
    backgroundColor: "primary.darker",
    color: "strokeSecondary",
    minWidth: 128,
    borderWidth: 1,
    rowBreak: false
};

export class CarrierSelectionStepper extends AutogenLayoutCarrierSelectionStepper {
    private onClose: (cancelled: boolean, updatedData?: ModelRow) => void;
    private doAfterCarrierAssignmentSearch: (layout: CarrierAssignment) => void;
    private activeTrackingVendorsFound = false;
    private manualTrackingRequired = true;
    private panelHeader: Panel;
    private _orderRow: RowOrders | RowQuoteOrder;

    // Header Buttons
    private unassignBtn: Button;
    private doneBtn: Button;
    private saveBtn: SaveButton;

    public get orderRow(): RowOrders | RowQuoteOrder {
        return this._orderRow;
    }

    public set orderRow(value: RowOrders | RowQuoteOrder) {
        this._orderRow = value;
    }

    override onLoad() {
        this.stepperCarrierSelection.addBeforeStepListener((event: StepEvent) => {
            this.doBeforeStep(event);
        });
    }

    static showSlideout(props: CarrierSelectionProps) {
        const stepperLayout = Layout.getLayout("lme/dispatch/CarrierSelectionStepper", {
            width: window.innerWidth,
            backgroundColor: "defaultBackground",
            fillHeight: true,
            paddingBottom: 50
        }) as CarrierSelectionStepper;
        stepperLayout.onClose = props.onClose;

        const decorator = new SlideoutDecorator({
            layout: stepperLayout,
            title: `Carrier Selection - Order Number ${props.orderId}`,
            fillVerticalSpace: true,
            overlayProps: { closeOnClickOff: false, greyedBackground: true },
            doAfterSlideIn: async (decorator: SlideoutDecorator) => {
                stepperLayout.setupHeaderButtons(decorator, stepperLayout);
                await stepperLayout.prepareLayouts(props);
            },
            onClose: (cancelled: boolean) => decorator.slideOut().then(() => {
                if (props.onClose)
                    props.onClose(cancelled);
            })
        });
    }

    async prepareLayouts(props: CarrierSelectionProps) {
        await this.prepareCarrierSelectionLayout(props);
        this.prepareCarrierAssignmentLayout();
    }

    async prepareCarrierSelectionLayout(props: CarrierSelectionProps) {
        this.orderRow = this.layoutSelection.orderRow = props?.orderRow ?? await new ModelOrders().searchSingle({ id: props.orderId }, "lme/dispatch/Orders");
        this.layoutSelection.doAfterChoosingCarrier = async () => {
            this.stepperCarrierSelection.selectedIndex = WorkflowStep.ASSIGNMENT;
            await this.setupCarrierAssignmentLayout(this.layoutSelection.chosenCarrier.get("rating_source"));
        }
        await this.layoutSelection.sourceBrltlBillingFreightGroup.search({ order_id: this.orderRow.get("id") });
    }

    async prepareCarrierAssignmentLayout() {
        this.layoutAssigned.panelHeader.visible = false;
        this.layoutAssigned.panelEquipment.visible = false;
        this.layoutAssigned.layoutMovementPay.panelRates.forEveryChildComponent(comp => {
            comp.enabled = false
        });
        this.layoutAssigned.layoutMovementPay.layoutCarrierRates.forEveryChildComponent(comp => comp.enabled = false);
        this.layoutAssigned.layoutMovementPay.tabOtherPay.forEveryChildComponent(comp => comp.enabled = false);
        this.layoutAssigned.panelReference.visible = true;
        this.layoutAssigned.panelContact.forEveryChildComponent(comp => comp.enabled = false);
    }

    setupHeaderButtons(decorator: SlideoutDecorator, stepperLayout: CarrierSelectionStepper) {
        // temporarily remove close button as it automatically gets added again w addHeaderActions
        const headerPanel = decorator.findComponentById("sodPanelHeader") as Panel;
        headerPanel.remove(decorator.findComponentById("sodCloseButton"));
        stepperLayout.generateHeaderButtons(decorator);
    }

    generateHeaderButtons(decorator: SlideoutDecorator) {
        this.unassignBtn = this.createHeaderButton({
            id: "buttonUnassign",
            caption: "Unassign",
            onClick: (event: ClickEvent) => this.unassignButtonOnClick(event)
        });
        this.doneBtn = new Button({
            id: "buttonDone",
            caption: "Save & Close",
            onClick: (event: ClickEvent) => this.doneButtonOnClick(event),
            color: "primary.reverse",
            backgroundColor: "primary",
            minWidth: 128,
            borderWidth: 0,
            padding: 10, // since border width is 0 we add padding to adjust the button size to match the other buttons
            rowBreak: false
        });
        this.saveBtn = new SaveButton({
            id: "buttonSave",
            caption: "Save",
            dataSource: this.layoutSelection.sourceBrltlBillingFreightGroup,
            onClick: (event: ClickEvent) => {
                this.layoutSelection.sourceBrltlBillingFreightGroup.data.forEach(row => row.post());
                this.layoutSelection.getRates();
            },
            minWidth: 128,
            rowBreak: false
        });
        decorator.addHeaderActions([this.unassignBtn, this.doneBtn, this.saveBtn]);
        this.determineHeaderBtns(this.stepperCarrierSelection.selectedIndex);
    }

    determineHeaderBtns(index: number) {
        this.unassignBtn.visible = index === WorkflowStep.ASSIGNMENT && !["D", "P"].includes(this.orderRow.get("status"));
        this.doneBtn.visible = index === WorkflowStep.ASSIGNMENT;
        this.saveBtn.visible = index === WorkflowStep.SELECTION;
    }

    private async doBeforeStep(event: StepEvent) {
        switch (event.newIndex) {
            case WorkflowStep.SELECTION:
                if (this.layoutSelection.chosenCarrier) {
                    this.layoutSelection.buttonChangeMarkups.visible = false;
                }
                break;
            case WorkflowStep.ASSIGNMENT:
                if (this.layoutSelection.chosenCarrier == null) {
                    event.preventDefault();
                    return;
                }
                break;
        }
        this.determineHeaderBtns(event.newIndex);
    }

    doBeforeStepLayoutDisplay(index: number) {
        if (this.parent?.["dismissAllSnackbars"]) this.parent["dismissAllSnackbars"]();
        const layout = this.getStepLayout(index);
        if (layout != null) {
            layout.setAllDataSourcesToMode(DataSourceMode.UPDATE);
            if (this.panelHeader != null) {
                this.panelHeader.setProps({ padding: 0, marginBottom: 12 });
                this.panelHeader.removeAll();
                layout["panelHeader"].visible = true;
                this.panelHeader.add(layout["panelHeader"]);
            }
        }
    }

    private getStepLayout(index: number) {
        return this.getStep(index).components[0] as Layout;
    }

    public setupCarrierAssignmentLayout(ratingSource?: string): Promise<CarrierAssignment> {
        return new Promise((resolve, reject) => {
            const layout = this.layoutAssigned as CarrierAssignment;
            layout.addLayoutLoadListener(() => {
                layout.sourceCarriersForAssignment.addDisplayListener((event) => {
                    if (event.rowData == null && layout.mainDataSource.activeRow != null) {
                        this.activeTrackingVendorsFound = layout.mainDataSource?.activeRow?.get("active_tracking_vendors_found", false);
                        if (this.activeTrackingVendorsFound) {
                            const manualTrackingRequiredOnMovement = layout.mainDataSource.activeRow?.getBoolean("manual_tracking_required", false);
                            this.setManualTrackingRequired(manualTrackingRequiredOnMovement, layout);
                        } else {
                            this.setManualTrackingRequired(false, layout);
                        }
                    } else if (!this.manualTrackingRequired && this.activeTrackingVendorsFound) {
                        const trackingDisabledOnPayee: boolean = layout.sourceCarriersForAssignment?.activeRow?.get("disable_automatic_tracking", "N") === "Y";
                        this.setManualTrackingRequired(trackingDisabledOnPayee, layout);
                    }
                });
                layout.mainDataSource.search({
                    "id": this.orderRow.get("curr_movement_id"),
                    "orders.id": this.orderRow.get("id")
                }).then(result => {
                    if (layout.mainDataSource?.activeRow == null) {
                        reject("Unable to find movement");
                    } else {
                        layout.setAllDataSourcesToMode(DataSourceMode.UPDATE);
                        const lockErrorMessage = layout.mainDataSource.activeRow.get("locked_error_message");
                        if (lockErrorMessage != null) {
                            reject(lockErrorMessage);
                        } else {
                            this.activeTrackingVendorsFound = layout.mainDataSource.activeRow?.get("active_tracking_vendors_found", false);
                            this.setManualTrackingRequired(layout.mainDataSource?.activeRow?.get("manual_tracking_required", false) == true, layout);
                            layout.doAfterClose = (canceled: boolean) => this.close(canceled);
                            if (this.doAfterCarrierAssignmentSearch != null) {
                                this.doAfterCarrierAssignmentSearch(layout);
                            }
                            const carrierId = layout.mainDataSource.activeRow.get("override_payee_id");
                            if (carrierId != null) {
                                layout.textboxPendingPayeeId.field = "override_payee_id";
                                layout.activeRow.setLookupModelData("override_payee_id", new ModelRow(layout.textboxPendingPayeeId.lookupModel, false, { id: carrierId, name: layout.activeRow.get("payee.name") }));
                                layout.textboxPendingPayeeId.displayData(layout.activeRow, null, 0);
                                layout.textboxPendingPayeeId.enabled = false;
                                layout.sourceCarriersForAssignment.search({ "id": carrierId });
                            }
                            layout.sourceReferenceNumber.search({ "stop_id": layout.mainDataSource.activeRow.get("origin_stop_id") });
                            layout.layoutMovementPay.calculatePay(false, undefined, undefined, undefined, false);
                            if (!StringUtil.equalsIgnoreCase("project44", ratingSource)) layout.textboxReferenceNumber.enabled = false;
                            resolve(layout);
                        }
                    }
                });
            });
        });
    }

    private setManualTrackingRequired(value: boolean, layout: CarrierAssignment) {
        if (this.manualTrackingRequired != value) {
            this.manualTrackingRequired = value;
        }
    }

    close(cancelled: boolean, updatedData?: ModelRow) {
        this.parent.parent.slideOut().then(() => {
            if (this.onClose) this.onClose(cancelled);
        });
    }

    private getStep(index: number): Step {
        return this.stepperCarrierSelection.components[index] as Step
    }

    createHeaderButton(props: HeaderButton) {
        return new Button({
            id: props.id, caption: props.caption, ...buttonProps, onClick: props.onClick
        });
    }

    doneButtonOnClick(event: ClickEvent) {
        const doneButton = event.target as Button;
        doneButton.busy = true;
        this.layoutAssigned.mainDataSource.execute().then(response => {
            if (this.layoutAssigned.sourceReferenceNumber.activeRow != null) this.layoutAssigned.sourceReferenceNumber.execute();
            this.close(false)
        }).catch(error => log.error(error)).finally(() => doneButton.busy = false)
    }

    async unassignButtonOnClick(event: ClickEvent) {
        const button = event.target as Button;
        button.busy = true;
        if (!this.orderHasValidStatusForCancelDispatch(this.orderRow.get("status"))) return;
        let cancelSupported = true;
        const p44Record = await Model.searchSingleRecord("lme/powerbroker/contract-management-control", { id: "P44" });
        if (p44Record?.getBoolean("is_active", false) && this.layoutSelection.chosenCarrier.get("dispatch_type", null) === "P44") {
            const result = await Api.post("lme/powerbroker/get-p44-carrier-cancel-support", { bfg_uid: this.layoutSelection.chosenCarrier.get("bfg_uid") });
            cancelSupported = result?.data[0]["carrier_cancel_support"];
        }
        if (!cancelSupported) {
            await CommonDialogs.showYesNo(`This ${p44Record?.getBoolean("is_active", false) ? "Project44" : "SMC3"} carrier does not support dispatch cancellation, you will have to contact the carrier. Continue?`, "Unassign Carrier").then(response => {
                cancelSupported = response
            });
        }
        if (cancelSupported) {
            await this.removeCarrier(this.layoutSelection.mainDataSource.activeRow.get("movement_id"), this.layoutSelection.chosenCarrier.get("carrier_name"), async () => {
                this.layoutSelection.chosenCarrier = null;
                await this.layoutSelection.mainDataSource.search({ order_id: this.orderRow.get("id") });
                this.stepperCarrierSelection.selectedIndex = WorkflowStep.SELECTION;
            });
        }
        button.busy = false;
    }

    async removeCarrier(movementId: string, carrierName: string, doAfterSave?: () => void) {
        const unassignCarrierPromptLayout = Layout.getLayout("lme/dispatch/UnassignCarrierPrompt", {
            backgroundColor: "defaultBackground",
            borderRadius: 4, borderWidth: 1, borderShadow: true, borderColor: "strokeSecondary",
        }) as UnassignCarrierPrompt;
        unassignCarrierPromptLayout.movementId = movementId;
        unassignCarrierPromptLayout.addLayoutDataLoadListener(event => {
            unassignCarrierPromptLayout.onCancel = () => Overlay.hideOverlay(overlay);
            unassignCarrierPromptLayout.onSave = async (success: boolean, vendorName: string, forceCancel: boolean) => {
                unassignCarrierPromptLayout.toastMessage = `${carrierName} was removed from movement ${movementId}.`;
                new Promise(resolve => setTimeout(resolve, 2500)).then(async () => {
                    doAfterSave?.();
                });
            };
            unassignCarrierPromptLayout.buttonYes.addClickListener((event) => {
                if (unassignCarrierPromptLayout.validateSimple()) {
                    Overlay.hideOverlay(overlay);
                    unassignCarrierPromptLayout.unassignCarrier();
                }
            });
            const overlay = Overlay.showInOverlay(unassignCarrierPromptLayout, {
                closeOnClickOff: false,
                greyedBackground: true,
                centered: true
            });
        });
    }

    orderHasValidStatusForCancelDispatch(movementStatus: string): boolean {
        switch (movementStatus) {
            case "P":
                CommonDialogs.showYesNo("This order is already in progress do you want to cancel the dispatch?", "Unassign Carrier").then(response => {
                    return !response;
                });
                break;
            case "D":
                CommonDialogs.showYesNo("This order is already delivered do you want to cancel the dispatch?", "Unassign Carrier").then(response => {
                    return !response;
                });
                break;
        }
        return true;
    }
}
