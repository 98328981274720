import { Checkbox, DataSource, Label, Layout, Panel, Tab, Table, Tabset, Textbox } from "@mcleod/components";

export abstract class AutogenLayoutEdiOrderReply extends Layout {
    checkboxReplyTransmitted: Checkbox;
    labelInboundReplyStatus: Label;
    panelReplyErrors: Panel;
    panelReplyMain: Panel;
    sourceEdiDeclineCodes: DataSource;
    sourceEdiMapError: DataSource;
    sourceViewEdiOrder: DataSource;
    tabErrors: Tab;
    tableErrors: Table;
    tabsetReplyErrors: Tabset;
    textboxReplyAction: Textbox;
    textboxReplyBatchId: Textbox;
    textboxReplyDate: Textbox;
    textboxReplyError: Textbox;
    textboxReplyReason: Textbox;
    textboxReplyRemarks: Textbox;
}
