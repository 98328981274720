import { CommonDialogs } from "@mcleod/common";
import { ChangeEvent, ClickEvent, DataSource, DataSourceAction, DataSourceExecutionEvent, DropdownItem, Panel, Toast, ValidationResult } from "@mcleod/components";
import { Api, ModelRow, StringUtil } from "@mcleod/core";
import { MacroPointDataUtils } from "./MacroPointDataUtils";
import { P44Util } from "./P44Util";
import { AutogenLayoutInitiateBrokerageTracking } from "./autogen/AutogenLayoutInitiateBrokerageTracking";
import { ModelBrokerageTracking } from "./models/ModelBrokerageTracking";

const MACROPOINT_LITE_VENDOR = "M";
const PROJECT44_VENDOR = "P";
const TYPE_TRACTOR = "VEHICLE_ID";

interface Vendor {
    vendorPanel: Panel;
    controlRow?: ModelRow;
}

export class InitiateBrokerageTracking extends AutogenLayoutInitiateBrokerageTracking {
    private _displayedVendorPanel: Panel;
    private vendorMap = new Map<string, Vendor>();
    private vendorPanel: Panel;
    private p44Util = new P44Util(this);
    private macroPointDataUtils = new MacroPointDataUtils();
    private _editTracking: boolean = false;

    public doAfterTrackingInitiated: () => void;

    override onLoad() {
        this.vendorMap.set(ModelBrokerageTracking.LOAD_TRACK_VENDOR, { vendorPanel: this.panelVendorL });
        this.vendorMap.set(ModelBrokerageTracking.MACROPOINT_LITE_VENDOR, { vendorPanel: this.panelVendorM });
        this.vendorMap.set(ModelBrokerageTracking.FOURKITES_VENDOR, { vendorPanel: this.panelVendorF });
        this.vendorMap.set(ModelBrokerageTracking.PROJECT44_VENDOR, { vendorPanel: this.panelVendorP });
        this.vendorMap.set(ModelBrokerageTracking.TRANSFLOVELOCITY_VENDOR, { vendorPanel: this.panelVendorT });

        this.textboxEquipmentIdentifier.addBeforeLookupModelSearchListener((event) => {
            const overridePayeeId = this.mainDataSource.activeRow?.get("override_payee_id");
            const brVendor = this.mainDataSource.activeRow?.get("br_vendor");
            this.setCarrierVehicleLookupFilter(overridePayeeId, brVendor, event.filter);
        });

        this.textboxCarrierTractor.addBeforeLookupModelSearchListener((event) => {
            const overridePayeeId = this.mainDataSource.activeRow?.get("override_payee_id");
            const brVendor = this.mainDataSource.activeRow?.get("br_vendor");
            this.setCarrierVehicleLookupFilter(overridePayeeId, brVendor, event.filter);
        });
    }

    private setCarrierVehicleLookupFilter(overridePayeeId: string, brVendor: string, filter: any) {
        if (overridePayeeId) {
            filter.override_payee_id = overridePayeeId;
        }

        if (brVendor) {
            filter.vendor = brVendor;
        }
    }

    /** This is an event handler for the onChange event of textboxRequiredVendor.  */
    textboxRequiredVendorOnChange(event: ChangeEvent) {
        if (this.textboxVendor.selectedItem == null || event.userInitiatedChange) {
            const vendorId = this.textboxVendor.selectedItem?.value;
            if (vendorId != null) {
                this.displayVendorPanel(this.textboxVendor.selectedItem?.value);
                this.panelCarrierDriverInfo.visible = true;
            }
        }
    }

    public get editTracking(): boolean {
        return this._editTracking;
    }

    public set editTracking(value: boolean) {
        this._editTracking = value;
    }

    get displayedVendorPanel(): Panel {
        return this._displayedVendorPanel;
    }

    private set displayedVendorPanel(panel: Panel) {
        if (this.displayedVendorPanel != null) {
            this.setPanelDataSource(this.displayedVendorPanel, null);
            this.displayedVendorPanel.visible = false;
        }

        this._displayedVendorPanel = panel;
        this.setPanelDataSource(this.displayedVendorPanel, this.mainDataSource);

        if (this.displayedVendorPanel != null) {
            this.displayedVendorPanel.visible = true;
        }
    }

    async displayVendorPanel(vendorCode: string) {
        const vendor = this.vendorMap.get(vendorCode);
        this.vendorPanel = vendor?.vendorPanel;
        const vendorCtrlRow = vendor?.controlRow;
        const movementId: string = this.mainDataSource.activeRow.get("id");
        const disabledTooltip = "This value cannot be changed at this time, tracking must be canceled and restarted with the new value.";

        this.labelBusy.busy = true;
        this.labelBusy.caption = "Loading...";
        this.displayedVendorPanel = this.panelBusy;

        if (vendorCode === PROJECT44_VENDOR) {
            this.setCarrierTractorLookupData();

            this.labelBusy.caption = "Retrieving Tracking Methods..."
            await this.p44Util.getTrackingMethods(this.mainDataSource.activeRow.get("id"))
                .then(response => {
                    const hasCapacity: boolean = response.data[0].has_capacity ? response.data[0].has_capacity : false;

                    if (response.data[0].success) {

                        if (response?.data[0]?.type === TYPE_TRACTOR)
                            this.labelBusy.caption = "Retrieving Vehicle IDs..."

                        this.p44Util.setP44RequiredLabel(response.data[0].type);
                    } else {
                        this.labelTrackingNote.color = "error.light";
                        this.labelTrackingNote.caption = "Error retrieving carrier tracking method";
                    }

                    this.textboxCapacityProviderID.visible = hasCapacity;
                    this.textboxCapacityProviderID.required = hasCapacity;
                });

        } else if (vendorCode === MACROPOINT_LITE_VENDOR) {
            this.setEquipmentIdentfierLookUpData();
            await this.macroPointDataUtils.isTrackingStarted(movementId, this);
            if (!this.textboxTrackStartDate.enabled) {
                this.textboxTrackStartDate.disabledTooltip = disabledTooltip;
            }
            if (!this.textboxTrackDurationExt.enabled) {
                this.textboxTrackDurationExt.disabledTooltip = disabledTooltip;
            }
            if (!this.textboxTrackIntervalMins.enabled) {
                this.textboxTrackIntervalMins.disabledTooltip = disabledTooltip;
            }

            if (this.mainDataSource.activeRow.get("override_payee_id")) {
                this.labelBusy.caption = "Updating Equipment Identifiers...";
                this.macroPointDataUtils.updateEquipmentIdentifiers(movementId);
                this.labelBusy.caption = "Retrieving Tracking Method...";
                await this.macroPointDataUtils.getTrackingMethod(movementId, this);
            }
            this.labelBusy.caption = "Retrieving Track Duration Extension...";
            await this.macroPointDataUtils.getDurationExt(movementId, this);
            await this.macroPointDataUtils.getNotificationEmails(movementId, this);
        }

        this.displayedVendorPanel = this.vendorPanel;
        this.mainDataSource.activeRow.set("track_interval_mins", vendorCtrlRow?.get("track_interval_mins"));
    }

    /** This is an event handler for the afterExecution event of sourceBrokerageTracking.  */
    sourceBrokerageTrackingAfterExecution(event: DataSourceExecutionEvent) {
        if (DataSourceAction.SEARCH == event.getAction()) {
            this.textboxShpmtIdentValue.visible = "OE" === this.mainDataSource.activeRow?.get("p44_shipment_ident_type");
            this.textboxShpmtIdentValue.required = this.textboxShpmtIdentValue.visible === true;
            this.editTracking = this.mainDataSource.activeRow?.get("br_vendor") != null;
            this.setVendorModelRows();
            if (this.editTracking) {
                this.configureForEditTracking();
            } else {
                this.configureForInitiateTracking();
                this.mainDataSource.activeRow?.set("br_vendor", this.mainDataSource.activeRow?.get("required_vendor"));
            }
            this.displayVendorPanel(this.mainDataSource.activeRow?.get("br_vendor", null));
        }
    }

    private configureForEditTracking() {
        this.labelHeaderTitle.caption = "Edit Tracking";
        this.textboxVendor.enabled = false;
        this.textboxVendor.backgroundColor = "#EDEDED";
        this.buttonSave.caption = "Update Changes";
        this.buttonSave.imageName = "pencil";
        this.buttonSave.width = 170;
    }

    private configureForInitiateTracking() {
        this.labelHeaderTitle.caption = "Initiate Tracking";
        this.textboxVendor.enabled = true;
        this.buttonSave.caption = "Initiate Tracking";
        this.textboxVendor.backgroundColor = "#FFFFFF";
        this.buttonSave.width = 170;

    }

    private setVendorModelRows() {
        const vendorItems: DropdownItem[] = [];
        for (const [key, value] of this.vendorMap.entries()) {
            value.controlRow = null;
            this.setPanelDataSource(value.vendorPanel, null);
            const data = this.mainDataSource.activeRow?.get(`control_row_${key}`);
            if (data != null) {
                value.controlRow = new ModelRow("lme/powerbroker/br-tracking-ctrl", false, data);
                vendorItems.push({ value: value.controlRow.get("vendor"), caption: value.controlRow.get("vendor_display_value") });
            }
        }
        this.textboxVendor.items = vendorItems;
    }

    private setPanelDataSource(panel: Panel, ds: DataSource) {
        if (panel != null)
            panel.components.forEach(comp => comp.dataSource = !comp.visible ? null : ds);
    }

    /** This is an event handler for the onClick event of buttonSave.  */
    buttonSaveOnClick(event: ClickEvent) {
        this.buttonSave.enabled = false;
        this.buttonSave.busy = true;
        if (this.vendorPanel.validateSimple()) {
            this.mainDataSource.activeRow.set("edit_tracking", this.editTracking || true);
            Api.post("lme/powerbroker/tracking/initiate-tracking",
                {
                    data: this.mainDataSource.getDataboundValues(null, true, null, null),
                    movement_id: this.mainDataSource.activeRow.get("id"),
                    edit_tracking: this.editTracking,
                    p44_tracking_response: this?.p44Util?.trackingResponse
                })
                .then((result) => {
                    if (result?.data[0]?.br_vendor) {
                        const requestStatus = result?.data[0]?.request_status;
                        if (requestStatus === "SUCCESS") {
                            if (result?.data[0]?.response_message) {
                                Toast.showSuccessToast(result?.data[0]?.response_message);
                                this.executeDoAfterTrackingInitiated();
                            }
                        } else {
                            if (result?.data[0]?.response_message)
                                CommonDialogs.showError(result?.data[0]?.response_message, "Initiate Tracking Error - " + this.mainDataSource.activeRow.get("id"))
                                    .then(() => this.executeDoAfterTrackingInitiated());
                        }
                    }
                    this.buttonSave.enabled = true;
                    this.buttonSave.busy = false;
                });
        }
    }

    executeDoAfterTrackingInitiated() {
        if (this.doAfterTrackingInitiated != null)
            this.doAfterTrackingInitiated();
    }

    public getP44UtilInstance(): P44Util {
        return this.p44Util;
    }

    textboxCarrierTractorOnChange(event: ChangeEvent) {
        const carrierTractorLength = 12;
        const vendorId = this.textboxVendor.selectedItem?.value;
        if (event.userInitiatedChange && vendorId != null && vendorId === PROJECT44_VENDOR) {
            this.mainDataSource?.activeRow?.set("carrier_tractor", event.newValue.length > carrierTractorLength ? event.newValue.substring(0, carrierTractorLength).trim() : event.newValue);
            this.mainDataSource?.activeRow?.set("br_track_vehicle_id", event.newValue);
        }
        this.setCarrierTractorLookupData();
    }

    setCarrierTractorLookupData() {
        const tractorField = "carrier_tractor";
        const carrierTractor: String = <String>this.mainDataSource?.activeRow?.get(tractorField);
        if (!StringUtil.isEmptyString(carrierTractor)) {
            const lmData = new ModelRow(this.textboxCarrierTractor.lookupModel, false, { vehicle_id: carrierTractor });
            this.mainDataSource?.activeRow?.setLookupModelData(tractorField, lmData, this.textboxCarrierTractor);
            this.mainDataSource.activeRow.set(tractorField, lmData.get("vehicle_id"));
            this.textboxCarrierTractor.displayData(this.mainDataSource.activeRow, null, 0);
        }
    }

    setEquipmentIdentfierLookUpData() {
        const tractorField = "equipment_identifiers";
        const carrierTractor: String = <String>this.mainDataSource?.activeRow?.get(tractorField);
        if (!StringUtil.isEmptyString(carrierTractor)) {
            const lmData = new ModelRow(this.textboxEquipmentIdentifier.lookupModel, false, { vehicle_id: carrierTractor });
            this.mainDataSource?.activeRow?.setLookupModelData(tractorField, lmData, this.textboxEquipmentIdentifier);
            this.mainDataSource.activeRow.set(tractorField, lmData.get("vehicle_id"));
            this.textboxEquipmentIdentifier.displayData(this.mainDataSource.activeRow, null, 0);
        }
    }

    override validate(checkRequired: boolean, showErrors: boolean = true): ValidationResult[] {
        return this.vendorPanel.validate(checkRequired, showErrors);
    }
}


