import { BlurEvent, Button, ClickEvent, DataSourceExecutionEvent, DataSourceMode, DropdownItem, SaveButton, Snackbar, TableRow, Textbox } from "@mcleod/components";
import { TableRowMode } from "@mcleod/components/src/components/table/TableRow";
import { Api, Currency, CurrencyUtil, ModelRow } from "@mcleod/core";
import { setAutoRateContext, setRateListeners } from "@mcleod/dispatch/src/RateUtil";
import { getDispatchControlBoolean } from "../../general/src/models/ModelDispatchControl";
import { AutogenLayoutEdiOrderRates } from "./autogen/AutogenLayoutEdiOrderRates";

export class EdiOrderRates extends AutogenLayoutEdiOrderRates {
    private _allocationEnabled: boolean;
    private _calculatingCharges = false;
    private chargePanelExpanded: boolean = true;
    private estimateFuelSurcharge: boolean = getDispatchControlBoolean("est_fuel_surcharge")
    private autoRate: boolean = getDispatchControlBoolean("auto_rate");
    private autoCalcMaxPay: boolean = getDispatchControlBoolean("auto_pay_rates");
    private saveButton: SaveButton;
    doAfterRating: () => void;
    checkPickupAndDeliveryEntered = () => true;

    override onLoad(): void {
        this.textboxCollectionMethod.items = [{ caption: "Prepaid", value: "P" }, {
            caption: "Third party", value: "T"
        }, { caption: "Collect", value: "C" }];
        this.textboxCtrlParty.textCombined.manualAddLayout = null;
        this.buttonEstimateFuelSurcharge.visible = !this.estimateFuelSurcharge;
    }

    sourceOrdersAfterExecution(event: DataSourceExecutionEvent) {
        setAutoRateContext(this, this.activeRow?.get("rate_id"));
        this.buttonRate.enabled = (this.activeRow?.get("order_id") == null);
    }

    setMaxTargetPayData() {
        // const maxPayData = this.mainDataSource.activeRow.get("max_target_pay_data");
        // if (maxPayData != null && this.carrierPayLayout?.visible == true) {
        //   this.setupMaxTargetPanel(true);
        //   this.carrierPayLayout.setDataFromResponse(maxPayData);
        //   this.mainDataSource.activeRow.set("max_target_pay_data", undefined);
        // }
    }

    // get carrierPayLayout(): MovementCarrierPay {
    // return this.layoutMovementRates as MovementCarrierPay
    // }

    set calculatingCharges(value: boolean) {
        this._calculatingCharges = value;
    }

    get calculatingCharges(): boolean {
        return this._calculatingCharges;
    }

    textboxSegAllocCodeBeforeLookupModelSearch(event) {
        event.filter.alloc_type = 'M';
        event.filter.is_active = 'Y';
    }

    buttonExpandChargesOnClick(event: ClickEvent) {
        const transitionProps = { speed: 100, paddingTop: 0 };
        if (this.chargePanelExpanded) this.panelOtherChargeTable.collapse(transitionProps).then(() => {
            this.buttonExpandCharges.imageRotation = 90
        }); else this.panelOtherChargeTable.expand(transitionProps).then(() => {
            this.buttonExpandCharges.imageRotation = 0
        });
        this.chargePanelExpanded = !this.chargePanelExpanded;
    }

    sourceOrdersAfterModeChange(event) {
        if ((event.newMode == DataSourceMode.ADD || event.newMode == DataSourceMode.UPDATE) && event.newMod != event.oldMode) {
            this.setupMaxTargetPanel(true);
            this.sourceOtherChargeEdi.mode = DataSourceMode.ADD;
        }
        setRateListeners(this);
    }

    private setupMaxTargetPanel(visible: boolean) {
        // this.carrierPayLayout.visible = visible;
        this.panelRateTotals.borderBottomRightRadius = visible ? 0 : 4;
        this.panelRateTotals.borderBottomLeftRadius = visible ? 0 : 4;
        // if (this.carrierPayLayout.mainDataSource.activeRow == null) this.carrierPayLayout.configureForOrderEntry(this.mainDataSource);
    }

    /** This is an event handler for the onRowDisplay event of tableOtherCharges.  */
    tableOtherChargesOnRowDisplay(event) {
        const chargeId: Textbox = event.getTableRow().findComponentById("textboxChargeId") as Textbox;
        const textboxDescr: Textbox = event.getTableRow().findComponentById("textboxDescr") as Textbox;
        if (chargeId != null && chargeId.onSelectItem == null) {
            chargeId.onSelectItem = ((textbox, selectedItem) => {
                textboxDescr.text = (selectedItem as ModelRow).get("descr");
                this.chargeIdChanged(textbox, selectedItem);
                return undefined;
            });
            if (chargeId.text && "I" === this.sourceViewEdiOrder?.activeRow?.get("direction")) {
                chargeId.enabled = false;
            }
        }
        if ("I" === this.sourceViewEdiOrder?.activeRow?.get("direction")) {
            event.getTableRow().canBeDeleted = false;
        }
    }

    textboxChargeRateOnBlur(event: BlurEvent) {
        if (event.changedWhileFocused) {
            const textbox: Textbox = event.target as Textbox;
            const orderInfo = {
                "order_distance": this.mainDataSource.activeRow.get("bill_distance", null),
                "order_weight": this.mainDataSource.activeRow.get("weight", null)
            };
            if (this.mainDataSource.activeRow.data["freight_charge"] != null) this.addCurrencyToProps(orderInfo, this.mainDataSource.activeRow, "freight_charge", "order_freight");
            if (this.getOtherChargeTotal(false) != null) this.addCurrencyValueToProps(orderInfo, "previous_other_charge_total", this.getOtherChargeTotal(false));

            Api.search("lme/datafusion/calculate-other-charge-edi", {
                "other_charge_row": TableRow.getContainingTableRow(textbox).data, "order_info": orderInfo
            }).then(response => {
                this.otherChargeFromApi(response, textbox);
            });
        }
    }

    buttonEstimateFuelSurchargeOnClick(event: ClickEvent) {
        this.ratingButtonClicked(event, false, true);
    }

    buttonRateOnClick(event: ClickEvent) {
        this.ratingButtonClicked(event, true);
    }

    ratingButtonClicked(event: ClickEvent, autoRate: boolean, estimateFsc: boolean = this.estimateFuelSurcharge) {
        const button = event.target as Button;
        button.busy = true;
        button.enabled = false;
        this.calculateRates(autoRate, estimateFsc).finally(() => {
            const orderId = this.mainDataSource.activeRow?.get("order_id");
            button.busy = false
            button.enabled = (orderId == null);
        });
    }

    buttonAvailableCreditOnClick(event: ClickEvent) {
        this.buttonAvailableCredit.busy = true;
        const row = this.mainDataSource.activeRow;
        const params = { customer_id: this.mainDataSource.activeRow.get("customer_id") };
        if (row.get("total_charge") != null) this.addCurrencyToProps(params, row, "total_charge", "order_total_charges");
        if (row.get("totalcharge_and_excisetax") != null) this.addCurrencyToProps(params, row, "totalcharge_and_excisetax", "order_total_charges_plus_excisetax");
        if (this.mainDataSource.mode != DataSourceMode.ADD && row.get("order_id") != null) params["exclude_order_id"] = row.get("order_id");

        Api.search("lme/ar/customer-balance", params).then(result => {
            const customerData = result?.data?.[0];
            if (customerData["available_credit"] == null) this.labelAvailableCredit.caption = ""; else this.labelAvailableCredit.caption = CurrencyUtil.formatCurrency(customerData["available_credit"]);
        }).finally(() => this.buttonAvailableCredit.busy = false);
    }

    chargeIdChanged(textbox: Textbox, selection) {
        const orderInfo = {
            "order_distance": this.mainDataSource.activeRow.get("bill_distance", null),
            "order_weight": this.mainDataSource.activeRow.get("weight", null)
        };
        if (this.mainDataSource.activeRow.data["freight_charge"] != null) this.addCurrencyToProps(orderInfo, this.mainDataSource.activeRow, "freight_charge", "order_freight");
        if (this.getOtherChargeTotal(false) != null) this.addCurrencyValueToProps(orderInfo, "previous_other_charge_total", this.getOtherChargeTotal(false));

        Api.search("lme/datafusion/calculate-other-charge-edi", {
            "charge_code": (selection as ModelRow).data["id"],
            "charge_id": (selection as ModelRow).data["id"],
            "other_charge_row": TableRow.getContainingTableRow(textbox).data,
            "order_info": orderInfo
        }).then(response => {
            this.otherChargeFromApi(response, textbox);
        });
    }

    private addCurrencyToProps(props, row: ModelRow, fieldName: string, propName: string) {
        const currencyValue = row.get(fieldName);
        this.addCurrencyValueToProps(props, propName, currencyValue);
    }

    private addCurrencyValueToProps(props: any, propName: string, currencyValue: any) {
        props[propName] = currencyValue;
    }

    getOtherChargeTotal(includeFreightCharge: boolean) {
        let totalAmount: number = 0;
        const freightCharge: number = this.activeRow.get("freight_charge")?.amount ?? 0;
        this.sourceOtherChargeEdi.data.forEach(row => {
            if (row.data["amount"] != null) {
                totalAmount += row.data["amount"]["amount"];
            }
        });
        return {
            amount: includeFreightCharge ? freightCharge + totalAmount : totalAmount, currency_code: CurrencyUtil.getCurrencySettings().effective_currency_code
        } as Currency;
    }

    private otherChargeFromApi(response, textbox: Textbox) {
        const data = response.data[0];
        const otherCharge = data["other_charge_row"];
        if (Object.keys(otherCharge).length > 0) {
            const row = TableRow.getContainingTableRow(textbox);
            row.data["calc_method"] = otherCharge["calc_method"];
            const calcMethod: Textbox = row.findComponentById("textboxChargeCalcMethod") as Textbox;
            const items = calcMethod.items;
            for (let i = 0; i < items.length; ++i) {
                if (items[i].value == otherCharge["calc_method"]) {
                    calcMethod.selectedItem = items[i];
                    break;
                }
            }
            const units: Textbox = row.findComponentById("textboxChargeUnits") as Textbox;
            units.text = otherCharge["units"]?.toString();
            const rate: Textbox = row.findComponentById("textboxChargeRate") as Textbox;
            rate.text = otherCharge["rate"]?.toString();
            TableRow.getContainingTableRow(textbox).data.set("amount", otherCharge["amount"]);

            const chargeRow: TableRow = TableRow.getContainingTableRow(textbox);
            chargeRow.data.set("amount", otherCharge["amount"]);
            const amount: Textbox = row.findComponentById("textboxChargeAmount") as Textbox;
            if (row.mode === TableRowMode.QUICK_ADD) amount.text = CurrencyUtil.formatCurrency(otherCharge["amount"])?.toString();
        }
    }

    tableOtherChargesOnContentsChanged(event) {
        this.mainDataSource.activeRow.set("otherchargetotal", this.getOtherChargeTotal(false));
        this.mainDataSource.activeRow.set("total_charge", this.getOtherChargeTotal(true));
    }

    async calculateRates(autorate: boolean = this.autoRate, estimateFsc: boolean = this.estimateFuelSurcharge): Promise<any> {
        if (!this.mainDataSource.isAddingOrUpdating() || this.calculatingCharges || this.mainDataSource.activeRow == null) return Promise.resolve();
        this.mainDataSource.setComponentsBusy(true);
        this.calculatingCharges = true;
        const calcMaxTargetPay = this.shouldCalcMaxPay();
        try {
            const response = await Api.search("lme/dispatch/calculate-order-charges", {
                "autorate": autorate,
                "estimate_fsc": estimateFsc,
                "order_row": this.mainDataSource.getDataboundValues(null, true, null, null),
                "calculate_max_target": calcMaxTargetPay
            });
            let totalChargeChanged = false;
            const data = response.data[0];
            const orderApiData = data["order_row"] || [];

            // Estimate Fuel Surcharge
            const appendingId: string[] = [];
            this.tableOtherCharges.rows.forEach(row => {
                if (row.data?._appending == false) appendingId.push(row.data?._data["id"])
            });
            for (let i = this.tableOtherCharges.rows.length - 1; i >= 0; i--) {
                const row = this.tableOtherCharges.rows[i];
                if (row?.data?.data["est_fuel_surcharge"] === "Y") {
                    row.deleteRow()
                }
            }
            this.sourceOtherChargeEdi.data = [];
            const otherCharges = data["other_charges"] || [];
            otherCharges.forEach(element => {
                const isAppending = appendingId.length == 0 || !appendingId.includes(element["id"]);
                const modelRow = new ModelRow(this.sourceOtherChargeEdi.url, isAppending);
                modelRow.setValues(element);
                this.sourceOtherChargeEdi.data.push(modelRow);
            });
            this.tableOtherCharges.displayData(null, this.sourceOtherChargeEdi.data, 0);

            // Auto Rate
            const items = this.textboxRateType.items as DropdownItem[];
            this.textboxRateType.selectedItem = items.find((item) => item.value === orderApiData["rate_type"]);
            this.mainDataSource.activeRow.set("rate_type", orderApiData["rate_type"]);
            this.mainDataSource.activeRow.set("bill_distance", orderApiData["bill_distance"]);
            this.mainDataSource.activeRow.set("autorate_status", orderApiData["autorate_status"]);
            this.mainDataSource.activeRow.set("rate", orderApiData["rate"]);
            this.mainDataSource.activeRow.data["calc_method"] = data["rate_type"];
            this.mainDataSource.activeRow.set("rate_units", orderApiData["rate_units"]);
            this.mainDataSource.activeRow.set("freight_charge", orderApiData["freight_charge"]);
            this.mainDataSource.activeRow.set("freight_charge_n", orderApiData["freight_charge_n"]);
            this.mainDataSource.activeRow.set("otherchargetotal", this.getOtherChargeTotal(false));
            this.mainDataSource.activeRow.set("rate_id", orderApiData["rate_id"]);
            if (orderApiData["total_charge"] != null) {
                if (orderApiData["total_charge"].amount != this.mainDataSource.activeRow.get("total_charge")?.amount || 0) {
                    this.mainDataSource.activeRow.set("total_charge", orderApiData["total_charge"]);
                    this.mainDataSource.activeRow.set("total_charge_n", orderApiData["total_charge_n"]);
                    totalChargeChanged = true;
                }
            }
            if (orderApiData["totalcharge_and_excisetax"] != null) this.mainDataSource.activeRow.set("totalcharge_and_excisetax", orderApiData["order_total_charges_plus_excisetax"]);

            setAutoRateContext(this, orderApiData["rate_id"]);
            if (orderApiData["rate_id"] == null) {
                Snackbar.showSnackbar("No valid rates found for this tender.");
            }

            // if (calcMaxTargetPay) this.carrierPayLayout.setDataFromResponse(data.max_target_pay_data);
            if (totalChargeChanged && this.doAfterRating != null) this.doAfterRating();
        } finally {
            this.calculatingCharges = false;
            this.mainDataSource.setComponentsBusy(false);
        }
    }

    shouldCalcMaxPay(): boolean {
        return false;
        // return this.autoCalcMaxPay && this.carrierPayLayout?.visible && (this.activeRow.get("override_max_pay") == null || this.activeRow.get("override_targetpay") == null)
    }

    /** This is an event handler for the onChange event of checkboxLockMiles.  */
    checkboxLockMilesOnChange(event) {
        this.textboxBillDistance.enabled = event.newValue === false;
    }

}
