import {
    BlurEvent, Button, ClickEvent, Component, DataSource, DataSourceAction, DataSourceExecutionEvent, Image,
    LookupModelSearchEvent, Panel, Snackbar, Switch, Tab, TableContentsChangedEvent,
    TableRow, TableRowCreationEvent, TableRowDisplayEvent, TableRowMode, Textbox
} from "@mcleod/components";
import { Api, Block, FieldUpdateEvent, getUnauthSettings, ModelRow, NumberUtil } from "@mcleod/core";
import { BrltlUtil } from "../BrltlUtil";
import { AutogenLayoutBrltlFreightItemTable } from "./autogen/AutogenLayoutBrltlFreightItemTable";
import { RowBrltlOrderFreightGroupItem } from "./models/ModelBrltlOrderFreightGroupItem";


const fgiHazmatFields = ["hazmat", "hazmat_number", "hazmat_class_code", "hazmat_emergency_number", "hazmat_reference_data", "hazmat_is_residue",
    "poison_zone", "is_poison", "hazmat_qty_code", "placard_required", "_lookup_hazmat_class_code", "hazmat_ref_type_code",
    "hazmat_subsidiary_code", "hazmat_unna_number", "hazmat_packing_group", "lookup_hazmat_packing_group", "hazmat_proper_shipname", "hazmat_erg_number"
];

const fgiCommodityFields = ["nmfc_class_code", "_lookup_nmfc_class_code", "fgi_packaging_type_code", "_lookup_fgi_packaging_type_code",
    "length", "height", "width", "description", "nmfc_code", "_lookup_nmfc_code",
    "weight", "weight_uom_type_code", "req_spots", "handling_units"
];

const dimensionFields = ["length", "width", "height"];
const requiredFields = ["weight", "req_spots", "nmfc_class_code"];

interface FgiData {
    row: TableRow,
    textLength: Textbox,
    textWidth: Textbox,
    textHeight: Textbox,
    textHandlingUnits: Textbox,
    textWeight: Textbox,
    textboxLinearUnits: Textbox,
    textboxCubicUnits: Textbox
}

export class BrltlFreightItemTable extends AutogenLayoutBrltlFreightItemTable {
    private fgiMap = new Map<number, FgiData>();
    private _sourceOrder: DataSource;
    public doBeforeCommoditySearch: (filter: any) => void;
    private maxWeight = getUnauthSettings().company_settings["ltl_max_weight"];
    private unitOfMeasure = getUnauthSettings()?.company_settings?.["ltl_freight_dimension_uom"];

    public get sourceOrder(): DataSource {
        return this._sourceOrder;
    }

    sourceFreightGroupItemAfterExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() === DataSourceAction.SEARCH) {
            this.setFgiTotals();
        }
    }

    styleTab(tab: Tab) {
        tab.heading.setProps({ color: "subtle.light", fontBold: false, borderWidth: 0 });
        tab.headingLabel.setProps({ color: "McLeodPrimary", fontSize: "medium", fontBold: true });
    }

    buttonAddItemOnClick(event: ClickEvent) {
        this.addFreightItemToTable().then((tableRow: TableRow) => {
            const textCommodity = tableRow.findComponentById("textCommodity") as Textbox;
            textCommodity?.focus();
            tableRow.scrollIntoView({ block: Block.CENTER });
            textCommodity?.focus();
        });
    }

    addFreightItemToTable(): Promise<TableRow> {
        return new Promise((resolve) => {
            this.tableFreightItems._createNewRowData().then(row => {
                const addRowResult = this.tableFreightItems.addRow(row, { mode: TableRowMode.ADD }, {
                    display: true,
                    addToData: true,
                    save: false
                });
                resolve(addRowResult.row);
            });
        });
    }

    removeBlankFreightItem() {
        if (this.tableFreightItems.rows.length == 1) {
            const tableRow = this.tableFreightItems.rows[0];
            const modelRow = tableRow.data as RowBrltlOrderFreightGroupItem;
            if (modelRow.isNull("commodity_id") && modelRow.isNull("nmfc_class_code") && modelRow.isNull("req_spots") && modelRow.isNull("weight")) {
                tableRow.deleteRow();
            }
        }
    }

    tableFreightItemsOnRowDisplay(event: TableRowDisplayEvent) {
        const tableRow = event.target as TableRow;
        const modelRow = tableRow.data;
        modelRow.addAfterFieldUpdateListener((event: FieldUpdateEvent) => {
            if ((event.fieldName == "length" || event.fieldName == "width" || event.fieldName == "height") && event.newValue == null) {
                modelRow.setValues({ cubic_units: null, linear_units: null, density: null, suggested_nmfc_class: null })
            }
        });

        const fgiData: FgiData = {
            row: tableRow,
            textLength: tableRow.findComponentById("textLength") as Textbox,
            textWidth: tableRow.findComponentById("textWidth") as Textbox,
            textHeight: tableRow.findComponentById("textHeight") as Textbox,
            textHandlingUnits: tableRow.findComponentById("textHandlingUnits") as Textbox,
            textWeight: tableRow.findComponentById("textWeight") as Textbox,
            textboxLinearUnits: tableRow.findComponentById("textboxLinearUnits") as Textbox,
            textboxCubicUnits: tableRow.findComponentById("textboxCubicUnits") as Textbox
        }

        this.fgiMap.set(tableRow.index, fgiData);
        this.addDenistyListeners(fgiData);
        this.setTableRowBorder(tableRow, "subtle.light");
        tableRow.forEveryChildComponent((comp) => {
            if (dimensionFields.includes(comp.field)) {
                if (comp instanceof Textbox && this.unitOfMeasure != null)
                    comp.caption = `${comp.caption} (${this.unitOfMeasure})`;
            } else {
                comp.required = requiredFields.includes(comp.field)
            }
        });
        this.configureForHazmat(tableRow, tableRow.data.getBoolean("hazmat"), false);
        this.styleTab(tableRow.findComponentById("tabHazmatDetails") as Tab);
        this.addFgiTotalListeners(fgiData);
    }

    addFgiTotalListeners(fgiData: FgiData) {
        fgiData.row.data.addAfterFieldUpdateListener((event: FieldUpdateEvent) => {
            if (event.fieldName == "handling_units" || event.fieldName == "weight")
                this.setFgiTotals();
        });
    }

    private addDenistyListeners(fgiData: FgiData) {
        const blurListener = (event: BlurEvent) => {
            if (event.changedWhileFocused)
                this.calculateCubeAndDensity(fgiData, (event.target as Textbox).field);
        };

        Object.values(fgiData).forEach(obj => {
            if (obj instanceof Textbox)
                obj.addBlurListener(blurListener);
        });
    }

    calculateCubeAndDensity(fgiData: FgiData, changedField?: string) {
        const data = fgiData.row.data;
        let body: any = {
            weight: data.get("weight"),
            length: data.get("length"),
            width: data.get("width"),
            height: data.get("height"),
            handling_units: data.get("handling_units"),
            nmfc_class_code: data.get("nmfc_class_code")
        }
        if (Object.values(body).every(o => o != null)) {
            body = {
                linear_units: data.get("linear_units"),
                cubic_units: data.get("cubic_units"),
                changed_field: changedField,
                ...body
            }
            data.set("suggested_nmfc_class", null);
            Api.post("lme/dispatch/fgi-calculations", body).then(result => {
                const apiData = result.data[0];
                if (apiData) {
                    data.set("cubic_units", apiData.cubic_units);
                    data.set("linear_units", apiData.linear_units);
                    data.set("density", apiData.density);
                    data.set("suggested_nmfc_class", apiData.nmfc_class_code)
                }
                if (data.get("suggested_nmfc_class"))
                    Snackbar.showWarningSnackbar(`Density does not match freight class.  The suggested freight class is ${data.get("suggested_nmfc_class")}.`);
            });
        }
    }

    private setTableRowBorder(tableRow: TableRow, borderColor: string) {
        tableRow.setProps({
            borderWidth: 1,
            borderRadius: 4,
            borderColor: borderColor,
            paddingTop: 12,
            paddingBottom: 20,
            marginTop: 15,
            borderShadow: true
        });
    }

    tableFreightItemsOnRowCreate(event: TableRowCreationEvent) {
        const tableRow = event.target as TableRow;
        const modelRow = tableRow.data as ModelRow;
        modelRow.set("lme_order_id", this.sourceOrder?.activeRow?.get("id"));
        modelRow.setLookupModelData("nmfc_class_code", new ModelRow("nmfc_class_code", false, { field_code: modelRow.get("nmfc_class_code") }))
    }

    buttonDeleteFgiOnClick(event: ClickEvent) {
        const tableRow = TableRow.getContainingTableRow(event.target as Button);
        tableRow.deleteRow();
    }

    buttonExpandRowOnClick(event: ClickEvent) {
        const tableRow: TableRow = TableRow.getContainingTableRow(event.target as Component);
        const expandablePanel: Component = tableRow.findComponentById("panelHazmat");
        tableRow["custom_expanded"] = !tableRow["custom_expanded"] ?? true;
        event.target["imageRotation"] = tableRow["custom_expanded"] ? 0 : 90;
        expandablePanel.visible = tableRow["custom_expanded"] ? true : false;
    }

    textPackagingTypeBeforeLookupModelSearch(event: LookupModelSearchEvent) {
        event.filter.field_table_alias = "FGI";
        event.filter.field_name = "fgi_packaging_type_code";
    }

    textCommodityBeforeLookupModelSearch(event: LookupModelSearchEvent) {
        if (this.doBeforeCommoditySearch)
            this.doBeforeCommoditySearch(event.filter);
        const textCommodity = event.target as Textbox;
        if (textCommodity.onSelectItem == null) {
            textCommodity.onSelectItem = ((textbox, selection) => {
                this.commoditySelected(textbox, selection as ModelRow);
                return undefined;
            })
        }
    }

    commoditySelected(textCommodity: Textbox, selectedRow: ModelRow<any>) {
        if (selectedRow != null) {
            const row = textCommodity.getRelevantModelRow();
            const tableRow = TableRow.getContainingTableRow(textCommodity);

            fgiCommodityFields.forEach(field => row.set(field, null));
            fgiHazmatFields.forEach(field => row.set(field, null));
            row.set("hazmat_unna_nbr", null);
            row.setLookupModelData("nmfc_class_code", new ModelRow("nmfc_class_code", false, { field_code: selectedRow.get("nmfc_class_code") }))
            let pieces = null;
            if (selectedRow.getBoolean("weight_is_per_piece") ||
                selectedRow.getBoolean("spots_is_per_piece") ||
                selectedRow.getBoolean("handling_units_is_per_piece") ||
                selectedRow.getBoolean("fgi_value_is_per_piece")||
                selectedRow.getBoolean("cubic_units_is_per_piece")) {
                pieces = 1;
            }
            row.set("pieces", pieces);
            const fgiData = selectedRow.get("fgi_data");
            row.addAfterFieldUpdateListener((event: FieldUpdateEvent) => {
                if ("hazmat" == event.fieldName) {
                    this.configureForHazmat(tableRow, row.getBoolean("hazmat"), row.getBoolean("hazmat"));
                    const panelHazmat = tableRow.findComponentById("panelHazmat") as Panel;
                    panelHazmat.visible = row.getBoolean("hazmat");
                }
            })

            if (fgiData != null)
                row.set({ ...fgiData });

            this.calculateCubeAndDensity(this.fgiMap.get(tableRow.index), "handling_units");
            delete selectedRow.data.fgi_data;
            this.piecesUpdated(textCommodity);
            tableRow.cells[0].displayData(row, null, 0);
        }
    }

    textPiecesOnBlur(event: BlurEvent) {
        if (event.changedWhileFocused)
            this.piecesUpdated(event.target as Textbox)
    }

    piecesUpdated(textbox: Textbox) {
        const tableRow = TableRow.getContainingTableRow(textbox);
        const commodity = tableRow.data.getFirstLookupModelData("commodity_id");
        const weight = tableRow.data.get("weight");
        const handlingUnits = tableRow.data.get("handling_units");
        if (commodity != null) {
            this.setPerPieceValues(tableRow, "weight", this.getCommodityPerPiece(commodity, "weight"));
            this.setPerPieceValues(tableRow, "req_spots", this.getCommodityPerPiece(commodity, "req_spots", "spots_is_per_piece"));
            this.setPerPieceValues(tableRow, "handling_units", this.getCommodityPerPiece(commodity, "handling_units"));
        }
        const weightUpdated = weight != tableRow.data.get("weight");
        const handlingUnitsUpdated = handlingUnits != tableRow.data.get("handling_units");
        if (weightUpdated || handlingUnitsUpdated) {
            this.calculateCubeAndDensity(this.fgiMap.get(tableRow.index), handlingUnitsUpdated ? "handling_units" : "weight")
        }
    }

    private getCommodityPerPiece(commodity: ModelRow, field: string, perPieceField: string = field + "_is_per_piece"): number {
        let perPiece = null;
        if (!commodity.isNull(field) && commodity.getBoolean(perPieceField, false))
            perPiece = commodity.get(field);
        return perPiece
    }

    private setPerPieceValues(tableRow: TableRow, field: string, perPiece: number) {
        const data = tableRow.data;
        const pieces = parseInt(data.get("pieces", 0));
        if (!isNaN(pieces) && perPiece != null) {
            let value = perPiece * pieces;
            if ("req_spots" == field)
                value = Math.round(value);
            data.set(field, value);
        }
    }

    tableFreightItemsOnContentsChanged(event: TableContentsChangedEvent) {
        this.setFgiTotals();
    }

    setFgiTotals() {
        const totalWeight = BrltlUtil.getFgiTotal(this.tableFreightItems.dataSource, "weight");
        const totalHu = BrltlUtil.getFgiTotal(this.tableFreightItems.dataSource, "handling_units");
        this.setTotalCaptions(totalWeight, totalHu);
    }

    setTotalCaptions(weight: number, handlingUnits: number) {
        this.labelTotalWeight.caption = NumberUtil.formatDecimal(weight ?? 0, "#,###.#") + " lbs";
        this.labelTotalUnits.caption = (handlingUnits ?? 0).toString();
    }

    getRowsInAddOrUpdateMode(): TableRow[] {
        const rows = [];
        for (const row of this.tableFreightItems.rows)
            if (row.mode != TableRowMode.NONE)
                rows.push(row);
        return rows;
    }

    switchHazmatOnClick(event: ClickEvent) {
        const switchHazmat = event.target as Switch;
        const tableRow = TableRow.getContainingTableRow(switchHazmat);
        this.configureForHazmat(tableRow, switchHazmat.checked, switchHazmat.checked);
    }

    configureForHazmat(row: TableRow, isHazmat: boolean, expandRow: boolean) {
        const commodityTextBox = row.findComponentById("textCommodity") as Textbox;
        let hazmatImage = null;
        if (isHazmat)
            hazmatImage = new Image({ name: "warning", color: "error", marginLeft: 8 });
        commodityTextBox.imagePre = hazmatImage;
        const hazmatDetails = row.findComponentById("tabHazmatDetails") as Tab;
        hazmatDetails.expandCollapseTab(isHazmat, true);
        hazmatDetails.findComponentById("panelHazmat").displayData(row.data, null, 0); // workaround for displaying data in a tab on load
    }

    /** This is an event handler for the beforeLookupModelSearch event of textboxHazmatUnnaNbr.  */
    textboxHazmatUnnaNbrBeforeLookupModelSearch(event: LookupModelSearchEvent) {
        const textbox = event.target as Textbox;
        const tableRow = TableRow.getContainingTableRow(textbox);
        const data = tableRow.data;

        if (textbox.onSelectItem == null) {
            textbox.onSelectItem = (textbox: Textbox, selectedRow: ModelRow<any>) => {
                data.set("hazmat_proper_shipname", selectedRow.get("hazmat_proper_shipname"));
                data.set("hazmat_class_code", selectedRow.get("hazmat_class_code"));
                data.set("hazmat_erg_number", selectedRow.get("hazmat_erg_number"));
                data.set("hazmat_packing_group", selectedRow.get("hazmat_packing_group"));
                return null;
            }
        }
    }

    sourceBrltlOrderFreightGroupItemBeforeExecution(event: DataSourceExecutionEvent) {
        this.removeBlankFreightItem();
    }
}
