import { CommonDialogs } from "@mcleod/common";
import {
    BlurEvent, Button, ChangeEvent, ClickEvent, DataDisplayEvent, DataSource, DataSourceAction,
    DataSourceExecutionEvent, DataSourceMode, DataSourceModeChangeEvent, Dialog, EditRowDecorator, Label, Layout,
    LookupModelSearchEvent, McLeodMainPageUtil, Overlay, Panel, ParentSearchMode, SaveButton, ScreenStack,
    SlideoutDecorator, Snackbar, Switch, Table, TableAddRowResult, TableContentsChangedEvent, TableRow,
    TableRowBeforeSaveEvent, TableRowDisplayEvent, TableRowMode, Textbox, Toast, ValidationResult
} from "@mcleod/components";
import { ModelDataChangeType } from "@mcleod/components/src/databinding/DataSource";
import { DataSourceValidationEvent } from "@mcleod/components/src/events/DataSourceValidationEvent";
import { TableAction } from "@mcleod/components/src/events/TableContentsChangedEvent";
import {
    Alignment, Api, ArrayUtil, Currency, DisplayType, getAuthSettings, getLogger, getUnauthSettings, getUserSettings,
    HorizontalAlignment, McLeodClassicIntegration, Model, ModelRow, Navigation, PermissionsUtil, StringUtil,
    VerticalAlignment
} from "@mcleod/core";
import { SuccessFail } from "@mcleod/core/src/SuccessFail";
import { getDispatchControlBoolean } from "@mcleod/general/src/models/ModelDispatchControl";
import { OrderLoadTenderHistory } from "../../datafusion/src/OrderLoadTenderHistory";
import { UsersResponsibilitySlideoutTrans } from "../../general/src/UsersResponsibilitySlideoutTrans";
import { BRLTLOrder, BrltlUtil } from "../BrltlUtil";
import { apptConfirmCheck } from "./AppointmentUtil";
import { BillOfLading } from "./BillOfLading";
import { CarrierSelection } from "./CarrierSelection";
import { CarrierSelectionStepper } from "./CarrierSelectionStepper";
import { CheckDuplicateBOL, DuplicateBOLSource } from "./CheckDuplicateBOL";
import { DuplicateOrderPrompt } from "./DuplicateOrderPrompt";
import { OrderCreditValidator } from "./OrderCreditValidator";
import { OrderHandlingRequirementsSlideout } from "./OrderHandlingRequirementsSlideout";
import { OrderOverview } from "./OrderOverview";
import { OrderPostHist } from "./OrderPostHist";
import { OrderRates } from "./OrderRates";
import { OrderStop } from "./OrderStop";
import { OrdersEquipmentMatchListener } from "./OrdersEquipmentMatchListener";
import { PostToLoadBoards } from "./PostToLoadBoards";
import { setAutoRateContext } from "./RateUtil";
import { RearrangeStopSlideout } from "./RearrangeStopSlideout";
import { RecurringOrderPrompt } from "./RecurringOrderPrompt";
import { ShipmentStatusSlideout } from "./ShipmentStatusSlideout";
import { StopProgress } from "./StopProgress";
import { VoidOrderPrompt } from "./VoidOrderPrompt";
import { AutogenLayoutOrders } from "./autogen/AutogenLayoutOrders";
import { SaveAction } from "@mcleod/components/src/components/savebutton/SaveButton";

const log = getLogger("lme.dispatch.Orders");

const viewOrderURL = "lme/dispatch/Orders?mode=update&key=";
const equipmentTypeOptionsMap = {
    tarps: "T",
    expedited: "E"
};

interface ModelCustomerBalanceInput {
    customer_id: string,
    exclude_order_id?: string,
    order_total_charges?: Currency,
    order_total_charges_plus_excisetax?: Currency,
    order_bill_date?: Date
    order_other_charges?: any
}

export function isSpotOrder(data: ModelRow | string): boolean {
    let status: string;
    if (data instanceof ModelRow) {
        status = data.get("subject_order_status");
    } else {
        status = data;
    }
    return new Set(["S", "C"]).has(status);
}

export class Orders extends AutogenLayoutOrders {
    private _doAfterAction: () => void = () => this.mainDataSource.search({ id: this.mainDataSource.activeRow.get("id") });
    private _postOrderAction: string | (() => void);
    private _viewOrderURL = "lme/dispatch/Orders?mode=update&key=";
    private viewOrderListURL = "lme/dispatch/OrderList?ids=";
    private _assignCarrierURL = "assignCarrier";
    private _findCarrierURL = "lme/dispatch/Movement?mode=update&key=";
    private newOrderURL = "lme/dispatch/Orders";
    private creditValidator: OrderCreditValidator;
    private checkDuplicateBOL: CheckDuplicateBOL;
    private _invokedFromEdi: boolean = false;
    private loadingRecurringOrder: boolean = false;
    private _validateRespFilteringDuringAdd = (event: DataSourceValidationEvent) => this.validateRespFilteringDuringAdd(event, this.buttonUsers);
    private _enableNextActions: boolean;
    private _isBrokerageLtlCompany: boolean = false;
    private _isAsset: boolean = false;
    private postToLoadBoard: boolean = false;
    private carrierSelectionShouldRerate: boolean = false;
    private saveAction: SaveAction;

    override onLoad(): void {
        if (BrltlUtil.isBrokerageLtl()) {
            this.setupBrltlDatasources();
        }
        this.creditValidator = new OrderCreditValidator(this);
        this.checkDuplicateBOL = new CheckDuplicateBOL(this);

        if (!this.mainDataSource.isSearching) {
            this.textboxBillingUserId.buttonImageName = "";
        }

        this.addOnSelectListeners();
        this.switchRecurringOrder.enabled = false;
        this.addRecurringOrderListener();
        this.addBeforeLookupModelListeners();

        if (history.state != null && history.state.state?.successAdd == true)
            Toast.showSuccessToast("Yay! Your order was created successfully!", "Order number " + history.state.state?.orderId);

        this.stopsTable.onEditLayoutLoaded = this.stopTableOnEditLoaded();
        (this.layoutRates as OrderRates).doAfterRating = () => this.creditValidator.validate();

        this.labelHoldBadge.tooltipCallback = () => {
            return this.labelHoldBadge.showTooltip(this.mainDataSource.activeRow?.get("hold_reason"), null, { themeKey: "quickInfo" });
        }
        this.dataHeader["labelTitle"].minWidth = 220;

        this.checkboxTarps.addChangeListener(event => {
            if (event.userInitiatedChange) this.updateEquipTypeOptions();
        });
        this.checkboxExpedited.addChangeListener(event => {
            if (event.userInitiatedChange) this.updateEquipTypeOptions();
        });

        this.layoutRates.checkPickupAndDeliveryEntered = () => {
            const stopData = this.sourceStop.data;
            if (stopData.length < 2) return false;
            const pickupEntered = stopData[0].get("stop_type") === "PU" && stopData[0].get("city_id") != null;
            const deliveryEntered = stopData[stopData.length - 1].get("stop_type") === "SO" && stopData[stopData.length - 1].get("city_id") != null;
            return pickupEntered && deliveryEntered;
        }

        this.setDispatchSettings();
        this.addAdditionalMainDataSourceListeners();
        this.layoutRates.layoutMovementRates.panelOrderCharges.visible = false;
        this.addLayoutLoadListener(() => {
            this.enableNextActions = !this.displayingFromToolbox && !this["create_spot"];
        });
        if (this.displayingFromToolbox) {
            this.dataHeader.showSaveAndClose = true;
            this.dataHeader.showSave = false;
        }
        this.switchTenderReplyTransmitted.allowIndeterminate = true;
    }

    private addAdditionalMainDataSourceListeners() {
        this.mainDataSource.componentValidationFailedCallback = (result: ValidationResult) => this.onComponentValidationFailed(result);
        this.mainDataSource.afterGetDataboundValues = (row: ModelRow, dataSource: DataSource) => this.sourceOrdersAfterGetDataboundValues(row, dataSource);
        this.mainDataSource.doBeforeDataSourcePost = async () => {
            // Do additional validation here before posting
            let result = true;
            if (this.mainDataSource.mode == DataSourceMode.ADD) {
                result = await new OrdersEquipmentMatchListener(this).rowUpdated();
            }
            if (this.switchPostLoadboard.checked == true && this.activeRow.getBoolean("is_brokerage_ltl")) {
                this.mainDataSource.activeRow.set("loadboard", false);
                Snackbar.showWarningSnackbar("Brokerage LTL orders may not be posted to the load boards.");
                this.tabsetAddOrders.scrollToTab(this.tabNextActions);
                return false;
            }
            return result
        }
    }

    buttonAutoEquipmentMatchOnClick(event: ClickEvent) {
        return new OrdersEquipmentMatchListener(this).rowUpdated().then(() => {
            this.layoutHandlingReqs.populateHdrs(this.sourceBrltlOrderHdrXFgp.data, true, true).then(() => {
                this.sourceBrltlOrderHdrXFgp.notifyHasChangedComponents(true);
            });
            this.sourceEquipMatchDetail.displayDataInBoundComponents();
            this.textboxDefaultEquipMatchId.visible = !this.mainDataSource.activeRow.isNull("default_match_id");
        });
    }

    private addOnSelectListeners() {
        this.textboxEquipmentTypeIdGeneral.onSelectItem = () => {
            this.textboxEquipmentTypeIdEquipment.text = this.textboxEquipmentTypeIdGeneral.text;
            if (this.mpactConfigured()) {
                this.layoutRates.calculateRates(false, false);
            }
            return undefined;
        };

        this.textboxEquipmentTypeIdEquipment.onSelectItem = () => {
            this.textboxEquipmentTypeIdGeneral.text = this.textboxEquipmentTypeIdEquipment.text;
            if (this.mpactConfigured()) {
                this.layoutRates.calculateRates(false, false);
            }
            return undefined;
        };

        this.textboxRevenueCodeId.onSelectItem = () => {
            if (this.textboxRevenueCodeId.getFirstLookupModelData()?.get("id") != null)
                this.revenueCodeChanged(this.textboxRevenueCodeId.getFirstLookupModelData().get("id"));
            return undefined;
        }

        this.textboxOrderType.onSelectItem = () => {
            if (this.textboxOrderType.getFirstLookupModelData()?.get("id") != null) {
                this.orderTypeChanged(this.textboxOrderType.getFirstLookupModelData().get("id"));
            }
            return undefined;
        }

        this.textboxCustomerId.onSelectItem = () => {
            if (this.textboxCustomerId.getFirstLookupModelData()?.get("id") != null) {
                (this.layoutOverview as OrderOverview).customerChanged(this.textboxCustomerId.getFirstLookupModelData());
            }
            this.creditValidator.customerId = this.textboxCustomerId.getFirstLookupModelData()?.get("id")
            return undefined;
        }

        this.textboxDefMoveType.addChangeListener((event: ChangeEvent) => {
            this.isAsset = event.newValue === "Asset";
            if (!this.textboxDefMoveType.isDropdownVisible() && event.userInitiatedChange) {
                this.tabLoadBoardDetails.visible = (!this.isAsset && this.mainDataSource.mode !== DataSourceMode.ADD);
            }
        });
    }

    private addBeforeLookupModelListeners() {
        const activeFilter = event => event.filter.active = "Y";
        const userfilter = event => event.filter.web_user_type = "U";
        this.textboxBillingUserId.addBeforeLookupModelSearchListener(userfilter);
        this.textboxEnteredUserId.addBeforeLookupModelSearchListener(userfilter);
        this.textboxOperationsUser.addBeforeLookupModelSearchListener(userfilter);
        this.textboxBrkQualProfile.addBeforeLookupModelSearchListener(activeFilter);
        this.textboxBrokerageWorkflowId.addBeforeLookupModelSearchListener(activeFilter);
        this.textboxOrderOrderedBy.addBeforeLookupModelSearchListener(event => {
            event.filter.parent_row_id = this.mainDataSource.activeRow?.get("customer_id");
            event.filter.parent_row_type = "C";
        });

        this.textboxOrderOrderedBy.onSelectItem = ((textbox, selection) => {
            this.mainDataSource.activeRow.set("ordered_by_id", (selection as ModelRow).get("id"))
            return undefined;
        });
    }

    private mpactConfigured(): boolean {
        return getAuthSettings().dispatch_control[0].target_pay_calc == "M" &&
            !StringUtil.isEmptyString(getAuthSettings().dispatch_control[0].mpact_smart_api_key) &&
            !StringUtil.isEmptyString(getAuthSettings().dispatch_control[0].mpact_host_url);
    }

    public get isAsset(): boolean {
        return this._isAsset;
    }

    public set isAsset(value: boolean) {
        this._isAsset = value;
        this.buttonFindCarrier.visible = !value;
        this.buttonAssignCarrier.visible = !value;
        this.switchPostToLoadBoards.visible = !value;
        this.tabLoadBoardDetails.visible = !value && this.mainDataSource.mode !== DataSourceMode.ADD;
    }

    public get isBrokerageLtlCompany(): boolean {
        return this._isBrokerageLtlCompany;
    }

    public set isBrokerageLtlCompany(value: boolean) {
        this._isBrokerageLtlCompany = value;
        this.tabBrltlFreightItems.visible = value;
        this.tabAccessorials.visible = value;
        this.layoutRates.panelRatingDetails.visible = value;
        if (value && this.mainDataSource.mode === DataSourceMode.ADD) {
            this.addBlankItemIfBRLTL();
            this.textboxOrderMode.addBlurListener(() => {
                this.addBlankItemIfBRLTL();
            });
            this.textboxRevenueCodeId.addBlurListener(() => {
                this.addBlankItemIfBRLTL();
            });
            this.textboxOrderType.addBlurListener(() => {
                this.addBlankItemIfBRLTL();
            });
        }

        if (value) {
            this.layoutFreightItems.doBeforeCommoditySearch = (filter: any) => {
                filter.product_book_location = this.sourceStop?.data?.[0].get("location_id");
                filter.product_book_customer = this.activeRow.get("customer_id");
            };
            this.textboxBolNote.visible = true;
        }
    }

    set enableNextActions(value: boolean) {
        this._enableNextActions = value;
        if (this.getInvokedFromEdi()) return;
        if (this._enableNextActions) {
            const orderNextActionDefault = getAuthSettings().user_settings.new_order_next_action;
            switch (orderNextActionDefault) {
                case "N": {
                    this.postOrderAction = this.newOrderURL;
                    this.buttonEnterNewOrder.clicked();
                    break;
                }
                case "A": {
                    this.postOrderAction = this._assignCarrierURL;
                    this.buttonAssignCarrier.clicked();
                    break;
                }
                case "F": {
                    this.postOrderAction = this._findCarrierURL;
                    this.buttonFindCarrier.clicked();
                    break;
                }
                case "V":
                default: {
                    this.postOrderAction = this.viewOrderListURL;
                    this.buttonViewOrder.clicked();
                    break;
                }
            }
        } else {
            this.panelHowManyOrders.visible = false;
            this.panelNextActionButtons.visible = false;
            this.panelNextActions.padding = 12;
        }
    }

    private onComponentValidationFailed(result: ValidationResult): void {
        const comp = result.component;
        if (comp?.owner instanceof OrderStop)
            comp.owner.onComponentValidationFailed(result);
    }

    private updateEquipTypeOptions() {
        const options = [];
        if (this.checkboxTarps.checked) {
            options.push(equipmentTypeOptionsMap.tarps);
        }
        if (this.checkboxExpedited.checked) {
            options.push(equipmentTypeOptionsMap.expedited);
        }
        if (options.length > 0) this.activeRow?.set("equipment_type_options", options.join("|"));
    }

    public override getDataHeaderEllipsisActions(): Label[] {
        const result: Label[] = []
        if (this.mainDataSource?.activeRow == null || this.mainDataSource.mode !== DataSourceMode.UPDATE)
            return result;

        const orderStatus = this.mainDataSource.activeRow.get("status");
        if (isSpotOrder(this.mainDataSource.activeRow)) {
            if (orderStatus != "V") {
                result.push(new Label({
                    caption: "Convert to Order",
                    wrap: false,
                    onClick: (event) => this.convertSpotToOrder(event)
                }));
            }
        } else {
            result.push(new Label({
                caption: "Duplicate Order",
                wrap: false,
                onClick: (event) => this._duplicateOrder()
            }));
        }
        result.push(new Label({
            caption: "Create Recurring Order",
            wrap: false,
            onClick: (event) => this.createRecurringOrder(event)
        }));
        if (this.activeRow.getBoolean("is_brokerage_ltl", false)) {
            result.push(new Label({
                caption: "Get Terminal Info",
                wrap: false,
                onClick: (event) => BrltlUtil.getTerminalInfo(this.activeRow.get("id"))
            }));
        }

        const isUpdateOrdersAfterBilledDenied: boolean = PermissionsUtil.isUserDeniedAction("Dispatch.Update orders after billed");
        const isBilled: boolean = !this.mainDataSource.activeRow.isNull("bill_date");
        const isTransferred: boolean = !this.mainDataSource.activeRow.isNull("xferred2billing_dt");
        if (orderStatus == "A" && !((isBilled || isTransferred) && isUpdateOrdersAfterBilledDenied)) {
            result.push(new Label({ caption: "Void Order", wrap: false, onClick: (event) => this.voidOrder(event) }));
        }
        return result;
    }

    private _rearrangeStops() {
        const slideout = new RearrangeStopSlideout("O", this.mainDataSource?.activeRow.get("id"));
        slideout.showSlideout((canceled: boolean) => {
            if (!canceled) {
                Toast.showSuccessToast("Your stops were successfully resequenced");
                this.sourceStop.search({ order_id: this.activeRow.get("id") });
            }
        });
    }

    textboxCustomerIdOnBlur(event: BlurEvent) {
        if (event.changedWhileFocused && this.mainDataSource.activeRow.isNull("customer_id"))
            this.creditValidator.customerId = null;

        if (this.mainDataSource.mode == DataSourceMode.ADD && !this.mainDataSource.activeRow.isNull("customer_id") && !this.mainDataSource.activeRow?.isNull("blnum") && !this.switchRecurringOrder.checked) {
            this.checkForDuplicateBOL(DuplicateBOLSource.ADD)
                .then((response) => {
                    if (!response.success)
                        this.textboxBlnum.validationWarning = response.reason;
                });
        }

        if ((this.mainDataSource.mode == DataSourceMode.ADD || this.mainDataSource.mode == DataSourceMode.UPDATE) && !this.mainDataSource.activeRow.isNull("customer_id")) {
            this.stopsTable.rows?.forEach(tableRow => {
                const orderStop = tableRow["orderStop"] as OrderStop;
                orderStop.textboxLocationId.googlePlacesProps.customerId = this.mainDataSource.activeRow?.get("customer_id");
            });
        }
    }

    async checkStopsForDistanceChange() {
        const stopdata = this.sourceStop.data;
        let changedText = this.sourceStop.deletedData?.length > 0 ? "removed from" : null;
        for (let i = 0; changedText == null && i < stopdata?.length; i++) {
            if (stopdata[i]._appending) changedText = "added to";
            if (stopdata[i].getChangedData()?.city_id != null) changedText = "modified on";
        }
        const locked = this.activeRow?.getBoolean("lock_miles", false)
        if (changedText != null) {
            if (locked) {
                const caption = `A stop has been ${changedText} this order which may affect mileage.  Would you like to unlock the mileage?`;
                const unlock = await CommonDialogs.showYesNo(caption, "Unlock Miles");
                if (unlock) {
                    this.activeRow.set("lock_miles", "N");
                    this.activeRow.set("bill_distance", null);
                }
            } else {
                this.activeRow.set("bill_distance", null);
            }
        }
    }

    enableOnHoldComponents(value: boolean) {
        let ttCallback = null;
        if (!value) {
            ttCallback = () => {
                return this.switchHoldLoad.showTooltip(
                    new Panel({
                        width: 225, padding: 0, components: [
                            new Label({ caption: "Order Cannot Be Taken Off Hold", rowBreak: true, fontBold: true }),
                            new Label({ caption: "The Pending Credit Override must be approved to take order off hold" })
                        ]
                    }), { position: Alignment.RIGHT });
            }
        }
        this.switchHoldLoad.tooltipCallback = ttCallback;
        this.switchHoldLoad.enabled = value;
        this.textboxHoldReason.enabled = value;
    }

    private addRecurringOrderListener() {
        this.textboxRecurringOrderId.onSelectItem = ((input, selectedItem) => {
            if (this.sourceOrders.mode == DataSourceMode.ADD) {
                if (selectedItem) {
                    this.mainDataSource.setComponentsBusy(true);
                    Api.search("lme/dispatch/use-recurring-order", { "recurring_order_id": (selectedItem as ModelRow).get("id") }).then(response => {
                        this.loadingRecurringOrder = true;
                        this.loadFromApiData(response.data[0], DataSourceMode.ADD);
                        this.addBlankItemIfBRLTL();
                    }).finally(() => {
                        this.mainDataSource.setComponentsBusy(false);
                    });
                }
                return (selectedItem as ModelRow).get("id");
            }
        });
    }

    private addBlankItemIfBRLTL() {
        if (!this.tabBrltlFreightItems.visible) return;const brltlData = this.getBRLTLData()
        if (BrltlUtil.shouldRateUsingTariff(brltlData)&& this.layoutFreightItems.tableFreightItems.data.length == 0) {
                this.layoutFreightItems.addFreightItemToTable();

        }
        else {
            this.layoutFreightItems.removeBlankFreightItem();
        }
    }

    public loadFromApiData(apiData: any, mode: DataSourceMode) {
        const ratesLayout = this.layoutRates as OrderRates;
        this.removeChildDataSources();
        const order = new ModelRow("lme/dispatch/orders", true, apiData);
        this.checkRateExpiration(apiData.is_rate_expired);
        //set the stop data first so that when we set the order dataSource mode, we can not load the default 2 stops
        this.loadChildTableData(this.stopsTable, this._getArrayAndNull(order, "stops"), mode);
        this.loadChildTableData(this.tableEquipmentRequirements, this._getArrayAndNull(order, "equip_match_details"), mode);
        this.loadChildTableData(ratesLayout.tableOtherCharges, this._getArrayAndNull(order, "other_charges"), mode);
        this.loadChildTableData(this.layoutFreightItems.tableFreightItems, this._getArrayAndNull(order, "freight_group_items"), mode);
        this.getDFHandlingRequirementData(order);
        this.mainDataSource.setRowsAndMode(mode, [order], () => this._displayDataCallback(this.mainDataSource));
        this.showHazmatWarning(this.mainDataSource.activeRow.get("hazmat_code") != null ? "Y" : "N");
        const rateId = this.mainDataSource.activeRow.get("rate_id");
        ratesLayout.calculateRates(false, false).then(() => {
            setAutoRateContext(ratesLayout, rateId);
        });
    }

    private getDFHandlingRequirementData(row: ModelRow)
    {
        const rows: ModelRow[] = [];
        const hrs = row.get("handling_requirements");
        if(hrs != null && hrs.length > 0 ){
            hrs.forEach( data =>{
                const hr = new ModelRow("lme/dispatch/brltl-order-hdr-x-fgp", false, data);
                rows.push(hr);
                this.layoutHandlingReqs.addHdrRow(hr);
            });
        }
        this.layoutHandlingReqs.sourceBrltlOrderHdrXFgp.data = rows;
    }

    private _displayDataCallback(dataSource: DataSource) {
        dataSource.boundComponents.forEach(component => {
            if (component.field != "recurring_order_id")
                dataSource.displayDataInBoundComponent(component);
        });
        this.creditValidator.customerId = this.mainDataSource.activeRow.get("customer_id");
        (this.layoutRates as OrderRates).setMaxTargetPayData();
    }

    private _getArrayAndNull(row: ModelRow, field: string): ModelRow[] {
        const result = row.get(field) || [];
        row.set(field, null);
        return result;
    }

    removeChildDataSources() {
        this.stopsTable.rows?.forEach(tableRow => {
            const orderStop = tableRow["orderStop"] as OrderStop;
            if (orderStop != null) {
                orderStop.commentsAndRefNbrs.sourceStopNote.parentDataSource = null;
                orderStop.commentsAndRefNbrs.sourceReferenceNumber.parentDataSource = null;
            }
        });
    }

    private loadChildTableData(table: Table, data: any[], mode: DataSourceMode) {
        const tableDataSource = table.dataSource;
        tableDataSource.data = [];
        const modelRows = [];
        data.forEach((element: any) => modelRows.push(new ModelRow(tableDataSource.url, true, element)));
        tableDataSource.setRowsAndMode(mode, modelRows);
    }

    private checkRateExpiration(is_rate_expired: boolean) {
        if (is_rate_expired) {
            Snackbar.showWarningSnackbar("The rate on the recurring order you used to create this order has expired. "
                + "Rate information, freight charges and other charges have not been copied over.");
        }
    }

    clearEquipmentIds(event) {
        if (event.target.text == "") {
            this.textboxEquipmentTypeIdGeneral.text = "";
            this.textboxEquipmentTypeIdEquipment.text = "";
        }
    }

    async initializeAdd() {
        this.switchRecurringOrder.enabled = true;
        this.textboxRecurringOrderId.visible = false;
        this.textboxRecurringOrderId.visibleDuringAdd = false;

        this.textboxHoldReason.visible = false;
        this.howManyOrders.value = 1;

        if (this["quote_id"]) {
            const response = await Api.search("lme/dispatch/convert-quote-to-order", { quote_id: this["quote_id"] });
            const orderApiData = response.data[0];
            orderApiData["revenue_code_id"] = this.activeRow.get("revenue_code_id");
            orderApiData["_lookup_revenue_code_id"] = this.activeRow.get("_lookup_revenue_code_id");
            this.loadFromApiData(orderApiData, DataSourceMode.ADD);
            if (this["create_spot"]) {
                this.activeRow.set("create_spot", true);
                this.activeRow.set("rate_source", "S");
                this.activeRow.set("order_mode", "T");
                this.activeRow.set("loadboard", "Y");
                this.textboxCustomerId.required = false;
                this.textboxEquipmentTypeIdGeneral.required = true;
            }
        } else {
            await this.addStopsToTable();
        }

        this.mainDataSource.addValidationListener(this._validateRespFilteringDuringAdd);
    }

    setCompanySettings(mode: DataSourceMode) {
        const companySettings = getUnauthSettings()?.company_settings;
        this.textboxDefMoveType.visible = companySettings["is_asset"] && companySettings["is_brokerage"] && !companySettings["is_ltl"];

        this.isAsset = mode == DataSourceMode.ADD ? companySettings["default_move_type"] === "A" : this.activeRow.get("def_move_type") === "A";
        this.isBrokerageLtlCompany = BrltlUtil.isBrokerageLtl();
        if (this.getInvokedFromEdi()) this.setEdiFields();
    }

    setDispatchSettings() {
        const dispatchControlSettings = getAuthSettings().dispatch_control[0];

        if (dispatchControlSettings.enforce_billto == 'Y')
            this.textboxCustomerId.required = true;

        if (dispatchControlSettings.enforce_revenu_cod == 'Y')
            this.textboxRevenueCodeId.required = true;
    }

    onHoldLoadChange(event) {
        this.textboxHoldReason.visible = event.target.checked;
        this.textboxHoldReason.required = event.target.checked;
        this.labelHoldBadge.visible = event.target.checked;
        if (!event.target.checked) {
            this.mainDataSource.activeRow.set("hold_reason", null);
            this.creditValidator.validate();
        }
    }

    textboxBlnumOnBlur(event: BlurEvent) {
        if (!this.mainDataSource.activeRow?.isNull("blnum") && !this.switchRecurringOrder.checked) {
            let source: DuplicateBOLSource;
            if (this.mainDataSource.mode == DataSourceMode.ADD) {
                source = DuplicateBOLSource.ADD;
            } else if (this.mainDataSource.mode == DataSourceMode.UPDATE && this.activeRow.getChangedData()["blnum"] != null) {
                source = DuplicateBOLSource.UPDATE;
            }

            if (source != null) {
                this.checkForDuplicateBOL(source)
                    .then((response) => {
                        if (!response.success)
                            this.textboxBlnum.validationWarning = response.reason;
                    });
            }
        }
    }

    async checkForDuplicateBOL(source: DuplicateBOLSource): Promise<SuccessFail> {
        return await this.checkDuplicateBOL.validate(source, this.saveButton);
    }

    doBeforeExecute(event: DataSourceExecutionEvent) {
        if (event.getAction() === DataSourceAction.ADD) {
            this.stopsTable.rows.forEach(
                row => {
                    log.debug(row);
                    row.saveChanges();
                    log.debug(row.data);
                });
            log.debug("stops datasource length " + this.stopsTable.dataSource.data.length);
            if (this.howManyOrders.value > 1)
                this.mainDataSource.activeRow?.set("total_orders", this.howManyOrders.value);
        }

        if (event.getAction() === DataSourceAction.ADD || event.getAction() === DataSourceAction.UPDATE) {
            this.addBeforePostListeners();
        }
    }

    addBeforePostListeners() {
        if (this.mainDataSource.activeRow != null) {
            const row = this.mainDataSource.activeRow;
            row.addBeforePostListener(async postEvent => {
                await this.creditValidator.rowUpdated(postEvent)
                if (!this.mainDataSource.activeRow?.isNull("blnum")) {
                    await this.checkDuplicateBOL.rowUpdatedBySource(postEvent, this.saveButton);
                }
                if (this.mainDataSource.mode === DataSourceMode.ADD || await CarrierSelection.shouldRerate(this)) {
                    this.carrierSelectionShouldRerate = true;
                    if (this.saveButton.saveAction == SaveAction.SAVE_AND_CLOSE) {
                        this.saveAction = this.saveButton.saveAction;
                    }
                }
                if (this.switchPostToLoadBoards.checked) {
                    this.postToLoadBoard = true;
                }
            });
        }
    }

    async postOrderNavigateTo(): Promise<any> {
        let key = null;
        switch (this.postOrderAction) {
            case this.viewOrderUrl: // view order
                key = this.mainDataSource?.activeRow?.get("id");
                return Navigation.navigateTo(this.postOrderAction + key, { newTab: false });
            case this.viewOrderListURL:  // view order list
                const duplicateOrders: Array<String> = this.mainDataSource?.activeRow?.get("duplicate_orders", []);
                const orderIds = [this.mainDataSource?.activeRow?.get("id"), ...duplicateOrders];
                return Navigation.navigateTo(this.postOrderAction + orderIds.toString(), { newTab: false }).then(() => {
                    if (this.mainDataSource?.activeRow?.get("has_failed_orders")) {
                        this.creditValidator.showCustomerCreditValidationSnackbar("Unable to create all orders. " + this.textboxCustomerId.text +
                            " has exceeded their credit limit. " + (orderIds.length) +
                            " order(s) of " + this.howManyOrders.value + " were created.");
                    }
                });
            case this.newOrderURL:  // new order
                key = this.mainDataSource?.activeRow?.get("id");
                return Navigation.navigateTo(this.postOrderAction, { newTab: false }, {
                    successAdd: true,
                    orderId: key
                });
            case this._assignCarrierURL:  // assign carrier
                key = this.mainDataSource?.activeRow?.get("curr_movement_id");
                return Navigation.navigateTo(this._findCarrierURL + key, { newTab: false }, { assignCarrier: true })
            case this.findCarrierURL:  // find carrier
                if (this.activeRow.getBoolean("is_brokerage_ltl")) {
                    key = this.mainDataSource?.activeRow?.get("id");
                    return Navigation.navigateTo(this.viewOrderUrl + key, { newTab: false }, { selectCarrier: true });
                } else {
                    key = this.mainDataSource?.activeRow?.get("curr_movement_id");
                    return Navigation.navigateTo(this.postOrderAction + key, { newTab: false }, { findCarrier: true });
                }
            default:
                return Promise.resolve();
        }
    }

    executePostOrderAction() {
        const originStop = this.mainDataSource.activeRow?.get("origin_stop_id");
        const pickupData = this.sourceStop.data[0];
        this.postOrderNavigateTo().then(() => {
            if (this.postToLoadBoard && !this.activeRow.getBoolean("is_brokerage_ltl")) {
                this.displayPostToLoadBoardSlideout(originStop, pickupData);

            } else if (this.displayingFromToolbox) {
                this.addUnmountListener(() => {
                    this.displayOrderCreatedToast();
                });
            }
        });
    }

    doAfterExecute(event) {
        if (event.getAction() === DataSourceAction.ADD || event.getAction() === DataSourceAction.UPDATE) {
            this.activeRow.clearBeforePostListeners();
            this.mainDataSource.addAfterSearchPlusChildrenListener(async () => {
                if (this.activeRow.getBoolean("is_brokerage_ltl", false) && this.carrierSelectionShouldRerate) {
                    const snackbarTarget = this.saveAction == SaveAction.SAVE_AND_CLOSE ? McLeodMainPageUtil.getRouterPanel() : ScreenStack.getSnackbarTarget();
                    this.buttonCarrierRates.busy = true;
                    this.carrierSelectionShouldRerate = false;
                    await CarrierSelection.findCarrierRates(this.activeRow.get("id"), null, () => this.mainDataSource.search({ id: this.mainDataSource.activeRow.get("id") }), snackbarTarget).finally(() => {
                        this.buttonCarrierRates.busy = false;
                    });
                }
                if (event.getAction() === DataSourceAction.ADD) {
                    this.executePostOrderAction();
                }
            });
        } else if (event.getAction() === DataSourceAction.SEARCH) {
            this.addLayoutDataLoadListener(() => {
                const overviewLayout = this.layoutOverview as OrderOverview;
                (overviewLayout.layoutStopProgress as StopProgress).setStopsTable(this.stopsTable);
                const status = this.mainDataSource.activeRow.get("movement.status", null);
                const onHold = this.mainDataSource.activeRow.get("on_hold", null);
                if (status != null && status != 'A') {
                    if (this.switchPostLoadboard.checked)
                        this.switchPostLoadboard.checked = false;
                    this.switchPostLoadboard.enabled = false;
                    this.switchPostLoadboard.disabledTooltip = "Movement must be available to post to load boards";
                }
                if (status != null && status != 'A' && onHold != null && onHold == "Y" && this.switchPostLoadboard.checked == true)
                    this.createPostToLoadBoardDialog().then(() => this.switchPostLoadboard.checked = false);
                this._setEquipTypeOptions();
                this.layoutImageTable["populateForOrder"](this.mainDataSource.activeRow.get("id"));
                this.switchRecurringOrder.checked = (null != this.mainDataSource.activeRow.get("recurring_order_id"));
                this.updateEquipTypeOptions();
                this.checkReleaseToBilling();
                const commodityLookupData = this.textboxCommodityId.getFirstLookupModelData();
                this.showHazmatWarning(commodityLookupData?.get("is_hazmat", "N"));
                this.buttonSendBOL.visible = this.isBrokerageLtlCompany;
                if (this.isBrokerageLtlCompany)
                    this.getRatingDetails(this.mainDataSource.activeRow.get("id"));
            });
        }
        this.buttonCarrierRates.visible = this.activeRow.getBoolean("is_brokerage_ltl", false) && !this.getInvokedFromEdi();
        this.textboxDefaultEquipMatchId.visible = !this.mainDataSource.activeRow.isNull("default_match_id");
    }

    getRatingDetails(orderId: string) {
        this.layoutRates.sourceRatingDetails.search({ "order_id": orderId }).then(response => {
            this.layoutRates.sourceRatingDetailsSummary.search({ "order_id": orderId }).then(response => {
                this.layoutRates.switchByFreightItem.checked = true;
                this.layoutRates.tableRevSummary.visible = false;
            });
        });
    }

    private showCarrierSelectionSlideout() {
        const orderId = this.mainDataSource.activeRow.get("id");
        CarrierSelectionStepper.showSlideout({
            orderId: orderId,
            orderRow: this.mainDataSource.activeRow,
            onClose: () => this.mainDataSource.search({ id: orderId })
        });
    }

    doAfterModeChange(event: DataSourceModeChangeEvent) {
        if (this.mainDataSource.mode == DataSourceMode.SEARCH) {
            this.stopsTable.dataSource = null;
            this.textboxOrderedDate.displayType = DisplayType.DATERANGE;
        } else if (this.stopsTable.dataSource == null)
            this.stopsTable.dataSource = this.sourceStop;

        this.creditValidator.customerId = null;
        this.makeSubjectOrderAdjustments();
        if (event.oldMode != DataSourceMode.ADD && event.newMode == DataSourceMode.ADD) {
            this.initializeAdd();
        }
        else if (event.oldMode === DataSourceMode.NONE && event.newMode === DataSourceMode.UPDATE) {
            this.enforceActionPermissions();
            if (this.mainDataSource.activeRow?.get("credit_override_status") == "P" && this.mainDataSource.activeRow?.get("on_hold", null)) {
                this.enableOnHoldComponents(false);
            } else {
                this.creditValidator.customerId = this.mainDataSource.activeRow.get("customer_id");
            }
        }
        if (event.newMode !== DataSourceMode.ADD)
            this.mainDataSource.removeValidationListener(this._validateRespFilteringDuringAdd);
        this.activeRow.addAfterFieldUpdateListener((event) => {
            if (event.fieldName === "commodity_id" && this.textboxCommodityId.dataSource.actionInProgress == null) {
                this.commodityCodeChanged();
            }
        })
        this.setCompanySettings(event.newMode);
    }

    setupBrltlDatasources() {
        this.sourceBrltlOrderHdrXFgp.parentDataSource = this.mainDataSource;
        this.sourceBrltlOrderHdrXFgp.parentSearchMode = ParentSearchMode.onParentDisplay;

        this.sourceBrltlBillingFreightGroup.parentDataSource = this.mainDataSource;
        this.sourceBrltlBillingFreightGroup.parentSearchMode = ParentSearchMode.onParentDisplay;

        this.sourceBrltlOrderFreightGroupItem.parentDataSource = this.mainDataSource;
        this.sourceBrltlOrderFreightGroupItem.parentSearchMode = ParentSearchMode.onParentDisplay;
    }

    makeSubjectOrderAdjustments() {
        if (isSpotOrder(this.activeRow)) {
            this.textboxCustomerId.required = false;
        } else if (this.activeRow?.get("subject_order_status") == null) {
            this.tabSpotOrder.visible = false;
        }
    }

    enforceActionPermissions(component?: Layout | TableRow) {
        const isUpdateOrdersAfterBilledDenied: boolean = PermissionsUtil.isUserDeniedAction("Dispatch.Update orders after billed");
        const isBilled: boolean = !this.mainDataSource.activeRow.isNull("bill_date");
        const isTransferred: boolean = !this.mainDataSource.activeRow.isNull("xferred2billing_dt");

        const isUpdateOrdersAfterDeliveredDenied: boolean = PermissionsUtil.isUserDeniedAction("Dispatch.Update orders after delivery");
        const isDelivered: boolean = this.mainDataSource.activeRow.get("status", null) == "D";

        if ((isUpdateOrdersAfterBilledDenied && (isBilled || isTransferred)) ||
            (isUpdateOrdersAfterDeliveredDenied && isDelivered)) {
            const ignoreList = ['layoutImageTable', 'tableImages', 'buttonMoveDetails', 'tabsetAddOrders', 'tabsetStopNotesRefNos'];
            const rootComponent = component ?? this;
            rootComponent.forEveryChildComponent(comp => {
                if (ignoreList.includes(comp.id))
                    return;
                comp.enabled = false;
                if (comp instanceof Table) {
                    const nestedTable: Table = comp as Table
                    nestedTable.addRowDisplayListener((event: TableRowDisplayEvent) => {
                        const row: TableRow = (event as TableRowDisplayEvent).getTableRow();
                        row.forEveryChildComponent(child => {
                            child.enabledDuringUpdate = false;
                        });
                    });
                }
            });
        }
    }

    doBeforeModeChange(event) {
        this.hideSuffix = event.newMode != DataSourceMode.ADD
        this.panelDataHeaderAddlLeft.visible = event.newMode === DataSourceMode.UPDATE || event.newMode === DataSourceMode.ADD || event.newMode === DataSourceMode.SEARCH;
        if (event.newMode === DataSourceMode.SEARCH) {
            for (let x = this.panelDataHeaderAddlLeft.components.length - 1; x >= 0; x--) {
                const component = this.panelDataHeaderAddlLeft.components[x];
                if (component !== this.buttonUsers)
                    component.visible = false;
            }
        }
        this.panelDataHeaderUpdateOnly.visible = event.newMode === DataSourceMode.UPDATE;
        if (event.newMode === DataSourceMode.UPDATE) {
            this.title = "Order Number " + this.mainDataSource.activeRow?.get("id");
        } else if (event.newMode === DataSourceMode.ADD) {
            if (this["create_spot"]) {
                this.title = "New Spot Order";
                this.hideSuffix = true;
            }
        }

        this.tabOverview.visible = event.newMode === DataSourceMode.UPDATE || event.newMode === DataSourceMode.NONE;
        this.tabBackOffice.visible = event.newMode !== DataSourceMode.ADD;
        this.tabLoadBoardDetails.visible = event.newMode !== DataSourceMode.ADD;
        this.tabDataFusion.visible = event.newMode !== DataSourceMode.ADD;
        this.tabNextActions.visible = event.newMode === DataSourceMode.ADD;
        this.tabStops.visible = event.newMode !== DataSourceMode.SEARCH;
        this.imageReorder.visible = event.newMode !== DataSourceMode.ADD;
    }

    private async addStopsToTable() {
        if (this.stopsTable.dataSource.data?.length === 0) {
            await this.addNewStopToTable();
            await this.addNewStopToTable();
            log.debug("stops length " + this.stopsTable.dataSource.data.length)
        }
        this.tabsetAddOrders.expandAll();
    }

    public async addNewStopToTable(): Promise<TableRow> {
        let addRowResult: TableAddRowResult;
        const newRowData = await this.stopsTable._createNewRowData();
        if (newRowData != null) {
            newRowData.set("stop_type", this.stopsTable.data.length > 0 ? "SO" : "PU");
            addRowResult = this.stopsTable.addRow(newRowData, { mode: TableRowMode.ADD }, {
                display: true,
                addToData: true
            });
        }
        return addRowResult.row;
    }

    /** This is an event handler for the onChange event of textboxCommodityId.  */
    textboxCommodityIdOnChange(event) {
        this.commodityCodeChanged();
    }

    commodityCodeChanged() {
        if (this.activeRow != null) {
            const commodityCleared = this.activeRow.isNull("commodity_id");
            const lookupData = commodityCleared ? null : this.textboxCommodityId.getFirstLookupModelData();
            const hazmatModelRow = commodityCleared ? null : new ModelRow(this.textboxHazmatCode.lookupModel, false, { id: lookupData?.get("hazmat_number") });

            this.showHazmatWarning(lookupData?.get("is_hazmat"));
            this.activeRow.setLookupModelData("hazmat_code", hazmatModelRow);
            this.activeRow.set("hazmat_code", lookupData?.get("hazmat_number"));
            this.activeRow.set("commodity", lookupData?.get("descr"));
            this.activeRow.set("hazmat", lookupData?.get("hazmat", "N"));
            this.activeRow.set("temperature_min", lookupData?.get("temperature_min"));
            this.activeRow.set("temperature_max", lookupData?.get("temperature_max"));
            this.activeRow.set("placard_required", lookupData?.get("placard_required", null));
        }
    }

    getBRLTLData() {
        const orderData: BRLTLOrder = {
            order_type_id: this.activeRow.get("order_type_id"),
            revenue_code_id: this.activeRow.get("revenue_code_id"),
            order_mode: this.activeRow.get("order_mode")
        }
        return orderData
    }

    showHazmatWarning(hazmat: string) {
        this.panelCommodityHazmat.borderWidth = hazmat === "Y" ? 1 : 0;
        this.panelCommodityHazmat.borderColor = hazmat === "Y" ? "error" : "";
        this.labelHazmatCommodity.visible = hazmat === "Y";
    }

    textboxAppliesToOnChange(event: ChangeEvent) {
        if (!event.userInitiatedChange) return;
        const appliesTo: Textbox = event.target as Textbox;
        const equipType = TableRow.getContainingTableRow(appliesTo).findComponentById("textboxMatchEquipmentTypeId") as Textbox;
        equipType.text = null;
        equipType.updateBoundData(equipType.boundRow, this.mainDataSource.mode);
    }

    textboxMatchEquipmentTypeIdBeforeLookupModelSearch(event: LookupModelSearchEvent) {
        event.filter.applies_to = "in Y,X,D";
    }

    buttonAddAnotherStopOnClick(event: ClickEvent) {
        this.addNewStopToTable().then(row => row.scrollIntoView());
    }

    buttonAddAnotherStopOnDataDisplay(event: DataDisplayEvent) {
        (event.target as Button).setProps({ ...(event.target as Button).allProps, "color": "primary" });
    }

    //** This is an event handler for stopsTable onRowBeforeSave */
    tableStopBeforeRowSave(event: TableRowBeforeSaveEvent) {
        const tableRow: TableRow = event.target as TableRow;
        const modelRow: ModelRow = tableRow.data;
        const orderStopLayout: OrderStop = event.target["orderStop"] as OrderStop;
        modelRow.set("stop_notes", orderStopLayout.commentsAndRefNbrs.tableStopComments?.data || []);
        modelRow.set("stop_reference_numbers", orderStopLayout.commentsAndRefNbrs.tableStopReferenceNumbers?.data || []);
        const changedData = modelRow.getChangedData();
        if (apptConfirmCheck(changedData, modelRow))
            orderStopLayout.getAppointmentChangedRow();
        else {
            modelRow.set("appointment_change", null);
        }
    }

    textboxRecurringOrderIdBeforeLookupModelSearch(event: LookupModelSearchEvent) {
        event.filter.is_active = "Y";
    }

    /** This is an event handler for the onRowDisplay event of stopsTable.  */
    stopsTableOnRowDisplay(event) {
        const tableRow: TableRow = event.getTableRow();
        tableRow.canBeDeleted = tableRow.index != 0;
        const labelSequence = (tableRow.findComponentById("labelSequence")) as Label
        labelSequence.caption = (tableRow.index + 1).toString();
        tableRow.findComponentById((comp: any) => {
            if (comp instanceof OrderStop) {
                comp.addLayoutLoadListener(() => {
                    this.enforceActionPermissions(tableRow);
                    this.sourceStop.preventChangeNotifications = true;
                    tableRow["orderStop"] = comp;
                    labelSequence.addClickListener(event => tableRow._showEditLayout());
                    comp.calculateRates = () => this.checkStopsForDistanceChange().then(() => {
                        this.layoutRates.calculateRates(false, false)
                    });
                    comp.initialize(false, tableRow, this.sourceOrders);
                    comp.loadingRecurringOrder = this.loadingRecurringOrder;
                    comp.invokedFromEDI = this.getInvokedFromEdi();
                    if (this.getInvokedFromEdi() ) {
                        comp.setStopDateTypeForEdi();
                    }
                    comp.setFreeFormVisibility();
                    if (this.getInvokedFromEdi() || this.loadingRecurringOrder || (this["quote_id"] && !this.hasPortalContactInfo(tableRow.data))) {
                        comp.doAfterLocationIdChanged();
                    }
                    comp.displayData(tableRow.data, this.sourceStop.data, tableRow.index);
                    if (tableRow.index == 0) {
                        if (this.isBrokerageLtlCompany) {
                            this.layoutShipperPONums.initialize({
                                "stopSource": this.sourceStop,
                                "firstStopRow": tableRow.data
                            });
                        }
                        comp.switchPalletsRequired.displayData(this.sourceOrders.activeRow, null, 0);
                        comp.textboxPalletsHowMany.displayData(this.sourceOrders.activeRow, null, 0);
                        comp.marginRight = 36;
                    }
                    if (comp.commentsAndRefNbrs.enabled == false) {
                        const commentsTable = comp.commentsAndRefNbrs.tableStopComments;
                        commentsTable.allowEdit = false;
                        const tableRefNbrs = comp.commentsAndRefNbrs.tableStopReferenceNumbers;
                        tableRefNbrs.allowEdit = false;
                        comp.commentsAndRefNbrs.tabsetStopNotesRefNos.expandAll();
                    }
                    this.sourceStop.preventChangeNotifications = false;
                });
                return true;
            }
            return false;
        });
    }

    private revenueCodeChanged(newValue: any) {
        Api.search("lme/dispatch/calculate-order-mode",
            {
                "mode_locked": false,
                "revenue_code_id": newValue,
                "order_type_id": this.mainDataSource.activeRow.get("order_type_id"),
                "current_mode": this.mainDataSource.activeRow.get("order_mode")
            }).then(response => {
                const data = response.data[0];
                const newMode = data["mode"];
                this.mainDataSource.activeRow.set("order_mode", newMode);
            });
    }

    private hasPortalContactInfo(data: ModelRow){
        //This is needed to check if contact info was filled from the portal.
        //If it was filled from portal, we should not automatically update
        //if the location ID has contacts.
        return !data.isNull("phone") || !data.isNull("contact_name");
    }

    private orderTypeChanged(newValue: any) {
        Api.search("lme/dispatch/calculate-order-mode",
            {
                "mode_locked": false,
                "revenue_code_id": this.mainDataSource.activeRow.get("revenue_code_id"),
                "order_type_id": newValue,
                "current_mode": this.mainDataSource.activeRow.get("order_mode")
            }).then(response => {
                const data = response.data[0];
                const newMode = data["mode"];
                this.mainDataSource.activeRow.set("order_mode", newMode);
            });
    }

    displayOrderUsersSlideout() {
        const urs = new UsersResponsibilitySlideoutTrans("lme/dispatch/OrderUsers", this.getOrderUsersSlideoutTitle(), this.getOrderUsersIdFilter(),
            this.getOrderUsersSharedFieldNames(), this.sourceOrders, this.sourceResponsibleHist);
        urs.show();
    }

    private getOrderUsersSlideoutTitle(): string {
        const name = this.sourceOrders.activeRow?.get("id");
        return "Order Users" + ((StringUtil.isEmptyString(name) === false) ? (" - " + name) : "");
    }

    private getOrderUsersIdFilter(): any {
        const id = this.sourceOrders.activeRow?.get("id");
        if (id != null)
            return { id: id };
        return null;
    }

    private getOrderUsersSharedFieldNames(): string[] {
        return ["operations_user", "agent_payee_id", "entered_user_id", "billing_user_id"];
    }

    /** This is an event handler for the beforeExecution event of sourceLocation.  It's gross. */
    sourceOrdersAfterGetDataboundValues(row: ModelRow, dataSource: DataSource) {
        if (dataSource.mode === DataSourceMode.SEARCH) {
            //have to set these fields when in search mode because they aren't bound fields for the main layout
            for (const field of this.getOrderUsersSharedFieldNames()) {
                row.set(field, dataSource.searchRow.get(field));
            }

            //have to add hierarchy search data to the filter manually, since there are no bound components for those records
            if (ArrayUtil.isEmptyArray(this.sourceResponsibleHist.data))
                return;
            row.addLinkedModel({ model: this.sourceResponsibleHist.url, rows: [this.sourceResponsibleHist.data[0]] });
        }

        if (!StringUtil.isEmptyString(dataSource.activeRow.get("override_posting_city_id"))) {
            this.setPostingCityState(dataSource.activeRow.get("override_posting_city_id"));
        }
    }

    private setPostingCityState(cityId: string) {
        Model.searchSingleRecord("common/city", { id: cityId }).then(
            (cityRow: ModelRow) => {
                if (cityRow) {
                    const defaultData = new ModelRow(this.sourceOrders.layoutName, false, {
                        override_posting_city_id: cityRow.get("id"),
                        name: cityRow.get("name"),
                        state_id: cityRow.get("state_id"),
                        zip_code: cityRow.get("zip_code")
                    });
                    this.sourceOrders.activeRow.set(
                        "override_posting_city_id",
                        cityRow.get("id")
                    );
                    this.postingCityState.displayData(defaultData, null, 0);
                    this.postingCityState.tooltipCallback =
                        this.postingCityState.makeCityStateTooltip(
                            cityRow.get("id"),
                            this.postingCityState
                        );
                }
            }
        );
    }

    private displayPostToLoadBoardSlideout(originStopId: string, stopInfo: ModelRow) {
        const id = this.mainDataSource.activeRow?.data?.["id"];
        if (id) {
            const layout = Layout.getLayout("lme/dispatch/PostToLoadBoards", {
                maxWidth: 900,
                backgroundColor: "defaultBackground",
                fillHeight: true,
                paddingBottom: 50
            }) as PostToLoadBoards;

            const erd = new EditRowDecorator({
                title: "Post to Load Boards - Order " + id,
                layout: layout,
                fillVerticalSpace: true,
                width: 900,
                overlayProps: { greyedBackground: true },
                onSave: () => {
                    erd.multiButton.busy = true;

                    layout
                        .postDataSources()
                        .finally(() => (erd.multiButton.busy = false));
                },
                onClose: () =>
                    layout.slideOut().then(() => {
                        if (!this._enableNextActions) {
                            this.displayOrderCreatedToast();
                        }
                    }),
                doAfterSlideIn: () => {
                    layout.mainDataSource.setRowsAndMode(DataSourceMode.UPDATE, [
                        this.mainDataSource.data[0]
                    ]);
                    if (stopInfo) {
                        layout.setDefaultsByStop(stopInfo);
                    } else {
                        layout.setDefaultById(originStopId);
                    }
                }
            });
        }
    }

    textboxPnnLoadboardUserIdBeforeLookupModelSearch(event) {
        event.filter.web_user_type = "U";
    }

    switchRecurringOrderOnChange(event) {
        this.textboxRecurringOrderId.visible = this.switchRecurringOrder.checked;
        this.textboxRecurringOrderId.visibleDuringAdd = this.switchRecurringOrder.checked;
        this.textboxRecurringOrderId.enabledDuringAdd = this.switchRecurringOrder.checked;
        if (this.mainDataSource.mode == DataSourceMode.ADD && !this.mainDataSource.activeRow?.isNull("blnum") && !this.switchRecurringOrder.checked)
            this.checkForDuplicateBOL(DuplicateBOLSource.ADD);
    }

    public static navigateTo(id: string, newTab = true): void {
        Navigation.navigateTo(viewOrderURL + id, { newTab: newTab });
    }

    private postOrderActionButtonClick(event: ClickEvent) {
        const button = event.target as Button;
        this.panelNextActionButtons.forEveryChildComponent((component) => {
            if (component._element.nodeName === "BUTTON") {
                component.color = "subtle.darker";
                component.backgroundColor = "primary.reverse"
            }
        });
        button.color = "primary.reverse";
        button.backgroundColor = "primary";
    }

    /** This is an event handler for the onClick event of buttonAssignCarrier.  */
    buttonAssignCarrierOnClick(event: ClickEvent) {
        this.postOrderAction = this._assignCarrierURL;
        if (this.switchPostToLoadBoards.checked)
            this.switchPostToLoadBoards.showTooltip("Post to load board unavailable when Assign Carrier is selected for the next action.", {
                warning: true,
                timeout: 6000
            });
        this.switchPostToLoadBoards.checked = false;
        this.postOrderActionButtonClick(event);
    }

    /** This is an event handler for the onClick event of buttonFindCarrier.  */
    buttonFindCarrierOnClick(event: ClickEvent) {
        this.postOrderAction = this._findCarrierURL;
        this.postOrderActionButtonClick(event);
    }

    async buttonCarrierRatesOnClick(event: ClickEvent) {
        if (await CarrierSelection.shouldRerate(this, false)) {
            Snackbar.showWarningSnackbar("Order has unsaved changes. Please save before viewing carrier rates.");
        }
        else {
            this.showCarrierSelectionSlideout()
        }
    }

    /** This is an event handler for the onClick event of buttonEnterNewOrder.  */
    buttonEnterNewOrderOnClick(event: ClickEvent) {
        this.postOrderAction = this.newOrderURL;
        this.postOrderActionButtonClick(event);
    }

    /** This is an event handler for the onClick event of buttonViewOrder.  */
    buttonViewOrderOnClick(event: ClickEvent) {
        this.postOrderAction = viewOrderURL;
        this.postOrderActionButtonClick(event);
        if (this.howManyOrders.value > 1)
            this.postOrderAction = this.viewOrderListURL;
        else
            this.postOrderAction = this._viewOrderURL;
    }

    /** This is an event handler for the onChange event of howManyOrders.  */
    howManyOrdersOnChange(event: ChangeEvent) {
        if (event.newValue > 1 && (this.postOrderAction === this._findCarrierURL || this.postOrderAction === this._assignCarrierURL))
            this.buttonViewOrder.clicked();
        else if (event.newValue > 1 && this.postOrderAction === this._viewOrderURL)
            this.postOrderAction = this.viewOrderListURL;
        else if (event.newValue == 1 && this.postOrderAction === this.viewOrderListURL)
            this.postOrderAction = this._viewOrderURL;

        this.buttonAssignCarrier.enabled = event.newValue <= 1;
        this.buttonAssignCarrier.disabledTooltip = event.newValue > 1 ? "Assign carrier action unavailable when orders to add is greater than 1" : "";
        this.buttonFindCarrier.enabled = event.newValue <= 1;
        this.buttonFindCarrier.disabledTooltip = event.newValue > 1 ? "Find carrier action unavailable when orders to add is greater than 1" : "";
        this.switchPostToLoadBoards.checked = false;
        this.switchPostToLoadBoards.enabled = event.newValue <= 1;
        this.switchPostToLoadBoards.disabledTooltip = event.newValue > 1 ? "Post to load boards unavailable when orders to add is greater than 1" : "";
    }

    stopTableOnEditLoaded(): (rowDecorator: EditRowDecorator, tableRow: TableRow) => void {
        return (rowDecorator: EditRowDecorator, tableRow: TableRow) => {
            const thisOrderStop = tableRow["orderStop"] as OrderStop;
            const editOrderStop = rowDecorator.layout as OrderStop;
            if (this.sourceOrders.isAddingOrUpdating()) {
                editOrderStop.commentsAndRefNbrs.tableStopComments.dataSource.mode = DataSourceMode.UPDATE;
                editOrderStop.commentsAndRefNbrs.tableStopReferenceNumbers.dataSource.mode = DataSourceMode.UPDATE;
            }
            editOrderStop.mainDataSource.activeRow?.set("stop_notes", thisOrderStop?.commentsAndRefNbrs.tableStopComments.data);
            editOrderStop.mainDataSource.activeRow?.set("reference_numbers", thisOrderStop?.commentsAndRefNbrs.tableStopReferenceNumbers.data);
            editOrderStop.initialize(true, tableRow, this.sourceOrders);
            editOrderStop.slideOutTableRow = tableRow;

            rowDecorator.onSave = this.stopSlideoutOnSave(rowDecorator, tableRow);
            rowDecorator.btnDelete.visible = false;
            rowDecorator.layout.width = window.innerWidth * .75;
            rowDecorator.labelTitle.caption = "Order Entry Stop Details - " + this.title;
            rowDecorator.multiButton.dropdownItems = null;
        };
    }

    stopSlideoutOnSave(rowDecorator: EditRowDecorator, tableRow: TableRow): (updatedData: ModelRow | any) => void {
        const orderStop = (rowDecorator.layout as OrderStop);
        const thisOrderStop = tableRow["orderStop"] as OrderStop;
        return async (updatedData: ModelRow | any) => {
            thisOrderStop.textboxLocationId["_lookupModelDataForUpdate"] = orderStop.textboxLocationId["_lookupModelDataForUpdate"]; // syncs lookupModelDataForUpdate which is used in updateBoundData in Location.ts
            tableRow.saveChangesFromEdit(updatedData);
            if (tableRow.index == 0) {
                this.sourceOrders.activeRow.set("pallets_how_many", orderStop.textboxPalletsHowMany.text);
                this.sourceOrders.activeRow.set("pallets_required", true === orderStop.switchPalletsRequired.checked ? "Y" : "N");
            }
            orderStop.commentsAndRefNbrs.saveChangesFromEdit(tableRow["orderStop"].commentsAndRefNbrs);
            thisOrderStop.textboxLocationId.displayData(tableRow.data, null, 0);
            thisOrderStop.setLocationTextAfterLocation(thisOrderStop.textboxLocationId.textCombined, tableRow.data, "location_id");
            thisOrderStop.calculateRates();
            thisOrderStop.activeRow.set("initial_appointment_change", null); // reset in scenario if user exits slideout and makes another update on main screen
        };
    }

    viewLoadTenders() {
        const id = this.mainDataSource.activeRow?.data?.["id"];
        log.debug("id", id);
        if (id) {
            const layout = Layout.getLayout("lme/datafusion/OrderLoadTenderHistory", {
                maxWidth: 900,
                width: window.innerWidth * .60,
                backgroundColor: "defaultBackground",
                fillHeight: true,
                paddingBottom: 50
            }) as OrderLoadTenderHistory;

            new SlideoutDecorator({
                layout: layout,
                title: "Tenders - Order " + id,
                fillVerticalSpace: true,
                doAfterSlideIn: (decorator: SlideoutDecorator) => layout.mainDataSource.search({ order_id: id })
            });

            layout.orderId = id;
        }
    }

    viewShipmentStatus() {
        const id = this.mainDataSource.activeRow?.data?.["id"];
        if (id) {
            const layout = Layout.getLayout("lme/dispatch/ShipmentStatusSlideout", {
                //maxWidth: 900,
                width: window.innerWidth * .70,
                backgroundColor: "defaultBackground",
                fillHeight: true,
                paddingBottom: 50
            }) as ShipmentStatusSlideout;

            new SlideoutDecorator({
                layout,
                title: `Order Number ${id}`,
                fillVerticalSpace: true,
                addlComponents: [layout.saveButton],
                onClose: () => {
                    this.mainDataSource.search({ id: id });
                },
                doAfterSlideIn: (decorator: SlideoutDecorator) => {
                    layout.tabset1.selectedIndex = layout.tabset1.indexOf(layout.tabOutbound);
                    layout.sourceOrderEdistatus.search({ order_id: id });
                    layout.sourceEdiOrder.search({ order_id: id });
                    layout.saveButton.addClickListener(event => {
                        if (decorator.validateSimple()) {
                            const dataLayout = layout.outboundDataSource.data;
                            dataLayout?.forEach((data, index) => {
                                if (data.hasChanged()) {
                                    layout.outboundDataSource.data[index].post();
                                }
                            });
                        }
                    });
                },
                doAfterSlideOut: (decorator: SlideoutDecorator) => {
                    this.mainDataSource.search({ id: id });
                }
            });
        }
    }

    set postOrderAction(value: string | (() => void)) {
        this._postOrderAction = value;
    }

    get postOrderAction(): string | (() => void) {
        return this._postOrderAction;
    }

    set viewOrderUrl(value: string) {
        this._viewOrderURL = value;
    }

    get viewOrderUrl(): string {
        return this._viewOrderURL;
    }

    set findCarrierURL(value: string) {
        this._findCarrierURL = value;
    }

    get findCarrierURL(): string {
        return this._findCarrierURL;
    }

    public set assignCarrierURL(value) {
        this._assignCarrierURL = value;
    }

    public get assignCarrierURL() {
        return this._assignCarrierURL;
    }

    /** This is an event handler for the onChange event of switchPostLoadboard.  */
    switchPostLoadboardOnChange(event: ChangeEvent) {
        if (event.userInitiatedChange) {
            const switchPostLoadboard = event.target as Switch;
            const onHold = this.mainDataSource.activeRow.get("on_hold");
            if (event.newValue && this.postOrderAction === this._assignCarrierURL)
                this.buttonFindCarrier.clicked();
            if (switchPostLoadboard != null && switchPostLoadboard.checked && onHold == "Y")
                this.createPostToLoadBoardDialog().then(() => switchPostLoadboard.checked = false);
        }
    }

    createPostToLoadBoardDialog() {
        const dialog = new Dialog({ okVisible: true });
        const lblCaptionLine1 = new Label({
            caption: "Orders on hold may not be posted to the load boards.",
            align: HorizontalAlignment.CENTER,
            fontSize: "large"
        });
        const lblCaptionLine2 = new Label({
            caption: "Remove the hold to post this order.",
            align: HorizontalAlignment.CENTER,
            fontSize: "large"
        })
        const pnlCaption = new Panel({
            marginRight: 8,
            verticalAlign: VerticalAlignment.TOP,
            fillHeight: true,
            rowBreak: false
        })
        pnlCaption.add(lblCaptionLine1, lblCaptionLine2);

        const pnlContainer = new Panel({ fillRow: true, maxWidth: 550 });
        pnlContainer.add(pnlCaption);
        dialog.add(pnlContainer);
        return dialog.show();

    }

    createUpdateDeniedDialog() {
        const dialog = new Dialog({ okVisible: true });
        const lblCaptionLine1 = new Label({
            caption: "Order has been billed and you do not have permission to update an order " +
                "after the order has been billed",
            align: HorizontalAlignment.CENTER,
            fontSize: "large"
        });
        const pnlCaption = new Panel({
            marginRight: 8,
            verticalAlign: VerticalAlignment.TOP,
            fillHeight: true,
            rowBreak: false
        });
        pnlCaption.add(lblCaptionLine1);
        const pnlContainer = new Panel({ fillRow: true, maxWidth: 550 });
        pnlContainer.add(pnlCaption);
        dialog.add(pnlContainer);
        return dialog.show();
    }

    buttonOpenClassicOnClick(event: ClickEvent) {
        McLeodClassicIntegration.openClassicScreen("com.tms.client.loadmaster.dsp.EntryOrder", this.mainDataSource.activeRow.get("id"));
    }

    /** This is an event handler for the onClick event of buttonReleaseToBilling.  */
    buttonReleaseToBillingOnClick(event: ClickEvent) {
        const order_id = this.activeRow?.get("id");
        if (order_id) {
            Api.post("lme/powerbroker/release-to-billing", { order_id }).then(output => {
                const success = output.data[0].success;
                const error_message = output.data[0].error_message;
                if (success) {
                    Toast.showSuccessToast("Released to Billing", "Order " + order_id);
                    this.activeRow.set("ready_to_bill", "Y");
                    const setBolReceived = getDispatchControlBoolean("set_bol_received", false);
                    if (setBolReceived)
                        this.activeRow.set("bol_received", "Y");
                    this.activeRow.set("billing_user_id", getUserSettings().user_id);
                    this.activeRow.setLookupModelData("billing_user_id", new ModelRow("common/users", false, {
                        id: getUserSettings().user_id,
                        name: getUserSettings().user_name
                    }));
                    this.textboxBillingUserId.displayData(this.activeRow, null, 0);
                } else {
                    Toast.showToast(error_message);
                }
            });
        }
    }

    private checkReleaseToBilling() {
        const status = this.activeRow.get("status");
        let disabledTooltipMessage = "";
        if (isSpotOrder(this.activeRow)) {
            disabledTooltipMessage = "Release to billing is not available for spot orders.";
        } else if (status != "D" && status != "P") {
            disabledTooltipMessage = "Cannot release to billing: Order status must be in progress or delivered.";
        } else if (this.activeRow.get("xferred2billing", false) || !this.activeRow.isNull("xferred2billing_dt") || !this.activeRow.isNull("bill_date")) {
            disabledTooltipMessage = "Order has already been transferred to billing.";
        }
        if (disabledTooltipMessage.length > 0) {
            this.buttonReleaseToBilling.enabled = false;
            this.buttonReleaseToBilling.disabledTooltip = disabledTooltipMessage;
            this.buttonReleaseToBilling.tooltip = null;
        }
    }

    private _setEquipTypeOptions() {
        if (this.mainDataSource.activeRow.get("show_equip_type_options", null)) {
            this.checkboxExpedited.visible = true;
            this.checkboxTarps.visible = true;

            const options = this.mainDataSource.activeRow?.get("equipment_type_options");
            if (options != null) {
                const splitOptions = options.split("|");
                for (let i = 0; i < splitOptions.length; i++) {
                    if (equipmentTypeOptionsMap.expedited === splitOptions[i]) {
                        this.checkboxExpedited.checked = true;
                    } else if (equipmentTypeOptionsMap.tarps === splitOptions[i]) {
                        this.checkboxTarps.checked = true;
                    }
                }
            }
        } else {
            this.checkboxExpedited.visible = false;
            this.checkboxTarps.visible = false;
        }
    }

    /** This is an event handler for the onClick event of buttonSendBOL.  */
    async buttonSendBOLOnClick(event: ClickEvent) {
        const shipper = this.sourceStop.data[0];
        BillOfLading.showBillOfLadingSlideout({
            order_id: this.activeRow.get("id"),
            customer_id: this.activeRow.get("customer_id"),
            _lookup_customer_id: this.activeRow.get("_lookup_customer_id"),
            shipperLocationId: shipper.get("location_id", null),
            bol_note: this.activeRow.get("bol_note", ""),
            onError: (message: string) => Snackbar.showWarningSnackbar(message)
        });
    }

    public get doAfterAction(): () => void {
        return this._doAfterAction;
    }

    public set doAfterAction(value: () => void) {
        this._doAfterAction = value;
    }

    private executeDoAfterAction() {
        if (this.doAfterAction != null)
            this.doAfterAction();
    }

    createRecurringOrder(event: ClickEvent) {
        const orderId = this.mainDataSource.activeRow?.get("id");
        if (orderId == null) return;

        function makeApiCall(userEnteredRecurringOrderId?: String) {
            Api.post("lme/dispatch/create-recurring-order", {
                order_id: orderId,
                recurring_order_id: userEnteredRecurringOrderId
            }).then(output => {
                const recurringOrderId = output.data[0].recurring_order_id;
                const toastMessage = `Recurring Order ${recurringOrderId} has been created.`;
                Toast.showSuccessToast(toastMessage);
            }).catch(error => {
                for (const message of error.messages) {
                    if (message.includes("already exists in the table")) {
                        Snackbar.showWarningSnackbar("Duplicate recurring order ID");
                        return;
                    }
                }
                Snackbar.showWarningSnackbar(error.messages[0]);
            });
        }

        if (getDispatchControlBoolean("recurring_override", false)) {
            const dialogLayout = Layout.getLayout("lme/dispatch/RecurringOrderPrompt") as RecurringOrderPrompt;
            CommonDialogs.showYesNo(dialogLayout, "Create Recurring Order", {
                yesButtonCaption: "OK",
                noButtonCaption: "Cancel"
            }, false, 150).then(clickedYes => {
                if (clickedYes) {
                    const userEnteredRecurringOrderId: String = dialogLayout.textboxRecurringOrderId.text;
                    makeApiCall(userEnteredRecurringOrderId);
                }
            });
        } else {
            makeApiCall();
        }
    }

    convertSpotToOrder(event: ClickEvent) {
        if ("C" == this.mainDataSource.activeRow.get("subject_order_status")) {
            Snackbar.showWarningSnackbar("This spot order has already been converted into an order.", { targetPanel: this });
            return;
        }
        if (this.activeRow.get("customer_id") == null) {
            this.textboxCustomerId.required = true;
            this.textboxCustomerId.validateSimple();
            this.textboxCustomerId.required = false;
            Snackbar.showWarningSnackbar("A customer is required to convert this spot order to an order", { targetPanel: this });
            return;
        }

        Api.post("lme/dispatch/convert-spot-to-order", { spot_id: this.activeRow.get("id") }).then(output => {
            const errorReason = output.data[0].error_reason;
            if (errorReason != null) {
                Snackbar.showWarningSnackbar(errorReason, { targetPanel: this });
            } else {
                const orderId = output.data[0].order_id;
                this.mainDataSource.search({ id: this.activeRow.get("id") }).then(() => {
                    const toast = Toast.showToast(this.getOrderCreatedToast(orderId, "created", () => this.orderToastLinkOnClick(orderId, toast)), null);
                });
            }
        });
    }

    voidOrder(event: ClickEvent) {
        if ("V" == this.mainDataSource.activeRow.get("status")) {
            Snackbar.showWarningSnackbar("This order is already void.", { targetPanel: this });
            return;
        }
        const orderId = this.mainDataSource.activeRow?.get("id");
        if (orderId != null) {
            const voidOrderPromptLayout = Layout.getLayout("lme/dispatch/VoidOrderPrompt", {
                backgroundColor: "defaultBackground",
                borderRadius: 4, borderWidth: 1, borderShadow: true, borderColor: "strokeSecondary"
            }) as VoidOrderPrompt

            voidOrderPromptLayout.orderId = orderId
            voidOrderPromptLayout.addLayoutLoadListener(event => {
                voidOrderPromptLayout.onCancel = () => Overlay.hideOverlay(overlay);
                voidOrderPromptLayout.onSave = (success: boolean) => {
                    Overlay.hideOverlay(overlay);
                    if (success) {
                        Navigation.reloadCurrentPage(false).then(() => {
                            const toast = Toast.showToast(this.getOrderCreatedToast(orderId, "voided", () => this.orderToastLinkOnClick(orderId, toast)), null, { persist: true });
                        });
                    } else {
                        const snackMessage = `Could not void order ${orderId}`;
                        Snackbar.showWarningSnackbar(snackMessage);
                    }
                };

                const overlay = Overlay.showInOverlay(voidOrderPromptLayout, {
                    closeOnClickOff: false,
                    greyedBackground: true,
                    centered: true
                });
            });
        }
    }

    imageReorderOnClick(event: ClickEvent) {
        this._rearrangeStops();
    }

    private _duplicateOrder() {
        const orderId: string = this.mainDataSource.activeRow.get("id");
        const dialogLayout = Layout.getLayout("lme/dispatch/DuplicateOrderPrompt") as DuplicateOrderPrompt;
        dialogLayout.addLayoutLoadListener(() => {
            dialogLayout.numbereditor1.value = 1;
            if (this.isBrokerageLtlCompany) {
                dialogLayout.panelBrLTLOptions.visible = true;
                if (this.activeRow.getBoolean("is_brokerage_ltl", false)) {
                    dialogLayout.panelOrderOptions.visible = false;
                } else {
                    dialogLayout.panelOrderOptions.align = HorizontalAlignment.LEFT;
                    dialogLayout.panelBrLTLOptions.align = HorizontalAlignment.LEFT;
                    dialogLayout.panelOrderOptions.paddingLeft = dialogLayout.panelBrLTLOptions.paddingLeft = 70;
                    dialogLayout.checkboxPerItem.visible = false;
                }
            }
            dialogLayout.sourceCopyControl.search({ "copy_type": "D" });
        });
        CommonDialogs.showYesNo(dialogLayout, "Duplicate Orders", {
            yesButtonCaption: "Create Orders",
            noButtonCaption: "Cancel"
        }, false, 150).then(clickedYes => {
            if (clickedYes) {
                const howManyOrders = dialogLayout.numbereditor1.value;

                // Values are opposite to checked values because copy control tell us what to exclude not include so we need to look at the checked property itself
                const copyRate: boolean = dialogLayout.checkboxIncludeRate.checked && !this.activeRow.get("is_brokerage_ltl");
                const copyOtherCharges: boolean = dialogLayout.checkboxIncludeOtherCharges.checked && !this.activeRow.get("is_brokerage_ltl");
                const includePerItem: boolean = dialogLayout.checkboxPerItem.checked;
                const includeHandlingRequirements: boolean = dialogLayout.checkboxHandlingReq.checked;

                this.checkDuplicateBOL.validate(DuplicateBOLSource.DUPLICATE, this.saveButton)
                    .then(result => {
                        if (!result.success) {
                            CommonDialogs.showMessage(result.reason, "", this.checkDuplicateBOL.getDialogProps(true, "Duplicate BOL"))
                        } else {
                            Snackbar.showDownloadSnackbar("Duplicate Order", "Creating " + howManyOrders + " order(s).");
                            return Api.post("lme/dispatch/duplicate-order", {
                                order_id: orderId,
                                how_many: howManyOrders,
                                fail_on_credit_warning: true,
                                copy_rate: copyRate,
                                copy_other_charges: copyOtherCharges,
                                include_per_item: includePerItem,
                                include_handling_requirements: includeHandlingRequirements
                            }, null, null).then(response => {
                                const data = response.data[0];
                                const orderIds = data["duplicate_orders"];
                                const hasFailedOrders = data["has_failed_orders"];
                                const failedCredit = data["failed_credit"];
                                if (hasFailedOrders) {
                                    this.creditValidator.showCustomerCreditValidationSnackbar("Unable to create all orders. " +
                                        (failedCredit ? this.textboxCustomerId.text + " has exceeded their credit limit. " : "") +
                                        (orderIds.length) + " order(s) of " + howManyOrders + " were created.")
                                }
                                Navigation.navigateTo(this.viewOrderListURL + orderIds.toString(), { newTab: false });
                            });
                        }
                    });
            }
        });
    }

    buttonOrderHistoryOnClick(event: ClickEvent) {
        OrderPostHist.showInSlideout(
            "ORDER",
            this.mainDataSource.activeRow.get("id"),
            this.mainDataSource.activeRow.get("curr_movement_id"),
            DataSourceMode.UPDATE, this.buttonOrderHistory);
    }

    equipItemTableOnRowDisplay(event) {
        const row: TableRow = event.getTableRow();
        const equipType = (row.findComponentById("textboxMatchEquipmentTypeId")) as Textbox

        if (equipType != null) {
            equipType.onSelectItem = ((textbox, selection) => {
                equipType.boundRow.set("applies_to", (selection as ModelRow).get("applies_to"));
                return undefined;
            })
        }
    }

    public set invokedFromEdi(value: boolean) {
        this._invokedFromEdi = value;
    }

    public getInvokedFromEdi(): boolean {
        return this._invokedFromEdi;
    }

    public setEdiFields() {
        this.buttonFindCarrier.visible = true;
        this.buttonAssignCarrier.visible = true;
        this.buttonViewOrder.visible = true;
        this.switchPostToLoadBoards.visible = true;
        this.buttonCarrierRates.visible = false;
    }

    stopsTableOnContentsChanged(event: TableContentsChangedEvent) {
        if (TableAction.DELETE == event.getAction()) {
            this.checkStopsForDistanceChange().then(() => {
                this.layoutRates.calculateRates(false, false);
            });
        }
    }

    private validateRespFilteringDuringAdd(event: DataSourceValidationEvent, buttonUsers: Button) {
        if (event.dataSource.activeRow.get("rf_entry_code_required", false) !== true)
            return;
        let rfDataSource: DataSource = null;
        if (event.childDataSources != null) {
            for (const childDataSource of event.childDataSources) {
                if (childDataSource.url === "lme/general/responsible-hist") {
                    rfDataSource = childDataSource;
                    break;
                }
            }
        }
        if (rfDataSource != null) {
            for (const row of rfDataSource.data) {
                if (row.get("responsible_role", null) === "E" &&
                    (row.get("hierarchy_level_1", null) != null || row.get("hierarchy_level_2", null) != null || row.get("hierarchy_level_3", null) != null ||
                        row.get("hierarchy_level_4", null) != null || row.get("hierarchy_level_5", null) != null)) {
                    //an entry row is present, return without adding anything to the validation results
                    return;
                }
            }
        }
        const message = "A responsibility record with role 'Entry' must be specified";
        event.validationResults.push({
            isValid: false,
            validationMessage: message,
            caption: message,
            component: buttonUsers
        });
    }

    private get saveButton(): SaveButton {
        return this.dataHeader?.["saveButton"];
    }

    public displayOrderCreatedToast() {
        const orderId = this.mainDataSource?.activeRow?.get("id", null);
        const toast = Toast.showToast(this.getOrderCreatedToast(orderId, "created", () => this.orderToastLinkOnClick(orderId, toast)), null);
    }

    private orderToastLinkOnClick(orderId: string, toast: Toast) {
        Orders.navigateTo(orderId, false);
        toast.dismiss();
    }

    private getOrderCreatedToast(orderId: string, action: string, doOnLinkClick: () => void): Panel {
        const labelProps = {
            color: "white",
            fontSize: "xlarge",
            rowBreak: false,
            paddingLeft: 1,
            paddingRight: 1,
            marginLeft: 0,
            marginRight: 0
        };
        let labels: Label[] = [];
        if (!StringUtil.isEmptyString(orderId)) {
            labels = [
                new Label({ ...labelProps, caption: "Order " }),
                new Label({
                    ...labelProps,
                    style: { textDecoration: "underline" },
                    caption: orderId,
                    onClick: event => doOnLinkClick()
                }),
                new Label({ ...labelProps, caption: " " + action + "." })
            ];
        } else {
            labels = [
                new Label({ ...labelProps, caption: "Order not " + action + "." })
            ];
        }
        return new Panel({ align: HorizontalAlignment.CENTER, fillRow: true, components: labels });
    }

    imageAddHandlingReqOnClick() {
        OrderHandlingRequirementsSlideout.showSlideout({
            hdrDataSource: this.sourceBrltlOrderHdrXFgp,
            hdrChanged: (row: ModelRow, type: ModelDataChangeType) => this.hdrChanged(row, type),
            onSave: (hasChanged: boolean) => {
                this.layoutHandlingReqs.populateHdrs(this.sourceBrltlOrderHdrXFgp.data, true, hasChanged).then(() => {
                    this.sourceBrltlOrderHdrXFgp.notifyHasChangedComponents(true);
                });
            },
            invokedFromEdi: false
        })
    }

    hdrChanged(hdr: ModelRow, type: ModelDataChangeType) {
        if (type == ModelDataChangeType.ADD) {
            hdr.set("fgp_uid", this.mainDataSource.activeRow.get("fgp_uid"));
            hdr.set("order_id", this.mainDataSource.activeRow.get("id"));
            hdr._appending = true;
            this.sourceBrltlOrderHdrXFgp.addRow(hdr, this.sourceBrltlOrderHdrXFgp.data.length, false);
        } else if (type == ModelDataChangeType.DELETE) {
            this.sourceBrltlOrderHdrXFgp.data.forEach((row, index) => {
                if (row?.data["hdr_uid"] == hdr.data["hdr_uid"]) {
                    this.sourceBrltlOrderHdrXFgp.deleteTableRow(index, true);
                }
            });
        } else if (type == ModelDataChangeType.UPDATE) {
            this.sourceBrltlOrderHdrXFgp.data.forEach((row, index) => {
                if (row?.data["hdr_uid"] == hdr.data["hdr_uid"]) {
                    row.setValues({
                        "text_value": hdr.data["text_value"],
                        "integer_value": hdr.data["integer_value"],
                        "float_value": hdr.data["float_value"],
                        "time_value": hdr.data["time_value"],
                        "date_value": hdr.data["date_value"]
                    });
                }
            });
        }

    }

    enableStatusButton() {
        const orderStatus = this.mainDataSource.activeRow?.get("status");
        this.buttonInitiateStatusProcessing.enabled = (orderStatus != "Q" && orderStatus != "V" && orderStatus != "S");
    }

    async initiateStatusProcessing(event: ClickEvent): Promise<any> {
        const orderId = this.mainDataSource.activeRow?.get("id");
        if (orderId == null) return;
        this.buttonInitiateStatusProcessing.busy = true;
        const mode = this.mainDataSource.mode;
        this.mainDataSource.activeRow?.set("shipstatus2edi_dt", null);
        this.mainDataSource.displayDataInBoundComponent(this.textboxOutboundStatusCompleted);

        await this.validateProcessStatus(orderId, mode).then(async response => {
            await this.delay(3000).then(() => {
                this.sourceOrders.search({ id: orderId }).then((order) => {
                    const row = order.modelRows[0].data;
                    const statusCompletedDate = row["shipstatus2edi_dt"];
                    this.textboxOutboundStatusCompleted.text = statusCompletedDate;
                    this.mainDataSource.displayDataInBoundComponent(this.textboxOutboundStatusCompleted);
                });
            });
        });
        this.buttonInitiateStatusProcessing.busy = false;
    }

    async validateProcessStatus(orderId: string, mode: string): Promise<any> {
        return await Api.post("lme/datafusion/status-processing", { order_id: orderId, datasource_mode: mode });
    }

    async delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    sourceBrltlBillingFreightGroupAfterExecution(event: DataSourceExecutionEvent) {
        if (event.getAction() == DataSourceAction.SEARCH) {
            this.buttonCarrierRates.visible = this.activeRow.getBoolean("is_brokerage_ltl") && !this.getInvokedFromEdi();
            if (this["selectCarrier"] === true) {
                this.showCarrierSelectionSlideout();
                this["selectCarrier"] = false;
            }
        }
    }

    sourceBrltlOrderHdrXFgpAfterExecution(event: DataSourceExecutionEvent) {
        this.layoutHandlingReqs.populateHdrs(event.dataSource.data, true);
    }

    getFreightGroupItemDataSource(): DataSource {
        return this.sourceBrltlOrderFreightGroupItem;
    }

    getStopDataSource(): DataSource {
        return this.sourceStop;
    }

    getHdrXFgpDataSource(): DataSource {
        return this.sourceBrltlOrderHdrXFgp;
    }

    getBillingFreightGroupDataSource(): DataSource {
        return this.sourceBrltlBillingFreightGroup;
    }

}


