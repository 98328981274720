import {
  Button,
  ChangeEvent,
  Checkbox,
  ClickEvent,
  DataSourceExecutionEvent,
  Label,
  Layout,
  SlideoutDecorator,
  Snackbar,
  Switch,
  Toast
} from "@mcleod/components";
import { Api, ModelRow, StringUtil } from "@mcleod/core";
import {SmsMessageDetails} from "./SmsMessageDetails";
import { AutogenLayoutSendSmsMessage } from "./autogen/AutogenLayoutSendSmsMessage";

interface SMSTemplate {
    order_number: string;
    street_addresses: string;
    schedule_arrival_times: string;
    contact_information: string;
    comments: string;
    reference_numbers: string;
    directions: string;
    directions_disclaimer: string;
    distance_between_stops: string;
    office_phone: string;
    office_cell: string;
    office_email: string;
    do_not_call_notice: string;
    city_state_zip: string;
    request_link: string;
    request_info_header:string;
}

export class SendSmsMessage extends AutogenLayoutSendSmsMessage {
  private _movementId: string;
  private _carrierId: string;
  private _orderId: string;
  private _messageId:string;
  private _messageText: string;
  private _includeStopDetails = false;
  private _includeArrivalTimes = false;
  private _includeContactInformation = false;
  private _includeCommentInformation = false;
  private _includeReferenceNumbers = false;
  private _includeDirections = false;
  private _includeDirectionsDisclaimer = false;
  private _includeDistanceBetweenStops = false;
  private _includeDistanceBetweenDirections = false;
  private _includeSenderOfficeNum = false;
  private _includeSenderCellNumber= false;
  private _includeSenderEmail = false;
  private _includeDoNotCall = false;

  public buttonSend = new Button({
    rowBreak: false,
    backgroundColor: "primary",
    color: "primary.reverse",
    caption: "Send Message",
    onClick: (event: ClickEvent) => this.sendSmsMessage()
  })

  constructor(props?) {
    super(props);
    this._movementId = props.movementId;
    this._carrierId = props.carrierId;
    //this._orderId = props.orderId;
    // this._messageText = props.messageText;
    // this._messageId = props.messageId;
  }

  override onLoad(): void {
    this.textboxDriverName.onSelectItem = (textbox, selectedItem: ModelRow) => {
      const driverName = textbox.getDataValue();

      Api.post("lme/powerbroker/obtain-phone-number", { "movement_id": this._movementId, "driver_name": driverName })
        .then(response => {
          if (!StringUtil.isEmptyString(response.data[0]?.phone_number)) {
            this.activeRow.set({ cell_phone_number: response.data[0]?.phone_number });
          }
          if (StringUtil.equalsIgnoreCase(response.data[0]?.is_sms_active, "Y")) {
            this.buttonSend.enabled = true;
            this.buttonSend.tooltip = "Send Message";
          } else {
            this.buttonSend.enabled = false;
            Snackbar.showWarningSnackbar("Driver must be opted in for SMS messages.");
          }
        });

      this.getDriverPhoneNumber(driverName, this._movementId).then(result => {
        if (!StringUtil.isEmptyString(result)) {
          this.activeRow.set({ cell_phone_number: result });
        }
      });
      return selectedItem.data.id;
    }
    this.textboxDriverName.addBeforeLookupModelSearchListener(event => {
      event.filter.carrier_id = this._carrierId;
      event.filter.is_active = "Y";
      event.filter.is_sms_opted_in = "Y";
    });

    this.getTemplateFieldData(this._movementId);

    this.layoutSmsMessageDetail.mainDataSource.addBeforeExecutionListener((event: DataSourceExecutionEvent) => event.filter.movement_id = this._movementId);
    this.layoutSmsMessageDetail.mainDataSource.search({movementId: this._movementId});
   // this.layoutSmsMessageDetail.mainDataSource.search();
  }

  static showEditRowDecorator(movementId: string, carrierId: string, driverName: string, overrideDriverCell: string) {
    const layout = Layout.getLayout("lme/dispatch/SendSmsMessage", { movementId: movementId, carrierId: carrierId } as any) as SendSmsMessage;

    const slideoutDecorator = new SlideoutDecorator({
      title: "Send SMS Message",
      layout: layout,
      fillVerticalSpace: true,
      width: 1200,
      overlayProps: { greyedBackground: true },
      addlComponents: layout.buttonSend,
      doAfterSlideIn: async (decorator: SlideoutDecorator) => {
        this.setDefaultSmsFields(layout, overrideDriverCell, carrierId, driverName);
      },
      onClose: (cancelled: boolean) => layout.slideOut()
    });
  }


  static setDefaultSmsFields(layout: any, overrideDriverCell: string, carrierId: string, driverName: string): void {
    layout.sourceCarrierDriver.search({ carrier_id: carrierId }).then(() => {
      const data = layout.sourceCarrierDriver.data;
      for (const row of data) {
        if (StringUtil.equalsIgnoreCase(row.get("driver_name"), driverName) && !StringUtil.isEmptyString(overrideDriverCell)) {
          layout.setDefaultFields(row.get("driver_name"), overrideDriverCell, row.get("is_active"), row.get("is_sms_opted_in"));
        } else if (StringUtil.equalsIgnoreCase(row.get("driver_name"), driverName)) {
          layout.setDefaultFields(driverName, row.get("cell_phone_number"), row.get("is_active"), row.get("is_sms_opted_in"));
        }
      }
    });
  }

  async setDefaultFields(driver_name: string, driver_cell: string, is_active: string, is_sms_opted_in: string) {
    this.mainDataSource.activeRow.set("driver_name", driver_name);
    this.textboxDriverName.text = driver_name;
    this.mainDataSource.activeRow.set("cell_phone_number", driver_cell);
    if (StringUtil.equalsIgnoreCase(is_active, "N")) {
      Snackbar.showWarningSnackbar("Driver is not active.");
      this.buttonSend.enabled = false;
      this.buttonSend.tooltip = "Driver must be active to recieve SMS messages.";
    } else if (StringUtil.equalsIgnoreCase(is_sms_opted_in, "N")) {
      Snackbar.showWarningSnackbar("Driver is opted out of SMS.");
      this.buttonSend.enabled = false;
      this.buttonSend.tooltip = "Driver must be opted in for SMS messages.";
    }

  }

  async sendSmsMessage() {
    const phoneNumber = this.textboxCellPhoneNumber.text;
    const messageText = this.smsMessageText.text;
    const driverName = this.textboxDriverName.text;
    const movementId = this._movementId;

    if (StringUtil.isEmptyString(driverName)) {
      Snackbar.showWarningSnackbar("Please provide a driver name.");
    } else if (StringUtil.isEmptyString(phoneNumber)) {
      Snackbar.showWarningSnackbar("Please provide a phone number.");
    } else if (StringUtil.isEmptyString(messageText)) {
      Snackbar.showWarningSnackbar("Please provide a text message.");
    } else {
      this.buttonSend.busy = true;
      await Api.post("lme/powerbroker/send-sms-message", {
        phone_number: phoneNumber,
        message_text: messageText,
        driver_name: driverName,
        movement_id: movementId,
      }).then(async response => {
        this.buttonSend.busy = false;
        await this.slideOut();
        const messageSent = response?.data?.[0]?.message_sent;
        if (messageSent) {
          Toast.showSuccessToast("Message sent successfully.", null, null, { millisUntilDismissal: 5000 });
        } else {
          Snackbar.showSnackbar("Failed to send message.");
        }

      });
    }
  }


  getDriverPhoneNumber(driverName: string, _movementId: string): Promise<string> {
    return Api.post("lme/powerbroker/obtain-phone-number", { "movement_id": _movementId, "driver_name": driverName })
      .then(response => response.data[0]?.phone_number)
  }

//   async getTemplateFieldData(_movementId: string) {
//     const response = await Api.post("lme/powerbroker/get-template-data", {"movement_id": _movementId});
//         if (response.data[0] !== undefined && response.data[0]?.template_data !== undefined) {
//           const orderNumberInfo = response.data[0]?.template_data?.order_number;
//           const streetAddress = response.data[0]?.template_data.street_addresses;
//           this.updateLabelAndSwitch(this.labelStopInfo, streetAddress, this.switchStopName);
//           const scheduleArrivalTimes = response.data[0]?.template_data.schedule_arrival_times;
//           this.updateLabelAndSwitch(this.labelArrivalTimes, scheduleArrivalTimes, this.switchSchedArrivalTimes);
//           const contactInformation = response.data[0]?.template_data.contact_information;
//           this.updateLabelAndSwitch(this.labelContactInformation, contactInformation, this.switchContactInfo);
//           const commentInformation = response.data[0]?.template_data.comments;
//           this.updateLabelAndSwitch(this.labelCommentInformation, commentInformation, this.switchComments);
//           const referenceNumbers = response.data[0]?.template_data.reference_numbers;
//           this.updateLabelAndSwitch(this.labelReferenceNumbers, referenceNumbers, this.switchReferenceNumbers);
//           const directions = response.data[0]?.template_data.directions;
//           this.updateLabelAndSwitch(this.labelDirections, directions, this.switchDirections);
//           const directionsDisclaimer = response.data[0]?.template_data.directions_disclaimer;
//           this.updateLabelAndSwitch(this.labelDirectionsDisclaimer, directionsDisclaimer, this.switchDirectionsDisclaimer);
//           const distanceBetweenStops = response.data[0]?.template_data.distance_between_stops;
//           this.updateLabelAndSwitch(this.labelDistanceBetweenStops, distanceBetweenStops, this.switchDistBetweenStops);
//           const officePhoneNumber = response.data[0]?.template_data.office_phone;
//           this.updateLabelAndSwitch(this.labelOfficePhoneNumber, officePhoneNumber, this.switchOfficePhone);
//           const officeCellNumber = response.data[0]?.template_data.office_cell;
//           this.updateLabelAndSwitch(this.labelCellPhoneNumber, officeCellNumber, this.switchCellPhone);
//           const officeEmail = response.data[0]?.template_data.office_email;
//           this.updateLabelAndSwitch(this.labelEmailAddress, officeEmail, this.switchEmailAddr);
//           const doNotCallNotice = response.data[0]?.template_data.do_not_call_notice;
//           this.updateLabelAndSwitch(this.labelDoNotCallNotice, doNotCallNotice, this.switchDoNotCall);

//           if (orderNumberInfo !== undefined && !StringUtil.isEmptyString(orderNumberInfo)) {
//             this.smsMessageText.text = orderNumberInfo;
//             this.appendTextStopToOptOut();
//           }
//         }
//   }

async getTemplateFieldData(_movementId: string) {
    const response = await Api.post("lme/powerbroker/get-template-data", {"movement_id": _movementId});
    if (response.data[0] !== undefined && response.data[0]?.template_data !== undefined) {
        const templateData: SMSTemplate = response.data[0].template_data;
        this.updateSwitchVisiblity(templateData.street_addresses, this.switchStopName);
        this.updateSwitchVisiblity(templateData.schedule_arrival_times, this.switchSchedArrivalTimes);
        this.updateSwitchVisiblity(templateData.contact_information, this.switchContactInfo);
        this.updateSwitchVisiblity(templateData.comments, this.switchComments);
        this.updateSwitchVisiblity(templateData.reference_numbers, this.switchReferenceNumbers);
        this.updateSwitchVisiblity(templateData.directions, this.switchDirections);
        this.updateSwitchVisiblity(templateData.directions_disclaimer, this.switchDirectionsDisclaimer);
        this.updateSwitchVisiblity(templateData.distance_between_stops, this.switchDistBetweenStops);
        this.updateSwitchVisiblity(templateData.office_phone, this.switchOfficePhone);
        this.updateSwitchVisiblity(templateData.office_cell, this.switchCellPhone);
        this.updateSwitchVisiblity(templateData.office_email, this.switchEmailAddr);
        this.updateSwitchVisiblity(templateData.do_not_call_notice, this.switchDoNotCall);
        if (templateData.order_number !== undefined && !StringUtil.isEmptyString(templateData.order_number)) {
            this.smsMessageText.text = templateData.order_number + this.appendTextStopToOptOut();
            // this.labelRequestLocation.caption = templateData.request_info_header + this.appendTextStopToOptOut() + '\n\n';
            // this.labelLink.link = templateData.request_link;
        }
    }
}

async buildSmsMessage(movementId: string, includeStopDetails: boolean, includeArrivalTimes: boolean,
                      includeContactInformation: boolean, includeComments: boolean, includeReferenceNumber: boolean,
                      includeDirections: boolean, includeDirectionDisclaimer: boolean, includeDistanceBetweenStops: boolean,
                      includeDistanceBetweenDirections: boolean, includeSendersOfficeNum: boolean, includeSenderCellNum: boolean,
                      includeSendersEmail: boolean, includeDoNotCallNotice: boolean) {
    const response = await Api.post("lme/powerbroker/build-sms-message",
        {
            "movement_id": movementId,
            "include_stop_details": includeStopDetails,
            "include_arrival_times": includeArrivalTimes,
            "include_contact_info": includeContactInformation,
            "include_comments": includeComments,
            "include_reference_num": includeReferenceNumber,
            "include_directions": includeDirections,
            "include_directions_disclaimer": includeDirectionDisclaimer,
            "include_distance_between_stops": includeDistanceBetweenStops,
            "include_distance_between_directions": includeDistanceBetweenDirections,
            "include_sender_office_num": includeSendersOfficeNum,
            "include_sender_cell_num": includeSenderCellNum,
            "include_sender_email": includeSendersEmail,
            "include_do_not_call": includeDoNotCallNotice
        });
    if (response.data[0] !== undefined && response.data[0]?.sms_message !== undefined) {
        this.smsMessageText.text = response.data[0].sms_message + this.appendTextStopToOptOut();
    }
}

switchStopNameOnChange(event: ChangeEvent) {
    this._includeStopDetails = this.switchStopName.checked;
    this.handleSwitchChangeAndUpdateSmsText(this._movementId,this._includeStopDetails,this._includeArrivalTimes,
        this._includeContactInformation,this._includeCommentInformation,this._includeReferenceNumbers,
        this._includeDirections,this._includeDirectionsDisclaimer,
        this._includeDistanceBetweenStops, this._includeDistanceBetweenDirections,this._includeSenderOfficeNum,
        this._includeSenderCellNumber,this._includeSenderEmail,this._includeDoNotCall);
}


switchSchedArrivalTimesOnChange(event: ChangeEvent) {
    this._includeArrivalTimes = this.switchSchedArrivalTimes.checked;
    this.handleSwitchChangeAndUpdateSmsText(this._movementId,this._includeStopDetails,this._includeArrivalTimes,
        this._includeContactInformation,this._includeCommentInformation,this._includeReferenceNumbers,
        this._includeDirections,this._includeDirectionsDisclaimer,
        this._includeDistanceBetweenStops, this._includeDistanceBetweenDirections,this._includeSenderOfficeNum,
        this._includeSenderCellNumber,this._includeSenderEmail,this._includeDoNotCall);
}

switchContactInfoOnChange(event: ChangeEvent) {
    this._includeContactInformation = this.switchContactInfo.checked;
    this.handleSwitchChangeAndUpdateSmsText(this._movementId,this._includeStopDetails,this._includeArrivalTimes,
        this._includeContactInformation,this._includeCommentInformation,this._includeReferenceNumbers,
        this._includeDirections,this._includeDirectionsDisclaimer,
        this._includeDistanceBetweenStops, this._includeDistanceBetweenDirections,this._includeSenderOfficeNum,
        this._includeSenderCellNumber,this._includeSenderEmail,this._includeDoNotCall);
}

switchCommentsOnChange(event: ChangeEvent) {
    this._includeCommentInformation = this.switchComments.checked;
    this.handleSwitchChangeAndUpdateSmsText(this._movementId,this._includeStopDetails,this._includeArrivalTimes,
        this._includeContactInformation,this._includeCommentInformation,this._includeReferenceNumbers,
        this._includeDirections,this._includeDirectionsDisclaimer,
        this._includeDistanceBetweenStops, this._includeDistanceBetweenDirections,this._includeSenderOfficeNum,
        this._includeSenderCellNumber,this._includeSenderEmail,this._includeDoNotCall);
}

switchReferenceNumbersOnChange(event: ChangeEvent) {
    this._includeReferenceNumbers = this.switchReferenceNumbers.checked;
    this.handleSwitchChangeAndUpdateSmsText(this._movementId,this._includeStopDetails,this._includeArrivalTimes,
        this._includeContactInformation,this._includeCommentInformation,this._includeReferenceNumbers,
        this._includeDirections,this._includeDirectionsDisclaimer,
        this._includeDistanceBetweenStops, this._includeDistanceBetweenDirections,this._includeSenderOfficeNum,
        this._includeSenderCellNumber,this._includeSenderEmail,this._includeDoNotCall);
}

switchDirectionsOnChange(event: ChangeEvent) {
    this._includeDirections = this.switchDirections.checked;
    this.handleSwitchChangeAndUpdateSmsText(this._movementId,this._includeStopDetails,this._includeArrivalTimes,
        this._includeContactInformation,this._includeCommentInformation,this._includeReferenceNumbers,
        this._includeDirections,this._includeDirectionsDisclaimer,
        this._includeDistanceBetweenStops, this._includeDistanceBetweenDirections,this._includeSenderOfficeNum,
        this._includeSenderCellNumber,this._includeSenderEmail,this._includeDoNotCall);
}

switchDirectionsDisclaimerOnChange(event: ChangeEvent) {
    this._includeDirectionsDisclaimer = this.switchDirectionsDisclaimer.checked;
    this.handleSwitchChangeAndUpdateSmsText(this._movementId,this._includeStopDetails,this._includeArrivalTimes,
        this._includeContactInformation,this._includeCommentInformation,this._includeReferenceNumbers,
        this._includeDirections,this._includeDirectionsDisclaimer,
        this._includeDistanceBetweenStops, this._includeDistanceBetweenDirections,this._includeSenderOfficeNum,
        this._includeSenderCellNumber,this._includeSenderEmail,this._includeDoNotCall);
}

switchDistBetweenStopsOnChange(event: ChangeEvent) {
    this._includeDistanceBetweenStops = this.switchDistBetweenStops.checked;
    this.handleSwitchChangeAndUpdateSmsText(this._movementId,this._includeStopDetails,this._includeArrivalTimes,
        this._includeContactInformation,this._includeCommentInformation,this._includeReferenceNumbers,
        this._includeDirections,this._includeDirectionsDisclaimer,
        this._includeDistanceBetweenStops, this._includeDistanceBetweenDirections,this._includeSenderOfficeNum,
        this._includeSenderCellNumber,this._includeSenderEmail,this._includeDoNotCall);
}

switchOfficePhoneOnChange(event: ChangeEvent) {
    this._includeSenderOfficeNum = this.switchOfficePhone.checked;
    this.handleSwitchChangeAndUpdateSmsText(this._movementId,this._includeStopDetails,this._includeArrivalTimes,
        this._includeContactInformation,this._includeCommentInformation,this._includeReferenceNumbers,
        this._includeDirections,this._includeDirectionsDisclaimer,
        this._includeDistanceBetweenStops, this._includeDistanceBetweenDirections,this._includeSenderOfficeNum,
        this._includeSenderCellNumber,this._includeSenderEmail,this._includeDoNotCall);
}

switchCellPhoneOnChange(event: ChangeEvent) {
    this._includeSenderCellNumber = this.switchCellPhone.checked;
    this.handleSwitchChangeAndUpdateSmsText(this._movementId,this._includeStopDetails,this._includeArrivalTimes,
        this._includeContactInformation,this._includeCommentInformation,this._includeReferenceNumbers,
        this._includeDirections,this._includeDirectionsDisclaimer,
        this._includeDistanceBetweenStops, this._includeDistanceBetweenDirections,this._includeSenderOfficeNum,
        this._includeSenderCellNumber,this._includeSenderEmail,this._includeDoNotCall);
}

switchEmailAddrOnChange(event: ChangeEvent) {
    this._includeSenderEmail = this.switchEmailAddr.checked;
    this.handleSwitchChangeAndUpdateSmsText(this._movementId,this._includeStopDetails,this._includeArrivalTimes,
        this._includeContactInformation,this._includeCommentInformation,this._includeReferenceNumbers,
        this._includeDirections,this._includeDirectionsDisclaimer,
        this._includeDistanceBetweenStops, this._includeDistanceBetweenDirections,this._includeSenderOfficeNum,
        this._includeSenderCellNumber,this._includeSenderEmail,this._includeDoNotCall);
}

switchDoNotCallOnChange(event: ChangeEvent) {
    this._includeDoNotCall = this.switchDoNotCall.checked;
    this.handleSwitchChangeAndUpdateSmsText(this._movementId,this._includeStopDetails,this._includeArrivalTimes,
        this._includeContactInformation,this._includeCommentInformation,this._includeReferenceNumbers,
        this._includeDirections,this._includeDirectionsDisclaimer,
        this._includeDistanceBetweenStops, this._includeDistanceBetweenDirections,this._includeSenderOfficeNum,
        this._includeSenderCellNumber,this._includeSenderEmail,this._includeDoNotCall);
}

//   switchStopNameOnChange(event: ChangeEvent) {
//     this.handleSwitchChange(event, this.labelStopInfo.caption, this.switchStopName);
//   }

//   switchSchedArrivalTimesOnChange(event: ChangeEvent) {
//     this.handleSwitchChange(event, this.labelArrivalTimes.caption, this.switchSchedArrivalTimes);
//   }

//   switchContactInfoOnChange(event: ChangeEvent) {
//     this.handleSwitchChange(event, this.labelContactInformation.caption, this.switchContactInfo);
//   }

//   switchCommentsOnChange(event: ChangeEvent) {
//     this.handleSwitchChange(event, this.labelCommentInformation.caption, this.switchComments);
//   }

//   switchReferenceNumbersOnChange(event: ChangeEvent) {
//     this.handleSwitchChange(event, this.labelReferenceNumbers.caption, this.switchReferenceNumbers);
//   }

//   switchDirectionsOnChange(event: ChangeEvent) {
//     this.handleSwitchChange(event, this.labelDirections.caption, this.switchDirections);
//   }

//   switchDirectionsDisclaimerOnChange(event: ChangeEvent) {
//     this.handleSwitchChange(event, this.labelDirectionsDisclaimer.caption, this.switchDirectionsDisclaimer);
//   }

//   switchDistBetweenStopsOnChange(event: ChangeEvent) {
//     this.handleSwitchChange(event, this.labelDistanceBetweenStops.caption, this.switchDistBetweenStops);
//   }

//   switchOfficePhoneOnChange(event: ChangeEvent) {
//     this.handleSwitchChange(event, this.labelOfficePhoneNumber.caption, this.switchOfficePhone);
//   }

//   switchCellPhoneOnChange(event: ChangeEvent) {
//     this.handleSwitchChange(event, this.labelCellPhoneNumber.caption, this.switchCellPhone);
//   }

//   switchEmailAddrOnChange(event: ChangeEvent) {
//     this.handleSwitchChange(event, this.labelEmailAddress.caption, this.switchEmailAddr);
//   }

//   switchDoNotCallOnChange(event: ChangeEvent) {
//     this.handleSwitchChange(event, this.labelDoNotCallNotice.caption, this.switchDoNotCall);
//   }

  updateSwitchVisiblity(text: string, switchOption: Switch) {
    if (text === undefined && StringUtil.isEmptyString(text)) {
        switchOption.visible = false;
    }
}


  updateLabelAndCheckbox(label: Label, text: string, checkbox: Checkbox) {
    if (text !== undefined && !StringUtil.isEmptyString(text)) {
      label.caption = text;
    } else {
      checkbox.visible = false;
    }
  }

  updateLabelAndSwitch(label: Label, text: string, switchOption: Switch) {
    if (text !== undefined && !StringUtil.isEmptyString(text)) {
        if (label !== undefined) {
            label.caption = text;
        }
    } else {
      switchOption.visible = false;
    }
  }

  handleCheckboxClick(event: ClickEvent, label: string, checkbox: Checkbox) {
    if (label === undefined) {
      return;
    }

    if (checkbox.checked) {
      if (StringUtil.isEmptyString(this.smsMessageText.text)) {
        this.smsMessageText.text = label;
      } else {
        this.smsMessageText.text += label;
      }
    } else {
      if (this.smsMessageText.text.includes(label)) {
        this.smsMessageText.text = this.smsMessageText.text.replace(label, '');
      }
    }
    this.removeOptOutMessage();
    this.appendTextStopToOptOut();
  }

  handleSwitchChange(event: ChangeEvent, label: string, switchOption: Switch) {
    if (label === undefined) {
      return;
    }

    if (switchOption.checked) {
      if (StringUtil.isEmptyString(this.smsMessageText.text)) {
        this.smsMessageText.text = label;
      } else {
        this.smsMessageText.text += label;
      }
    } else {
      if (this.smsMessageText.text.includes(label)) {
        this.smsMessageText.text = this.smsMessageText.text.replace(label, '');
      }
    }
    this.removeOptOutMessage();
    this.appendTextStopToOptOut();
  }

    handleSwitchChangeAndUpdateSmsText(movementId: string, includeStopDetails: boolean, includeArrivalTimes: boolean,
        includeContactInformation: boolean, includeComments: boolean, includeReferenceNumber: boolean,
        includeDirections: boolean, includeDirectionDisclaimer: boolean, includeDistanceBetweenStops: boolean,
        includeDistanceBetweenDirections: boolean,
        includeSendersOfficeNum: boolean, includeSenderCellNum: boolean, includeSendersEmail: boolean,
        includeDoNotCallNotice: boolean) {
        this.buildSmsMessage(movementId, includeStopDetails, includeArrivalTimes, includeContactInformation,
            includeComments, includeReferenceNumber, includeDirections, includeDirectionDisclaimer, includeDistanceBetweenStops,
            includeDistanceBetweenDirections, includeSendersOfficeNum, includeSenderCellNum, includeSendersEmail, includeDoNotCallNotice);
    }

    appendTextStopToOptOut(): string {
        return  '\n\nText back "stop" to opt-out of future messages.';
    }

  removeOptOutMessage() {
    const optOutMessage = '\nText back "stop" to opt-out of future messages.';
    if (this.smsMessageText.text.includes(optOutMessage)) {
      this.smsMessageText.text = this.smsMessageText.text.replace(optOutMessage, '');
    }
  }
  smsMessageTextOnChange(event: ChangeEvent) {
    const maxCharacters = 2048;
    const charactersUsed = this.smsMessageText.text.length;
    let remainCharacters = maxCharacters - charactersUsed;
    let numOfText = Math.ceil(charactersUsed / maxCharacters);

    // If there are more than 1 text messages, reset the remaining characters.
    if (numOfText > 1) {
      numOfText = Math.ceil(charactersUsed / maxCharacters);
      remainCharacters = maxCharacters * numOfText - charactersUsed;
    }

    this.textboxCharacters.text = charactersUsed.toString();
    this.textboxCharactersRemaining.text = remainCharacters.toString();
    this.textboxNumofTextMessages.text = numOfText.toString();
  }

}

